import {
    ArticleDefiniteEnum,
    CaseFirstEnum,
    CaseFourthEnum,
    CaseSecondEnum,
    CaseThirdEnum
} from "./Noun";

export enum ProperNameTypeEnum {
    continent = "continent",
    country = "country",
    state = "state",
    city = "city",
    sea = "sea",
    lake = "lake",
    river = "river",
    name = "name",
    salutation = "salutation",
    polite_phrase = "polite_phrase"
}

export class ProperName {
    wordId?: number
    syllabification?: string
    syllabificationPlural?: string
    properNameType?: string
    articleDefinite?: ArticleDefiniteEnum
    caseFirst?: CaseFirstEnum
    caseFirstValue?: string
    caseSecond?: CaseSecondEnum
    caseSecondValue?: string
    caseThird?: CaseThirdEnum
    caseThirdValue?: string
    caseFourth?: CaseFourthEnum
    caseFourthValue?: string
    caseFirstValuePL?: string
    caseSecondValuePL?: string
    caseThirdValuePL?: string
    caseFourthValuePL?: string

    toString = () : string => {
        return "- Proper name"
    }
}

export default ProperName
