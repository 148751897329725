import React, {RefObject} from "react";
import {Worksheet} from "../_model/Worksheet";
import '../assets/css/layout.min.css';
import {Menu, MenuContext, MenuType} from "../Components/Menu/Menu";
import {WDSheet} from "./WDSheet";
import {WDElementMode} from "./Elements/Enum/WDElementMode";
import {Util} from "../Framework/Util";
import {WDTextbox} from "./Elements/Textbox/WDTextbox";
import Const from "../Framework/Const";
import {Prompt, RouteComponentProps} from "react-router-dom";
import {
    CreateWorksheet,
    CreateWorksheetPage,
    CreateWorksheetRating,
    DeleteWorksheetItem,
    DeleteWorksheetPage,
    GetAllNameConfigElements,
    GetPrintableWorksheet,
    GetWorksheetById,
    GetWorksheetItems,
    GetWorksheetItemsOfFirstPage,
    MergeNameConfigElements,
    RejectWorksheetRating,
    SaveWorksheetItem,
    SaveWorksheetThumbnail,
    UpdateWorksheet,
    UpdateWorksheetPage
} from "../_endpoint/WorksheetEndpoint";
import {ElementBorder, ElementFillStyle, ElementLayout, ElementTransformation} from "./Elements/WDElementContainer";
import {WDCalculationTriangle} from "./Elements/Math/CalculationTriangle/WDCalculationTriangle";
import {ElementProps, WDToolbarElement} from "./Toolbar/Toolbar/WDToolbarElement";
import {WDToolbarAction} from "./Toolbar/WDToolbarAction";
import translations from "../Framework/translations.json";
import {WDCalculationTriangleToolbar} from "./Elements/Math/CalculationTriangle/WDCalculationTriangleToolbar";
import {WDTextboxToolbar} from "./Elements/Textbox/WDTextboxToolbar";
import {MainContext} from "../_base/MainContext";
import {
    NotificationStatus,
    PrintSolutionMode,
    RenderingMedia,
    SolutionForceMode,
    Status,
    UserSettingsAutoSave,
    WSMarketplaceStatus,
    WSPageFormat
} from "../Framework/Enums";
import {UpdateUserSettings} from "../_endpoint/UserEndpoint";
import {UserSettings} from "../_model/UserSettings";
import {WDWritingLineature} from "./Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDWritingLineatureToolbar} from "./Elements/Lineature/WritingLineature/WDWritingLineatureToolbar";
import {Notification} from "../Components/Notification/NotificationHandler";
import AppHeader from "../_base/AppHeader";
import {Sidebar, SidebarElement} from "./Sidebar/Sidebar";
import {WDImageCacheData, WDImageData} from "./Elements/Image/WDImage";
import {WDImageToolbar} from "./Elements/Image/WDImageToolbar";
import {MenuItem} from "../_model/MenuItem";
import {WDBalloon} from "./Elements/Balloon/WDBalloon";
import {WDBalloonToolbar} from "./Elements/Balloon/WDBalloonToolbar";
import {WDMathLineature} from "./Elements/Lineature/MathLineature/WDMathLineature";
import {WDMathLineatureToolbar} from "./Elements/Lineature/MathLineature/WDMathLineatureToolbar";
import {WDSettings, WorksheetSettings} from "./WDSettings";
import NameConfigElement from "../_model/NameConfigElement";
import NameConfig from "../_model/NameConfig";
import {GetAllNameConfigWS} from "../_endpoint/NameEndpoint";
import Converter from "../Framework/Converter";
import domToImage from 'dom-to-image';
import {WDCalculationTower} from "./Elements/Math/CalculationTower/WDCalculationTower";
import {WDCalculationTowerToolbar} from "./Elements/Math/CalculationTower/WDCalculationTowerToolbar";
import {WDElementHistory} from "./History/WDElementHistory";
import {Coords} from "../Framework/Coords";
import {LogLevel} from "../Framework/Log";
import {ImagePath} from "../Framework/CategoryImage";
import {WDGroupToolbar} from "./Elements/Group/WDGroupToolbar";
import {WDGroup} from "./Elements/Group/WDGroup";
import Entity from "../_model/Entity";
import {WDToolbarMixed} from "./Toolbar/Toolbar/WDToolbarMixed";
import {WDTable} from "./Elements/Table/WDTable";
import {WDTableToolbar} from "./Elements/Table/WDTableToolbar";
import {WDTextExercise} from "./Elements/TextExercise/WDTextExercise";
import {RESIZE_NODE} from "./Elements/Enum/WDElementContainerResizeNode";
import {WSContextType} from "./Elements/WSContext";
import {WDTextExerciseToolbar} from "./Elements/TextExercise/WDTextExerciseToolbar";
import {PageBorderPosition} from "../Components/PageBorder/WDPageBorder";
import {SidebarPageManager} from "./Sidebar/SidebarPageManager/SidebarPageManager";
import {PositioningLine, PositioningLineCheck, PositioningMode, WDUtils} from "./Utils/WDUtils";
import {WDHistoryAction} from "./History/Enum/WDHistoryAction";
import {WDHistoryDirection} from "./History/Enum/WDHistoryDirection";
import {WDElementHistoryItem} from "./History/WDElementHistoryItem";
import {WDHistory} from "./History/WDHistory";
import {WDPageHistory} from "./History/WDPageHistory";
import {WDPageHistoryItem} from "./History/WDPageHistoryItem";
import {GetRuleWorksheetItems} from "../_endpoint/RuleEndpoint";
import {WorksheetItemType, WorksheetItemTypeEnum} from "../_model/WorksheetItemType";
import {WDElementArrange} from "./Elements/Enum/WDElementArrange";
import {WDElementAlignment} from "./Elements/Enum/WDElementAlignment";
import {WDElementDistributeMode} from "./Elements/Enum/WDElementDistributeMode";
import {WDContextMenu} from "./ContextMenu/WDContextMenu";
import WDContextMenuItem from "./ContextMenu/WDContextMenuItem";
import WDContextMenuGroup from "./ContextMenu/WDContextMenuGroup";
import PlusButton from "../Components/PlusButton/PlusButton";
import {SidebarImages} from "./Sidebar/SidebarImages/SidebarImages";
import {SidebarNames} from "./Sidebar/SidebarNames/SidebarNames";
import {SidebarDictionary} from "./Sidebar/SidebarDictionary/SidebarDictionary";
import {PublishInMarketplaceModal} from "../Marketplace/PublishInMarketplaceModal";
import {Modal} from "../Components/Modal";
import {ButtonInfo} from "../Components/Controls/ButtonList";
import {UnsavedChangesModal} from "../Components/Notification/UnsavedChangesModal";
import {withRouter} from "react-router";
import PopoutMenu, {PopupMenuItem} from "../Components/Controls/PopoutMenu";
import {RateWorksheetModal} from "../Marketplace/RateWorksheetModal";
import {WorksheetRating} from "../_model/WorksheetRating";
import {WorksheetItem} from "../_model/WorksheetItem";
import {WorksheetPage} from "../_model/WorksheetPage";
import Auth from "../Framework/Auth";
import {NotificationData} from "../Components/Notification/Hint";
import {MPStatusIcon} from "../Marketplace/MPStatusIcon";
import {TooltipPosition, Tooltips, TooltipText} from "../Components/Tooltips";
import {WDWritingCourse} from "./Elements/WritingCourse/WDWritingCourse";
import {WDWritingCourseToolbar} from "./Elements/WritingCourse/WDWritingCourseToolbar";
import {WDShape2d} from "./Elements/Shape/WDShape2d";
import {WDShapeToolbar2D} from "./Elements/Shape/WDShapeToolbar2D";
import {WDLine} from "./Elements/Line/WDLine";
import {WDPresentation} from "./Presentation/WDPresentation";
import {SecurityError} from "../Framework/Error/SecurityError";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "./Utils/WorksheetItemUpdate";
import {WDPresentationAction} from "./Presentation/WDPresentationAction";
import {WorksheetItemHistory} from "./History/WorksheetItemHistory";
import {WorksheetItemAction} from "./Utils/WorksheetItemAction";
import {SidebarHelp} from "./Sidebar/SidebarHelp/SidebarHelp";
import _ from "lodash";
import {WDShape3d} from "./Elements/Shape/WDShape3d";
import {WDShapeToolbar3D} from "./Elements/Shape/WDShapeToolbar3D";
import {WDShapeBuildingBrick} from "./Elements/Shape/WDShapeBuildingBrick";
import {WDShapeToolbarBuildingBrick} from "./Elements/Shape/WDShapeToolbarBuildingBrick";
import {WDShapeCraftPattern} from "./Elements/Shape/WDShapeCraftPattern";
import {WDShapeToolbarCraftPattern} from "./Elements/Shape/WDShapeToolbarCraftPattern";
import {FrameSubject, SidebarFrames} from "./Sidebar/SidebarBorderManager/SidebarFrames";
import {WDTableData} from "./Elements/Table/WDTableData";
import {WDPrintModal} from "./Print/WDPrintModal";
import {WDPrintOptions} from "./Print/WDPrintOptions";
import {BorderStyle} from "../Components/Controls/BorderStylingOptions";
import {WDLineToolbar} from "./Elements/Line/WDLineToolbar";
import {GetImage, GetImageWithCounterpartImageInfo} from "../_endpoint/ImageEndpoint";
import {ImageCacheObject} from "../Framework/Cache/ImageCacheObject";
import {TutorialStepData} from "./Tutorial/TutorialData";
import {WDActionLogCategory, WDActionLogEntryDetails, WDActionLogType} from "./ActionLog/WDActionLogEntry";
import {WorksheetPageUpdate} from "./Utils/WorksheetPageUpdate";
import {WorksheetPageHistory} from "./History/WorksheetPageHistory";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> {
}

class ScrollDirection {
    top: boolean
    bottom: boolean
    left: boolean
    right: boolean

    constructor() {
        this.top = false
        this.bottom = false
        this.left = false
        this.right = false
    }
}

class ConfirmationDialogOptions {
    title: string
    description: string
    onSubmit: () => void

    constructor(title: string, description: string, onSubmit: () => void) {
        this.title = title
        this.description = description
        this.onSubmit = onSubmit
    }
}

interface IState {
    worksheet?: Worksheet;
    isToolbarOpen: boolean
    elementToolbar: JSX.Element
    contextMenu: JSX.Element

    historyStack: WDHistory[]
    historyIndex: number

    mode: WDElementMode
    resizeNode: RESIZE_NODE | undefined

    loaded: boolean
    elements: WorksheetItem[]
    clipboard: WorksheetItem[]
    nameConfigElement: NameConfigElement[]
    nameConfigWS: NameConfig[]
    activeSidebar: SidebarElement | undefined
    currentPageIndex: number

    announcement?: NotificationData

    inPresentationMode: boolean

    lastSaved?: Date
    generateThumbnailOnSave: boolean
    errorReportMessage?: string

    showCloseDialog: boolean
    showSettingsDialog: boolean
    showPrintDialog: boolean
    showConfirmationDialog: boolean
    showPublishInMarketplace: boolean
    showRateWorksheetMarketplace: boolean
    confirmationDialogOptions?: ConfirmationDialogOptions

    renderingMedia: RenderingMedia
    printSolutionMode?: PrintSolutionMode
    showNonPrintableObjects: boolean

    location?: string

    refMenu: RefObject<Menu>
    refSidebar: RefObject<Sidebar>
    refSidebarPageManager: RefObject<SidebarPageManager>
    refToolbar: RefObject<WDToolbarElement>
    refContextMenu: RefObject<WDContextMenu>
    refPopoutMenu: RefObject<PopoutMenu>
}

export class WDesigner extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // Mouse button pressed
    mouseButtonPressed: number = 0
    // Client coords of the mouse button press
    mouseButtonCoords?: Coords
    // Workspace of the mouse button press
    mouseButtonWorkspace: HTMLElement | null = null
    // Mouse coords in scroll area
    mouseMoveScrollAreaCoords?: Coords
    // Prevents multiple key events (e.g. when holding down the arrow keys)
    keyReleased: boolean = true
    // True during saving operation
    saving: boolean = false
    // Prevents opening the toolbar for a short time (e.g. when selecting elements via mouse selection box)
    preventToolbar: boolean = false
    // Positioning lines
    positioningLines: PositioningLine[] = []

    MOUSE_MOVE_THRESHOLD = 10
    AUTO_SAVE_INTERVAL = 10000

    constructor(props: MatchProps) {
        super(props)

        let printSolutionMode: PrintSolutionMode | undefined = undefined
        let displayMode = RenderingMedia.screen
        if (this.props.location.pathname.includes("print")) {
            const query = new URLSearchParams(this.props.location.search);
            displayMode = RenderingMedia.print

            // By default, print no solutions (worksheet mode)
            printSolutionMode = PrintSolutionMode.NoSolutions
            let s = query.get('s')
            if (s !== null) {
                if (s === "1") {
                    printSolutionMode = PrintSolutionMode.SolutionSheet
                } else if (s === "2") {
                    printSolutionMode = PrintSolutionMode.AutomaticSolutions
                }
            }
        }

        this.state = {
            isToolbarOpen: false,
            elementToolbar: <></>,
            contextMenu: <></>,
            mode: WDElementMode.NONE,
            resizeNode: undefined,
            historyStack: [],
            historyIndex: -1,
            generateThumbnailOnSave: false,
            loaded: false,

            elements: [],
            clipboard: [],
            worksheet: undefined,
            nameConfigElement: [],
            nameConfigWS: [],
            showCloseDialog: false,
            showSettingsDialog: false,
            showPrintDialog: false,
            showConfirmationDialog: false,
            showPublishInMarketplace: false,
            showRateWorksheetMarketplace: false,
            activeSidebar: undefined,
            currentPageIndex: 0,

            renderingMedia: displayMode,
            printSolutionMode: printSolutionMode,
            showNonPrintableObjects: true,
            inPresentationMode: false,

            refMenu: React.createRef<Menu>(),
            refSidebar: React.createRef<Sidebar>(),
            refSidebarPageManager: React.createRef<SidebarPageManager>(),
            refToolbar: React.createRef<WDToolbarElement>(),
            refContextMenu: React.createRef<WDContextMenu>(),
            refPopoutMenu: React.createRef<PopoutMenu>()
        }
    }

    componentDidMount() {
        // document event listener
        window.addEventListener('resize', this.onResizeWindow)
        window.addEventListener('beforeunload', this.onCloseWindow);

        document.addEventListener('keydown', this.onKeyDown, false)
        document.addEventListener('keyup', this.onKeyUp, false)

        // Init context data
        this.context.setZoom(1)
        this.context.initWDActionLog()

        // positioning of app header
        this.onResizeWindow()

        // initialize tutorials
        this.context.initTutorial("plus-button", [
            new TutorialStepData(0, false),
            new TutorialStepData(1, true)
        ])

        // set send error report delegate
        this.context.setOnSendErrorReportDelegate(this.onSendErrorReport)

        if (!isNaN(+this.props.match.params.id)) {
            this.context.log.info("Parameter ID " + +this.props.match.params.id)

            this.context.log.debug("Loading Worksheet ...")
            GetWorksheetById(+this.props.match.params.id).then(
                (result: Worksheet) => {
                    // prevent users from searching through published admin worksheets
                    if (result.context !== WSContextType.standard && !Auth.isAdmin()) {
                        this.context.handleError(
                            new SecurityError(this.context.translate(translations.error.not_allowed)),
                            undefined, undefined,
                            this.context.translate(translations.notification.title_error_permissions)
                        )
                        return
                    }
                    this.context.log.debug("Loading Worksheet OK")

                    this.refreshPageSorting(result.pages)

                    this.setState({worksheet: result}, () => {
                        this.context.log.debug("Loading Worksheet Items ...")
                        this.loadWorksheetItems(result);
                        this.context.log.debug("Loading Worksheet Items OK")

                        this.context.log.debug("Loading Worksheet Names ...")
                        this.loadWorksheetNameElements(result);
                        this.context.log.debug("Loading Worksheet Names OK")

                        this.context.log.info("Loading OK")

                        if (result.marketplaceStatus === WSMarketplaceStatus.approval || result.marketplaceStatus === WSMarketplaceStatus.updated) {
                            this.setState({announcement: new NotificationData(NotificationStatus.info, this.context.translate(translations.text.marketplace.while_approval_locked))})
                        }
                        this.context.log.flush(LogLevel.DEBUG)
                    })
                },
                (error) => {
                    this.context.handleError(error, undefined, undefined, this.context.translate(translations.notification.title_error_loading))
                })
        }
        else {
            this.setState({loaded: true})
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeWindow)
        window.removeEventListener('beforeunload', this.onCloseWindow);
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keyup', this.onKeyUp)
    }

    /**
     * Get and update current users settings
     */
    updateCurrentUserSettings = async (settings: UserSettings) => {
        this.context.updateUserSettings(await UpdateUserSettings(settings))
    }

    /**
     * Close and save dialog for unsaved changes
     */
    blockNavigation = (l) => {
        if (!this.isEditingAllowed()) {
            return true
        }

        if (!this.saving) {
            this.context.getUserSettings().then(userSettings => {
                // user wants to have his worksheets always saved on close
                if (this.state.worksheet && (userSettings.autoSave || userSettings.autoSaveOnClose === UserSettingsAutoSave.alwaysSave)) {

                    this.saveWorksheet(this.state.worksheet, true).then(
                        () => {
                            this.markElementsAsUnchanged()
                            this.markPagesAsUnchanged()
                            this.setState({elements: this.state.elements}, () => this.props.history.push(l))
                        }
                    )
                    return false

                    // user never wants to have his worksheets saved on close
                } else if (userSettings.autoSaveOnClose === UserSettingsAutoSave.neverSave) {
                    this.markElementsAsUnchanged()
                    this.markPagesAsUnchanged()
                    this.setState({elements: this.state.elements}, () => this.props.history.push(l))

                    return true

                    // user hasn't made a decision for default behaviour and wants to have dialog
                } else {
                    this.setState({showCloseDialog: this.hasUnsavedChanges(), location: l.pathname});
                    return false
                }
            })
        }

        return !this.hasUnsavedChanges()
    }
    closeDialogInteraction = async (save: boolean, saveDecision: boolean) => {
        // remember the decision regarding auto saving for the next time and don't show dialog again
        if (saveDecision) {
            let newUserSettings = await this.context.getUserSettings()
            newUserSettings.autoSaveOnClose = save ? UserSettingsAutoSave.alwaysSave : UserSettingsAutoSave.neverSave

            await this.updateCurrentUserSettings(newUserSettings)

            this.context.setNotification(Notification.handleInfo(
                this.context.translate(translations.notification.title_ok_save),
                this.context.translate(translations.notification.saved_user_settings))
            )
        }

        if (this.state.worksheet && save) {
            if (this.state.location) {
                this.saveWorksheet(this.state.worksheet, false).then(
                    () => {
                        this.props.history.push(this.state.location!)
                    }
                )
            }
        } else {
            this.markElementsAsUnchanged()
            this.markPagesAsUnchanged()

            // Save changed elements and proceed to chosen location
            this.setState({elements: this.state.elements}, () => {
                if (this.state.location) {
                    this.props.history.push(this.state.location)
                }
            })
        }
    }
    cancelSaveDialog = () => {
        this.setState({showCloseDialog: false})
    }
    hasUnsavedChanges = () => {
        const deletedPageKeys = this.state.worksheet?.pages
            ?.filter(page => page.deleted)
            ?.map(page => WorksheetPage.getUniqueElementIdentifier(page))
        const unsavedItems = this.state.elements
            .find(item => item.changed && !deletedPageKeys?.includes(item.worksheetPageKey))
        const unsavedPages = this.state.worksheet?.pages?.find(page => page.changed)

        return (unsavedItems !== undefined || unsavedPages !== undefined)
    }
    markElementsAsUnchanged = () => {
        // Mark all items as unchanged so the blocking-condition is false
        for (const item1 of this.getChildElementsRecursiveByElements(this.state.elements).filter(item => item.changed)) {
            item1.changed = false;
        }
    }
    markPagesAsUnchanged = () => {
        if (this.state.worksheet === undefined || this.state.worksheet.pages === undefined) {
            return
        }

        // Mark all items as unchanged so the blocking-condition is false
        for (const p of this.state.worksheet.pages.filter(p => p.changed)) {
            p.changed = false;
        }
    }

    /**
     * History
     * */
    pushHistory = (history: WDHistory) => {
        const index = this.state.historyIndex + 1

        if (history.oldValue instanceof WDElementHistoryItem) {
            let oldValue = history.oldValue as WDElementHistoryItem
            // console.log("Push history (" + index + ") before: ", oldValue.updates)
            this.persistHierarchicalState(oldValue.updates)
            // console.log("Push history (" + index + ") after: ", history.newValue)
        }

        let elements = this.state.historyStack
        elements.splice(index, (elements.length - index), {...history})

        this.context.log.debug("Push history (" + index + ")", elements)
        this.context.log.flush(LogLevel.INFO, true)

        this.setState({
            historyStack: elements,
            historyIndex: elements.length - 1
        })
    }
    pushElementHistory = (before: WorksheetItemUpdate[], after: WorksheetItemUpdate[]) => {
        this.pushHistory(new WDElementHistory(
            this.restoreElements,
            new WDElementHistoryItem(before), new WDElementHistoryItem(after)
        ))
    }
    updateHistory = (value: WDElementHistoryItem) => {
        let stack = this.state.historyStack
        this.context.log.debug("Update history (" + this.state.historyIndex + ")")
        this.context.log.flush(LogLevel.INFO, true)

        if (this.state.historyIndex >= 0 && stack.length > this.state.historyIndex - 1) {
            this.persistHierarchicalState(value.updates)

            stack[this.state.historyIndex].update(value)

            this.setState({historyStack: stack})
        }
        this.context.log.flush()
    }
    persistHierarchicalState = (updates: WorksheetItemUpdate[]) => {
        let allElements = this.getAllElementsRecursive()

        // Add the parents hierarchically to the history
        updates.forEach(e => {
            this.getParentItemState(e.itemKey, allElements, updates)
        })

        // Add the children of each element in the list to the history
        updates.forEach(u => {
            let e = allElements.find(i => i.itemKey === u.itemKey)
            if (e) {
                this.getChildrenItemState(e, updates)
            }
        })
    }

    restoreElements = async (history: WDElementHistory, direction: WDHistoryDirection) => {
        this.context.log.info("Restore Element Action: Direction = " + direction, history)

        const targetUpdates = (direction === WDHistoryDirection.BACK ? history.oldValue.updates : history.newValue?.updates)
        if (targetUpdates) {
            await this.updateElements(targetUpdates, { historyAction: WDHistoryAction.HISTORY})
        }

        this.context.log.flush(LogLevel.INFO)

        this.setState({
            historyIndex: this.state.historyIndex + (direction === WDHistoryDirection.BACK ? -1 : 1)
        })
    }
    restorePages = async (history: WDPageHistory, direction: WDHistoryDirection) => {
        if (this.state.worksheet && this.state.worksheet.pages) {
            this.context.log.info("Restore Page Action: Direction = " + direction, history)

            const targetUpdates = (direction === WDHistoryDirection.BACK ? history.oldValue.updates : history.newValue?.updates)
            if (targetUpdates) {
                await this.updatePages(targetUpdates)
            }

            this.context.log.flush(LogLevel.INFO)

            this.setState({
                historyIndex: this.state.historyIndex + (direction === WDHistoryDirection.BACK ? -1 : 1)
            })
        }
    }

    private getParentItemState = (itemKey: string, elements: WorksheetItem[], updates: WorksheetItemUpdate[]) => {
        let parent = this.getParentElement(itemKey, elements)
        if (parent) {
            // Parent element state
            this.setItemStateUpdate(parent, updates)

            this.context.log.debug("Adding update for parent " + parent.itemKey)
            this.context.log.flush(LogLevel.INFO)

            // Recursively add parents
            this.getParentItemState(parent.itemKey, elements, updates)
        }
    }
    private getChildrenItemState = (element: WorksheetItem, updates: WorksheetItemUpdate[]) => {
        element.children?.forEach(c => {
            // Element state
            this.setItemStateUpdate(c, updates)

            // Recursively add children
            this.getChildrenItemState(c, updates)
        })
    }
    private setItemStateUpdate = (item: WorksheetItem, updates: WorksheetItemUpdate[]) => {
        let existingUpdate = updates.find(u => u.itemKey === item.itemKey)

        if (existingUpdate === undefined) {
            existingUpdate = new WorksheetItemUpdate(item.itemKey, {
                width: item.width,
                height: item.height,
                posX: item.posX,
                posY: item.posY,
                content: item.content
            })
            updates.push(existingUpdate)
        } else {
            existingUpdate.value.width = item.width
            existingUpdate.value.height = item.height
            existingUpdate.value.posX = item.posX
            existingUpdate.value.posY = item.posY
            existingUpdate.value.content = item.content
        }
    }

    onUndo = async () => {
        this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.undo, [])

        const index = this.state.historyIndex
        if (index >= 0 && index <= this.state.historyStack.length - 1) {
            this.context.log.info("Undo (" + index + ")")
            await this.state.historyStack[index].restoreElements(WDHistoryDirection.BACK)
            this.context.log.debug("Element restored")
        }

        this.context.log.flush(LogLevel.INFO)

        if (this.getSelectedElements(true).length > 0) {
            this.openToolbar()
        } else {
            this.closeToolbar()
        }
        this.autoSaveWorksheet()
    }
    onRedo = async () => {
        this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.redo, [])

        const index = this.state.historyIndex + 1
        this.context.log.info("Redo (" + index + ")")

        if (index <= this.state.historyStack.length - 1) {
            await this.state.historyStack[index].restoreElements(WDHistoryDirection.FORWARD)
            this.context.log.debug("Element restored")
        }
        this.context.log.flush(LogLevel.INFO)

        if (this.getSelectedElements(true).length > 0) {
            this.openToolbar()
        } else {
            this.closeToolbar()
        }

        this.autoSaveWorksheet()
    }

    /**
     * Add Textbox mode and reset mode
     * */
    setElementMode = (mode: WDElementMode) => {
        if (mode === WDElementMode.NONE) {
            document.getElementById("ws-designer-document")!.className = "ws-designer-document"
        } else {
            let className = "ws-designer-document"
            switch (mode) {
                case WDElementMode.ADD_BALLOON:
                    className += " ws-designer-add-balloon-cursor"
                    break
                case WDElementMode.ADD_TABLE:
                    className += " ws-designer-add-table-cursor"
                    break
                case WDElementMode.ADD_TEXTBOX:
                    className += " ws-designer-add-textbox-cursor"
                    break
            }
            document.getElementById("ws-designer-document")!.className = className
        }
        this.setState({mode: mode})
    }
    isEditMode = () => {
        return (this.state.mode === WDElementMode.EDIT)
    }

    /**
     * Page settings change
     * */
    onChangePageSettings = (id: number, sheet: WorksheetPage) => {

        if (this.state.worksheet?.pages) {
            let WSSheet = this.state.worksheet?.pages.find(i => i.id === id)
            if (WSSheet) {
                WSSheet.borderTop = sheet.borderTop
                WSSheet.borderLeft = sheet.borderLeft
                WSSheet.borderRight = sheet.borderRight
                WSSheet.borderBottom = sheet.borderBottom
                WSSheet.linkBorders = sheet.linkBorders

                this.setState({worksheet: this.state.worksheet})
            }
        }
    }

    addElementToDesigner = async (element: WorksheetItem) => {
        this.context.log.info("Add element " + element.itemKey + " to designer")

        await this.unselectAllElements()

        await this.updateElements([], {historyAction: WDHistoryAction.CREATE}, [element])
        this.openToolbar()
    }

    /**
     * Element actions
     * */
    groupElements = async (elements: WorksheetItem[]) => {
        this.context.log.info("Group " + elements.length + " items.")

        // Calculate position
        const groupKey = WorksheetItem.getNewItemKey()
        const pageKey = elements[0].worksheetPageKey

        const elementLayouts = elements.map(i => WorksheetItem.getElementSize(i))

        const minX = elementLayouts.map(i => i.left).reduce((prev, current) => Math.min(prev, current))
        const minY = elementLayouts.map(i => i.top).reduce((prev, current) => Math.min(prev, current))
        const maxX = elementLayouts.map(i => i.left + i.width).reduce((prev, current) => Math.max(prev, current))
        const maxY = elementLayouts.map(i => i.top + i.height).reduce((prev, current) => Math.max(prev, current))

        // Create group worksheet item
        const group = new WorksheetItem(groupKey, pageKey, WorksheetItemTypeEnum.GROUP,
            minX, minY, maxX - minX, maxY - minY, "{}",
            true, false, false, false)
        group.changed = true
        group.ref = React.createRef<WDGroup>()
        group.posZ = WDUtils.getMaxPosZOfElements(this.getElementsByPage(pageKey)) + 1

        let updates = elements
            .sort((a, b) => (a.posZ || 0) - (b.posZ || 0))
            .map((item, i) => {
                return new WorksheetItemUpdate(item.itemKey, {
                    selected: false,
                    groupId: undefined,
                    groupKey: group.itemKey,
                    posX: item.posX - group.posX,
                    posY: item.posY - group.posY,
                    posZ: i + 1
                })
            })

        await this.updateElements(updates, {historyAction: WDHistoryAction.GROUP}, [group])
    }
    removeAllWrapperElements = async () => {
        await this.performUpdatesOnSelectedElements((element: WorksheetItem) => {
            return this.unwrapChildren(element)
        }, {
            applyToChildren: false,
            applyRecursive: false,
            historyAction: WDHistoryAction.UNWRAP_EXERCISE,
            actionCategory: WDActionLogCategory.unwrap
        })
    }
    ungroupElements = async (group: WorksheetItem) => {
        this.context.log.info("Ungroup " + group.itemKey)

        // Move elements outside group = "unwrap"
        let result = this.unwrapChildren(group)
        await this.updateElements(result.updates, {historyAction: WDHistoryAction.GROUP}, result.newItems)
    }
    unwrapChildren = (group: WorksheetItem): WorksheetItemAction => {
        this.context.log.info("Unwrap item " + group.itemKey)

        // Move elements outside group = "unwrap"
        let updates = group.children.map(child => {
            return new WorksheetItemUpdate(child.itemKey, {
                posX: child.posX + group.posX,
                posY: child.posY + group.posY,
                groupId: undefined,
                groupKey: undefined,
                selected: true
            })
        })

        updates.push(new WorksheetItemUpdate(group.itemKey, {
            children: [], deleted: true
        }))

        return new WorksheetItemAction(updates, [])
    }
    addElementToGroup = (groupKey: string, child: WorksheetItem) => {
        this.context.log.info("addElementToGroup")

        let parent = this.state.elements.find(e => e.itemKey === groupKey)
        if (parent) {
            this.context.log.info("Found parent " + groupKey)
            child.worksheetPageKey = parent.worksheetPageKey
            child.groupKey = parent.itemKey
            child.posZ = WDUtils.getMaxPosZOfElements(parent.children) + 1
            parent.children = [...parent.children, child]
        }

        this.context.log.flush()
    }

    getGroupUpdates = (item: WorksheetItem, update: Partial<WorksheetItem>) => {
        let updates: WorksheetItemUpdate[] = []
        item.children.forEach(child => {
            updates = updates.concat(this.getGroupUpdates(child, update))
        })

        updates.push(new WorksheetItemUpdate(item.itemKey, update))

        return updates
    }

    updateElements = async (updates: WorksheetItemUpdate[], options?: WorksheetItemUpdateOptions, newElements?: WorksheetItem[]): Promise<void> => {
        return new Promise(async (resolve) => {
            let itemHistory: WorksheetItemHistory[] = []

            // Remove empty updates
            updates = updates.filter(u => Object.keys(u.value).length > 0)

            // Update existing elements with the provided item updates, only changed fields are updated
            let result = WDUtils.updateWorksheetItems(this.state.elements, updates, itemHistory)

            // Add new elements
            if (newElements) {
                result.push(...newElements)

                newElements.forEach(e => {
                    itemHistory.push(new WorksheetItemHistory(e.itemKey, {deleted: true}, {deleted: false}))
                })
            }

            // Add history action
            if (options?.historyAction && options?.historyAction !== WDHistoryAction.HISTORY && itemHistory.length > 0) {
                const history = new WDElementHistory(
                    this.restoreElements,
                    new WDElementHistoryItem(itemHistory.filter(i => i.before !== undefined).map(e => new WorksheetItemUpdate(e.itemKey, e.before!))),
                    new WDElementHistoryItem(itemHistory.filter(i => i.after !== undefined).map(e => new WorksheetItemUpdate(e.itemKey, e.after!)))
                )

                if (options?.updateHistory === true && history.newValue) {
                    // console.log("Update history action " + options.historyAction, history.newValue)
                    this.updateHistory(history.newValue)
                } else {
                    // console.log("Push history action " + options.historyAction, history)
                    this.pushHistory(history)
                }
            }

            // Write entries to action log details
            if (options?.actionCategory && itemHistory.length > 0) {
                let allElements = this.getAllElementsRecursive()
                let logEntries = itemHistory
                    .map(i => {
                        let item = result.find(e => e.itemKey === i.itemKey)
                        if (item) {
                            if (options?.actionData) {
                                let refItem = allElements.find(i => i.itemKey === item!.itemKey)
                                let actionData = refItem?.ref?.current?.getAdditionalActionData() || {}
                                actionData = { ...options.actionData, ...actionData }

                                return new WDActionLogEntryDetails(i.itemKey, item.worksheetItemTypeId, { ...options.actionData, ...actionData }, {})
                            }
                            else {
                                return new WDActionLogEntryDetails(i.itemKey, item.worksheetItemTypeId, i.before, i.after)
                            }
                        }
                        return null
                    })
                    .filter(i => i !== null) as WDActionLogEntryDetails[]

                this.context.addWDAction(WDActionLogType.Info, options.actionCategory || WDActionLogCategory.unknown, logEntries, options.actionDescription)
            }

            if (options?.historyAction === WDHistoryAction.GROUP || options?.historyAction === WDHistoryAction.HISTORY) {
                result = this.getChildElementsRecursiveByElements(result)
                result.forEach(item => {
                    item.children = []
                })
                result = WDUtils.loadGroupStructureToHierarchy(result)
            }

            // Set state after reforming group structure and resolve promise as callback for a synchronous update
            this.setState({ elements: result }, resolve)
        });
    }
    updatePages = async (updates: WorksheetPageUpdate[], options?: WorksheetItemUpdateOptions, newPages?: WorksheetPage[]): Promise<void> => {
        return new Promise(async (resolve) => {
            let pageHistory: WorksheetPageHistory[] = []

            let worksheet = this.state.worksheet
            if (worksheet === undefined || worksheet.pages === undefined) {
                resolve()
                return
            }

            // Remove empty updates
            updates = updates.filter(u => Object.keys(u.value).length > 0)

            // Update existing elements with the provided item updates, only changed fields are updated
            let result = WDUtils.updateWorksheetPages(worksheet.pages, updates, pageHistory)

            // Add new elements
            if (newPages) {
                result.push(...newPages)

                newPages.forEach(e => {
                    pageHistory.push(new WorksheetPageHistory(WorksheetPage.getUniqueElementIdentifier(e), {deleted: true}, {deleted: false}))
                })
            }
            worksheet.pages = result

            // Add history action
            if (options?.historyAction && pageHistory.length > 0) {
                const history = new WDPageHistory(
                    this.restorePages,
                    new WDPageHistoryItem(pageHistory.filter(i => i.before !== undefined)
                        .map(e => new WorksheetPageUpdate(e.pageKey, e.before!))),
                    new WDPageHistoryItem(pageHistory.filter(i => i.after !== undefined)
                        .map(e => new WorksheetPageUpdate(e.pageKey, e.after!)))
                )

                // console.log("Push history action " + options.historyAction, history)
                this.pushHistory(history)
            }

            // Write action log
            if (options?.actionCategory && pageHistory.length > 0) {
                this.context.addWDAction(WDActionLogType.Info, options.actionCategory, [], options.actionDescription)
            }

            // Set state after reforming group structure and resolve promise as callback for a synchronous update
            this.setState({ worksheet: worksheet }, resolve)
        });
    }

    writeHistoryItemToActionLog = (type: WDActionLogType, category: WDActionLogCategory, index: number) => {
        if (index >= 0 && this.state.historyStack.length > index - 1) {
            let historyItem = this.state.historyStack[this.state.historyIndex] as WDElementHistory

            let elements = this.getAllElementsRecursive()
            let details = historyItem.oldValue.updates
                .map(i => {
                    let element = elements.find(e => e.itemKey === i.itemKey)
                    if (element) {
                        let after = historyItem.newValue?.updates.find(i => i.itemKey === element!.itemKey)

                        return new WDActionLogEntryDetails(i.itemKey, element.worksheetItemTypeId, i.value, after?.value)
                    }
                    return null
                })
                .filter(i => i !== null) as WDActionLogEntryDetails[]

            this.context.addWDAction(type, category, details)
        }
    }
    deleteElement = (item: WorksheetItem) => {
        this.context.log.info("deleteElement - " + item.itemKey)
        this.context.log.flush()

        let updates: WorksheetItemUpdate[] = []
        updates.push(new WorksheetItemUpdate(item.itemKey, {deleted: true}))

        let items = this.getChildElementsRecursiveByElement(item)
        return updates.concat(items.map(item => new WorksheetItemUpdate(item.itemKey, {deleted: true})))
    }
    deleteChildren = async (itemKey: string) => {
        const item = this.state.elements.find(item => item.itemKey === itemKey)
        if (item) {
            await this.performUpdatesOnElements((item: WorksheetItem) => {
                return new WorksheetItemAction(this.deleteElement(item), [])
            }, item.children.filter(child => !child.deleted), {actionCategory: WDActionLogCategory.delete})
        }
    }
    setElementLayout = (item: WorksheetItem, layout: ElementLayout, adjustSize: boolean, resizeGroup: boolean) => {
        this.context.log.debug("setItemLayout " + item.itemKey + ": " + layout.left + " / " + layout.top)
        this.context.log.flush(LogLevel.INFO)

        layout = this.calculateElementCoords(item, layout)

        let update = new WorksheetItemUpdate(item.itemKey, {})
        if (item.ref && item.ref.current) {
            // Element-specific size calculation (e.g. math lineature based on cells)
            update = item.ref.current.recalculateSize(layout.width, layout.height, adjustSize)
        } else {
            update.value.width = layout.width
            update.value.height = layout.height
        }
        update.value.posX = layout.left
        update.value.posY = layout.top

        let updates: WorksheetItemUpdate[] = []

        // Resize group when child element is moved
        if (resizeGroup) {
            let parent = this.getParentElement(item.itemKey, this.state.elements)
            if (parent && parent.worksheetItemTypeId === WorksheetItemTypeEnum.GROUP) {
                this.resizeGroup(update, parent, updates)
            } else {
                updates.push(update)
            }
        } else {
            updates.push(update)
        }

        this.context.log.flush(LogLevel.INFO)

        return updates
    }
    unselectAllElements = async () => {
        this.context.log.debug("Unselect all elements")

        let updates: WorksheetItemUpdate[] = this.getSelectedElements(true)
            .map(item => new WorksheetItemUpdate(item.itemKey, {selected: false}))
        await this.updateElements(updates)

        // Close element toolbar
        this.context.log.debug("Close toolbar")
        this.closeToolbar()
        this.closeContextMenu()

        this.context.log.flush(LogLevel.INFO)
    }
    cancelElementEditing = async () => {
        let elements = this.state.elements.filter(item => item.selected && !item.locked)
        for (const item of elements) {
            await item.ref?.current?.onEditElement(false)
        }
    }

    pasteElements = async (elements: WorksheetItem[], pageKey?: string) => {
        if (elements.length === 0) {
            return
        }

        // Get min y coordinate of elements as reference
        let minY = elements.map(item => item.posY).reduce((a, b) => Math.min(a, b))

        await this.unselectAllElements()

        let items = elements.map((item, i) => {
            let maxZ = WDUtils.getMaxPosZOfElements(this.getElementsByPage(pageKey ? pageKey : item.worksheetPageKey))

            let newItem = WorksheetItem.duplicate(item, Converter.mmToPx(5), Converter.mmToPx(5))
            newItem.posZ = maxZ + i + 1
            newItem.selected = true
            newItem.deleted = false
            if (pageKey) {
                // Move to current view center relative to the Y coordinate of the first element
                newItem.posY = newItem.posY - minY + this.getCurrentViewCenterY()
                newItem.worksheetPageKey = pageKey
            }
            return newItem
        })

        this.context.log.info("Add duplication to history")
        this.context.log.flush()

        await this.updateElements([], {historyAction: WDHistoryAction.DUPLICATE}, items)
    }
    moveElement = (item: WorksheetItem, coords: Coords) => {
        coords.toMmGrid()

        return this.setElementLayout(item, new ElementLayout(
            coords.x,
            coords.y,
            Converter.toMmGrid(item.width),
            Converter.toMmGrid(item.height)
        ), false, true)
    }
    resizeGroup = (itemUpdate: WorksheetItemUpdate, parent: WorksheetItem, updates: WorksheetItemUpdate[]) => {
        this.context.log.debug("Resize group of " + itemUpdate.itemKey + " by " + itemUpdate.value.posX + " / " + itemUpdate.value.posY)

        let w = 0, h = 0

        let x = parent.children
            .map(item => item.itemKey === itemUpdate.itemKey ? itemUpdate.value.posX! : item.posX)
            .reduce((prevItem, curItem) => Math.min(prevItem, curItem))
        let y = parent.children
            .map(item => item.itemKey === itemUpdate.itemKey ? itemUpdate.value.posY! : item.posY)
            .reduce((prevItem, curItem) => Math.min(prevItem, curItem))

        for (let i = 0; i < parent.children.length; i++) {
            const child = parent.children[i];

            let update = new WorksheetItemUpdate(child.itemKey, {
                posX: child.posX - x,
                posY: child.posY - y
            })
            if (child.itemKey === itemUpdate.itemKey) {
                update = {...itemUpdate}
                update.value.posX! -= x
                update.value.posY! -= y
            }

            // Update the leftest item and set to x = 0
            if (update.value.posX === 0) {
                update.value.selectX = child.selectX + x
            }
            if (update.value.posY === 0) {
                update.value.selectY = child.selectY + y
            }

            updates.push(update)

            const layout = WDUtils.getBoundingRectOfElement(child)

            w = Math.max(w, update.value.posX! + layout.width)
            h = Math.max(h, update.value.posY! + layout.height)
        }

        this.context.log.debug("Parent: w = " + w + ", h = " + h + ", x = " + parent.posX + ", y = " + parent.posY)

        this.context.log.debug("Reposition parent from " + parent.posX + "/" + parent.posY + " to " + (parent.posX + x) + "/" + (parent.posY + y))
        let parentUpdate = new WorksheetItemUpdate(parent.itemKey, {
            posX: parent.posX + x,
            posY: parent.posY + y,
            width: w,
            height: h
        })
        updates.push(parentUpdate)

        let grandParent = this.getParentElement(parent.itemKey, this.state.elements)
        if (grandParent) {
            this.resizeGroup(parentUpdate, grandParent, updates)
        }

        this.context.log.flush(LogLevel.INFO)
    }
    resizeChildElements = (item: WorksheetItem, factorX: number, factorY: number, adjustSize: boolean): WorksheetItemUpdate[] => {
        let updates = item.children.map((child) => {
            let updates = this.resizeChildElements(child, factorX, factorY, adjustSize)

            let childLayout = ElementLayout.createFromWorksheetItem(child)
            childLayout.width *= factorX
            childLayout.height *= factorY
            childLayout.left *= factorX
            childLayout.top *= factorY

            return updates.concat(this.setElementLayout(child, childLayout, adjustSize, false))
        })
        return updates.flat()
    }
    rotateElement = (item: WorksheetItem, angle: number) => {
        while (angle >= 360) {
            angle -= 360
        }
        while (angle <= -360) {
            angle += 360
        }

        return new WorksheetItemUpdate(item.itemKey, {rotation: angle})
    }
    calculateElementCoords = (item: WorksheetItem, layout: ElementLayout) => {
        // For group elements of type text exercise check that images cannot be dragged or resized out of the section
        if (item.groupId) {
            let parent = this.getParentElement(item.itemKey, this.state.elements)
            if (parent && parent.worksheetItemTypeId === WorksheetItemTypeEnum.TEXT_EXERCISE) {

                const groupElement = document.getElementById(parent.itemKey + "-section-images")
                const element: HTMLElement | null = document.getElementById(item.itemKey + "-container")
                if (element && groupElement) {
                    let elementRect = element.getBoundingClientRect()
                    let sectionRect = groupElement.getBoundingClientRect()

                    layout.left = Math.min(layout.left, sectionRect.width - elementRect.width)
                    layout.top = Math.min(layout.top, sectionRect.height - elementRect.height)

                    let width = Math.min(layout.width, sectionRect.width)
                    layout.height = Math.round((width / layout.width) * layout.height)
                    layout.width = width

                    let height = Math.min(layout.height, sectionRect.height)
                    layout.width = Math.round((height / layout.height) * layout.width)
                    layout.height = height
                }

                layout.left = Math.max(layout.left, 0)
                layout.top = Math.max(layout.top, 0)
            }
        } else {
            layout.width = Math.max(layout.width, item.ref?.current?.getMinWidth() || 0)
            layout.height = Math.max(layout.height, item.ref?.current?.getMinHeight() || 0)
        }

        return layout
    }

    updateElementData = async (itemKey: string, data: string) => {
        this.context.log.debug("Update element data: " + itemKey)
        this.context.log.flush()

        await this.updateElements([new WorksheetItemUpdate(itemKey, {content: data})],
            {historyAction: WDHistoryAction.CONTENT_CHANGED})
    }

    onAddRule = (ruleId: number) => {
        let selected = this.getSelectedElements(false)
        if (selected && selected.length > 0) {
            let refItem = selected[0]
            let elementPos = new Coords(refItem.posX + refItem.width, refItem.posY)

            GetRuleWorksheetItems(ruleId).then(
                (worksheetItems) => {
                    let initial: Coords
                    worksheetItems
                        .sort((a, b) => a.posX - b.posX)
                        .forEach(async (wi, i) => {
                            if (i === 0) {
                                initial = new Coords(wi.posX, wi.posY)
                            }

                            let element = WorksheetItem.duplicate(wi)
                            element.worksheetPageKey = refItem.worksheetPageKey
                            element.posX = elementPos.x + (wi.posX - initial.x)
                            element.posY = elementPos.y + (wi.posY - initial.y)
                            element.selected = true
                            element.resized = false
                            element.changed = true
                            element.ref = WorksheetItemType.getReactRefByWorksheetItemType(wi.worksheetItemTypeId)

                            await this.updateElements([], {historyAction: WDHistoryAction.CREATE}, [element])
                        })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.element_creating_error))
                }
            )
        }
    }

    /**
     * Element array methods
     * */
    getChildElementsRecursiveByElements = (elements: WorksheetItem[]): WorksheetItem[] => {
        let result: WorksheetItem[] = []
        elements
            .forEach(item => {
                result.push(item)
                result = result.concat(this.getChildElementsRecursiveByElement(item))
            })
        return result
    }
    getChildElementsRecursiveByElement = (item: WorksheetItem): WorksheetItem[] => {
        let array: WorksheetItem[] = []

        item.children.forEach(c => {
            array.push(c)
            array = array.concat(this.getChildElementsRecursiveByElement(c))
        })

        return array
    }
    getAllElementsRecursive = (): WorksheetItem[] => {
        return this.getChildElementsRecursiveByElements(this.state.elements)
    }
    getSelectedElements = (applyToLocked: boolean): WorksheetItem[] => {
        return this.getChildElementsRecursiveByElements(this.state.elements)
            .filter(item => item.selected && !item.deleted && (applyToLocked || !item.locked))
    }
    getParentElement = (childItemKey: string, elements: WorksheetItem[]): WorksheetItem | undefined => {

        elements = elements.filter(element => element.children !== undefined && element.children.length > 0)
        for (let i = 0; i < elements.length; i++) {
            let found = elements[i].children.find(element => element.itemKey === childItemKey)
            if (found) {
                return elements[i]
            }

            found = this.getParentElement(childItemKey, elements[i].children);
            if (found) {
                return found
            }
        }

        return undefined
    }
    getElementsByPage = (pageKey: string): WorksheetItem[] => {
        return WDUtils.getWorksheetItemsByPage(this.state.elements, pageKey)
    }

    /**
     * Transformation operations (resize, rotate, translate) and event handler
     * */
    performUpdatesOnElements = async (action: (item: WorksheetItem, index?: number) => WorksheetItemAction,
                                      elements: WorksheetItem[], options?: WorksheetItemUpdateOptions) => {

        if (this.state.worksheet?.context === WSContextType.text_exercise_child &&
            (options?.historyAction !== WDHistoryAction.TOOLBAR_ACTION && options?.historyAction !== WDHistoryAction.DELETE)) {
            return
        }

        let toolbarOpen = this.state.isToolbarOpen

        this.context.log.debug("Perform action " + options?.historyAction + " on " + elements.length + " elements ...")
        let itemActions = elements.map((item, i) => action(item, i))

        // Process all actions (updates and new elements)
        let updates: WorksheetItemUpdate[] = []
        let newElements: WorksheetItem[] = []
        itemActions.forEach(a => {
            updates = updates.concat(a.updates.filter(u => Object.keys(u.value).length > 0))
            newElements = newElements.concat(a.newItems)
        })

        if (updates.length > 0 || newElements.length > 0) {
            this.context.log.debug("Perform " + updates.length + " updates and create " + newElements.length + " elements ...")
            updates.forEach(u => this.context.log.debug("Update", u))

            this.context.log.flush(LogLevel.INFO)

            await this.updateElements(updates, options, newElements)
        }
        this.context.log.flush(LogLevel.INFO)

        // Don't open toolbar after action, so it's not opened on context menu action
        if (toolbarOpen) {
            this.openToolbar()
        }
    }
    performUpdatesOnSelectedElements = async (action: (item: WorksheetItem, index?: number) => WorksheetItemAction,
                                              options: WorksheetItemUpdateOptions,
                                              itemSortClause?: (a: WorksheetItem, b: WorksheetItem) => number) => {

        let applyToChildren = options.applyToChildren === undefined ? true : options.applyToChildren
        let applyToLocked = options.applyToLocked === undefined ? false : options.applyToLocked
        let applyRecursive = options.applyRecursive === undefined ? true : options.applyRecursive

        let selected = applyToChildren ?
            this.getSelectedElements(applyToLocked) :
            this.state.elements.filter(item => item.selected && (applyToLocked || !item.locked))

        if (applyRecursive) {
            selected = this.getChildElementsRecursiveByElements(selected)
        }

        if (selected.length === 0) {
            return
        }

        if (itemSortClause) {
            selected = selected.sort(itemSortClause)
        }

        await this.performUpdatesOnElements(action, selected, options)
    }

    onResizeStateChanged = async (state: boolean, nodeType?: RESIZE_NODE) => {
        // Add to history when starting resize and update when resize ends
        if (state && this.state.mode !== WDElementMode.RESIZE) {
            this.context.log.info("onResizeStateChanged: " + WDElementMode.RESIZE + " with node " + nodeType)

            this.setToolbarVisibility(false, false)

            this.setState({mode: WDElementMode.RESIZE, resizeNode: nodeType})
        } else if (!state && this.state.mode !== WDElementMode.NONE) {
            this.context.log.info("onResizeStateChanged: " + WDElementMode.NONE)

            this.setToolbarVisibility(true, false)
            this.openToolbar()

            this.setState({mode: WDElementMode.NONE, resizeNode: undefined})
        }
        this.context.log.flush()

        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let update = new WorksheetItemUpdate(item.itemKey, {resized: state})
            if (item.ref && item.ref.current) {
                update = item.ref?.current?.setResizeState(state, nodeType)
            }
            return new WorksheetItemAction([update], [])
        }, {
            applyRecursive: false,
            historyAction: WDHistoryAction.RESIZE,
            updateHistory: !state,
            actionCategory: WDActionLogCategory.resize
        })
    }
    onElementResize = async (proportional: boolean, x: number, y: number) => {
        this.context.log.debug("Resizing " + proportional + " by " + x + "/" + y)
        this.context.log.flush(LogLevel.INFO)

        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates: WorksheetItemUpdate[] = []

            // Resize selected element by x / y, returns new container size
            const minUnit = Converter.mmToPx(1)
            if (Math.abs(x) <= minUnit && Math.abs(y) <= minUnit) {
                return new WorksheetItemAction(updates, [])
            }

            let coords = new Coords(Converter.toMmGrid(x), Converter.toMmGrid(y))

            const update =
                item.ref?.current?.resizeElement(proportional, coords.x, coords.y, this.state.resizeNode)
            if (update) {
                let width = (update.value.width || item.width)
                let height = (update.value.height || item.height)

                this.context.log.debug("Resize element " + item.itemKey + " to " + width + " / " + height)

                updates = this.resizeChildElements(item, width / item.width, height / item.height, false)
                updates = updates.concat(this.setElementLayout(item, new ElementLayout(update.value.posX!, update.value.posY!, width, height), false, true))

                // Update content if it was changed by resizeElement method of element
                if (update.value.content) {
                    updates.push(new WorksheetItemUpdate(item.itemKey, {content: update.value.content}))
                }
            }

            return new WorksheetItemAction(updates, [])
        }, {applyRecursive: false})

        this.context.log.flush(LogLevel.INFO)
    }

    /**
     * Updates the element properties (width, height, posX, posY, rotation, content) based on the provided update.
     * @param item Element to update
     * @param update WorksheetItemUpdate containing all values to update
     * @param options WorksheetItemUpdateOptions containing additional options
     */
    updateElement = (item: WorksheetItem, update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => {
        let updates: WorksheetItemUpdate[] = []
        let baseUpdate = _.cloneDeep(update)
        //console.log("update element", item, update.value, options)

        // WIDTH & HEIGHT
        baseUpdate.itemKey = item.itemKey
        if (baseUpdate.value.width !== undefined || baseUpdate.value.height !== undefined) {
            let layout = new ElementLayout(item.posX, item.posY, item.width, item.height)

            let width = baseUpdate.value.width
            let height = baseUpdate.value.height

            // if one of the parameters is null (entered by toolbar) -> resize the other if proportionally
            if (height === undefined || width === undefined) {
                if (height !== undefined && options?.proportional === true) {
                    width = Math.round((height / item.height) * item.width)
                    if (width < item.minWidth) {
                        width = item.minWidth
                    }
                } else if (width !== undefined && options?.proportional === true) {
                    height = Math.round((width / item.width) * item.height)
                    if (height < item.minHeight) {
                        height = item.minHeight
                    }
                } else {
                    width = width !== undefined ? width : item.width
                    height = height !== undefined ? height : item.height
                }
            }

            layout.width = width!
            layout.height = height!

            if (options?.applyToChildren === undefined || options?.applyToChildren) {
                updates = this.resizeChildElements(item, layout.width / item.width, layout.height / item.height, true)
            }
            updates = updates.concat(this.setElementLayout(item, layout, true, true))

            // Remove width and height from baseUpdate, because the object specific width and height are calculated above
            delete baseUpdate.value.width
            delete baseUpdate.value.height
        }
        if (baseUpdate.value.posX !== undefined || baseUpdate.value.posY !== undefined) {
            updates = updates.concat(this.moveElement(item,
                new Coords(
                    baseUpdate.value.posX ? baseUpdate.value.posX : item.posX,
                baseUpdate.value.posY ? baseUpdate.value.posY : item.posY
                )
            ))
        }
        if (baseUpdate.value.rotation) {
            let rotationUpdate = this.rotateElement(item, baseUpdate.value.rotation)
            baseUpdate.value.rotation = rotationUpdate.value.rotation
        }
        if (baseUpdate.value.content && baseUpdate.itemKey !== item.itemKey) {
            // Obsolete?
            console.warn("Set content undefined because " + item.itemKey + " != " + baseUpdate.itemKey)
            baseUpdate.value.content = undefined
        }

        // Find baseUpdate for the given element and copy data to the parameter baseUpdate, so it is prepared
        let itemUpdate = updates.find(u => u.itemKey === item.itemKey)
        if (itemUpdate) {
            // Copy all value not yet set on baseUpdate from itemUpdate
            Object.keys(itemUpdate.value).forEach(k => {
                if (baseUpdate.value[k] === null || baseUpdate.value[k] === undefined) {
                    baseUpdate.value[k] = itemUpdate!.value[k]
                }
            })
        }

        // baseUpdate for current item removed, it will be added after all transformations coming below
        updates = updates.filter(u => u.itemKey !== item.itemKey)
        updates.push(baseUpdate)

        return updates
    }

    /**
     * Executes a WorksheetItemUpdate on a specific element.
     * This method is called for example when an element is raising an update event.
     * @param update WorksheetItemUpdate containing all values to update
     * @param options WorksheetItemUpdateOptions containing additional options
     */
    onUpdateElement = async (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => {
        let item = this.getAllElementsRecursive().find(item => item.itemKey === update.itemKey)
        if (item) {
            await this.performUpdatesOnElements((item: WorksheetItem) => {
                let updates = this.updateElement(item, update, options)
                return new WorksheetItemAction(updates, [])
            }, [item], options)
        }
    }
    /**
     * Executes a WorksheetItemUpdate on all selected elements. The ID defined in the update is ignored.
     * This method is called for example when the toolbar is used to change the size of all selected elements.
     * @param update WorksheetItemUpdate containing all values to update
     * @param options WorksheetItemUpdateOptions containing additional options
     */
    onUpdateSelectedElements = async (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            // console.log("onUpdateSelectedElements", update.value)
            let updates = this.updateElement(item, update, options)

            return new WorksheetItemAction(updates, [])
        }, options || {})
    }

    onChangeElementPositionOffset = async (x: number, y: number) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates = this.moveElement(item, new Coords(item.posX + x, item.posY + y))
            return new WorksheetItemAction(updates, [])
        }, {applyRecursive: false, historyAction: WDHistoryAction.MOVE, actionCategory: WDActionLogCategory.position})

        this.autoSaveWorksheet()
    }
    onChangeElementRotationBy = async (angle: number) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates = [this.rotateElement(item, item.rotation + angle)]
            return new WorksheetItemAction(updates, [])
        }, {applyRecursive: false, historyAction: WDHistoryAction.ROTATE, actionCategory: WDActionLogCategory.rotate})
        this.autoSaveWorksheet()
    }
    onChangeElementBorder = async (border: ElementBorder) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates = [new WorksheetItemUpdate(item.itemKey, {
                borderVisible: border.visible && border.style !== "none",
                borderStyle: border.style === null || border.style === undefined ? "solid" : border.style,
                borderWeight: border.weight === null || border.weight === undefined ? 1 : border.weight,
                borderColor: border.color
            })]
            return new WorksheetItemAction(updates, [])
        }, {
            applyRecursive: false,
            historyAction: WDHistoryAction.BORDER_COLOR,
            actionCategory: WDActionLogCategory.border
        })

        this.autoSaveWorksheet()
    }
    onChangeElementContainerBorder = async (border: ElementBorder) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates = [new WorksheetItemUpdate(item.itemKey, {
                borderVisible: border.visible,
                borderStyle: border.style,
                borderColor: border.color,
                borderWeight: border.weight,
                paddingTop: border.paddingTop,
                paddingRight: border.paddingRight,
                paddingBottom: border.paddingBottom,
                paddingLeft: border.paddingLeft,
                linkPadding: border.linkPadding
            })]
            return new WorksheetItemAction(updates, [])
        }, {applyRecursive: false, historyAction: WDHistoryAction.BORDER, actionCategory: WDActionLogCategory.border})

        this.autoSaveWorksheet()
    }
    onChangeElementFlip = async (direction: string) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates = [new WorksheetItemUpdate(item.itemKey, {
                [direction]: !item[direction]
            })]
            return new WorksheetItemAction(updates, [])
        }, {applyRecursive: false, historyAction: WDHistoryAction.FLIP, actionCategory: WDActionLogCategory.flip})

        this.autoSaveWorksheet()
    }
    onChangeElementLockingStatus = async (locked: boolean) => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let updates = [new WorksheetItemUpdate(item.itemKey, {locked: locked})]
            return new WorksheetItemAction(updates, [])
        }, {
            applyToLocked: true,
            applyRecursive: false,
            historyAction: WDHistoryAction.LOCK,
            actionCategory: WDActionLogCategory.lock
        })

        this.autoSaveWorksheet()
    }
    onChangeElementGroupingStatus = async () => {
        const elements = this.getSelectedElements(false)

        // Group selected elements
        if (elements.length > 1) {
            await this.groupElements(elements)
        }
        // Ungroup
        else if (elements.length === 1 && elements[0].worksheetItemTypeId === WorksheetItemTypeEnum.GROUP) {
            await this.ungroupElements(elements[0])

            // this.closeToolbar()
            this.closeContextMenu()
        }

        this.openToolbar()
        this.autoSaveWorksheet()

        this.context.log.flush()
    }

    onElementSelect = async (itemKey: string, resetSelection: boolean, newState?: boolean) => {
        // Do not allow selection when editing is not allowed
        if (!this.isEditingAllowed()) {
            return;
        }

        this.context.log.debug("Selecting " + itemKey + ", new state = " + newState + ", reset = " + resetSelection)

        let parentActual: WorksheetItem | undefined = undefined
        let selectedElements = this.getSelectedElements(false)
        if (selectedElements && selectedElements.length > 0) {
            parentActual = this.getParentElement(selectedElements[0].itemKey, this.state.elements)
            this.context.log.debug("Parent of element: " + parentActual?.itemKey)
        }

        let updates: WorksheetItemUpdate[] = []
        let item = this.getChildElementsRecursiveByElements(this.state.elements)
            .find(item => item.itemKey === itemKey)

        if (item) {
            if (resetSelection && !item.selected) {
                updates = this.getChildElementsRecursiveByElements(this.state.elements)
                    .filter(item => item.selected && item.itemKey !== itemKey)
                    .map(item => {
                        this.context.log.debug("Set selection of " + item.itemKey + " to false")
                        return new WorksheetItemUpdate(item.itemKey, {selected: false})
                    })
            }

            let selectItem = true

            // Select additional element -> check if they have the same parent (inside same group)
            if (!resetSelection && newState && parentActual !== undefined) {
                selectItem = false
                let parentFound = this.getParentElement(item.itemKey, this.state.elements)
                this.context.log.debug("Parent of found element: " + parentFound?.itemKey)

                if (parentFound?.itemKey === parentActual?.itemKey) {
                    this.context.log.debug("Same parent -> select")
                    selectItem = true
                }
            }

            if (selectItem) {
                this.context.log.debug("Item found - change selection")
                updates.push(new WorksheetItemUpdate(item.itemKey, {selected: newState ? newState : !item.selected}))
            }
        }

        this.context.log.flush()
        await this.updateElements(updates)

        // Open element toolbar
        if (this.getSelectedElements(true).length === 0) {
            this.closeToolbar()
            this.closeContextMenu()
        } else {
            this.openToolbar()
        }

        this.context.log.flush(LogLevel.INFO)
    }
    onElementLoaded = (id: string) => {
        // Reset changed flag after existing element is loaded completely, so it is not dirty on save
        const item = this.state.elements.find(item => item.itemKey === id)
        if (item && item.id) {
            item.changed = false
        }

        // Reload toolbar after element is loaded completely (e.g. image)
        if (this.state.isToolbarOpen) {
            this.openToolbar()
        }
    }
    onElementEdit = async (itemKey: string, editMode: boolean) => {
        this.context.log.debug("Set edit mode " + editMode + " for item " + itemKey)
        this.context.log.flush(LogLevel.INFO)

        // Save edit mode in item to force re-rendering in WDSheet
        let item = this.getAllElementsRecursive()
            .find(i => i.itemKey === itemKey)
        if (item) {
            await this.updateElements([new WorksheetItemUpdate(itemKey, {edited: editMode})])
        }

        this.setState({mode: editMode ? WDElementMode.EDIT : WDElementMode.NONE},
            () => {
                if (this.state.mode === WDElementMode.NONE) {
                    this.autoSaveWorksheet()
                }
                this.openToolbar()
            })
    }
    onDeleteElement = async () => {
        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            return new WorksheetItemAction(this.deleteElement(item), [])
        }, {applyRecursive: false, historyAction: WDHistoryAction.DELETE, actionCategory: WDActionLogCategory.delete})

        this.context.log.info(this.state.elements.filter(e => e.deleted).length + " have been deleted")

        this.closeToolbar()
        this.closeContextMenu()
    }
    onCutElement = () => {
        this.onCopyElement(this.onDeleteElement)
    }
    onCopyElement = (callback?: () => void) => {
        this.context.log.info("Copy selected elements")

        let selected = this.getSelectedElements(false)
        this.setState({clipboard: Util.cloneArray<WorksheetItem>(selected)}, callback)

        this.closeContextMenu()
    }
    onPasteElement = async () => {
        this.context.log.info("Paste elements from clipboard")
        await this.pasteElements(this.state.clipboard, this.getCurrentPageKey())

        this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.paste,
            this.state.clipboard.map(i => new WDActionLogEntryDetails(i.itemKey, i.worksheetItemTypeId, i, undefined)))

        this.autoSaveWorksheet()
        this.openToolbar()
    }
    onDuplicateElement = async () => {
        this.context.log.info("Duplicate selected elements")

        let selected = this.getSelectedElements(false)
        await this.pasteElements(selected)

        this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.duplicate,
            selected.map(i => new WDActionLogEntryDetails(i.itemKey, i.worksheetItemTypeId, i, undefined)))

        this.autoSaveWorksheet()
        this.openToolbar()
    }
    onElementConvert = async (itemKey: string, elementType: WorksheetItemTypeEnum, data: any) => {
        this.context.log.debug("Converting " + itemKey + " to " + elementType)

        let item = this.getChildElementsRecursiveByElements(this.state.elements).find(item => item.itemKey === itemKey)
        if (item) {
            // Conversion from text box to writing lineature
            if (elementType === WorksheetItemTypeEnum.WRITING_LINEATURE && item.worksheetItemTypeId === WorksheetItemTypeEnum.TEXTBOX) {
                let update = new WorksheetItemUpdate(item.itemKey, {
                    worksheetItemTypeId: WorksheetItemTypeEnum.WRITING_LINEATURE,
                    content: data,
                    resized: false,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingRight: 0
                })
                await this.updateElements([update], {historyAction: WDHistoryAction.TOOLBAR_ACTION}, [])

                this.closeToolbar()
                this.closeContextMenu()
            }
        }

        this.context.log.flush()
    }
    onArrangeElement = async (mode: WDElementArrange) => {
        this.context.log.info("Arrange selected elements, mode = " + mode)
        this.context.log.flush()

        let selected = this.getSelectedElements(false)
        if (selected.length === 0) {
            return
        }

        let actionDescription = "Arrange elements " + selected.map(e => e.itemKey).join(", ") + " with mode = " + mode

        let updates: WorksheetItemUpdate[] = []
        selected.forEach((item: WorksheetItem) => {
            this.context.log.info("Arrange element = " + item.itemKey)

            let pageElements = this.getElementsByPage(item.worksheetPageKey)

            let oldPosZ = item.posZ || 0
            let newPosZ = 1
            let maxZ = pageElements.length // WDUtils.getMaxPosZOfElements(pageElements)
            let factor = 1

            // Calculate new index (min. 1, max. current max Z of page)
            if (mode === WDElementArrange.down) {
                newPosZ = Math.max(oldPosZ - 1, 1)
            } else if (mode === WDElementArrange.up) {
                newPosZ = Math.min(oldPosZ + 1, maxZ)
            } else if (mode === WDElementArrange.top) {
                newPosZ = maxZ
            }

            // Get elements that need to be moved
            let movingElements: WorksheetItem[]
            if (mode === WDElementArrange.bottom || mode === WDElementArrange.down) {
                movingElements = pageElements.filter(e => {
                    let z = (e.posZ || 0)
                    return z <= oldPosZ && z >= newPosZ && e.itemKey !== item.itemKey
                })
            } else {
                factor = -1

                movingElements = pageElements.filter(e => {
                    let z = (e.posZ || 0)
                    return z >= oldPosZ && z <= newPosZ && e.itemKey !== item.itemKey
                })
            }

            movingElements.forEach(e => {
                let oldIndex = (e.posZ || 0)
                let newIndex = Math.max(oldIndex + factor, 1)
                this.context.log.info("Re-arrange element = " + e.itemKey + " from " + oldIndex + " to " + newIndex)

                updates.push(new WorksheetItemUpdate(e.itemKey, {posZ: newIndex}))
            })

            this.context.log.info("Arrange element = " + item.itemKey + " to " + newPosZ)
            updates.push(new WorksheetItemUpdate(item.itemKey, {posZ: newPosZ}))

            this.context.log.flush()
        })

        await this.updateElements(updates, {
            historyAction: WDHistoryAction.ARRANGE,
            actionCategory: WDActionLogCategory.arrange,
            actionDescription: actionDescription
        })
    }
    onAlignElement = async (mode: WDElementAlignment) => {
        this.context.log.info("Align selected elements, mode = " + mode)
        this.context.log.flush()

        let selectedElements = this.getSelectedElements(false)
        if (selectedElements === undefined || selectedElements === null || selectedElements.length === 0) {
            return
        }

        let selectedLayouts = selectedElements.map(e => WDUtils.getBoundingRectOfElement(e))

        let left = 0, right = 0, top = 0, bottom = 0
        if (mode === WDElementAlignment.left || mode === WDElementAlignment.horizontal_center) {
            left = selectedLayouts.reduce((a, b) => a.left < b.left ? a : b).left
        }
        if (mode === WDElementAlignment.right || mode === WDElementAlignment.horizontal_center) {
            let rightElement = selectedLayouts.reduce((a, b) => (((a.left + a.width) > (b.left + b.width)) ? a : b))
            right = rightElement.left + rightElement.width
        }
        if (mode === WDElementAlignment.top || mode === WDElementAlignment.vertical_center) {
            top = selectedLayouts.reduce((a, b) => a.top < b.top ? a : b).top
        }
        if (mode === WDElementAlignment.bottom || mode === WDElementAlignment.vertical_center) {
            let bottomElement = selectedLayouts.reduce((a, b) => (((a.top + a.height) > (b.top + b.height)) ? a : b))
            bottom = bottomElement.top + bottomElement.height
        }

        await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
            let rect = WDUtils.getBoundingRectOfElement(item)

            if (mode === WDElementAlignment.left) {
                return new WorksheetItemAction(this.moveElement(item, new Coords(left, item.posY)), [])
            } else if (mode === WDElementAlignment.right) {
                return new WorksheetItemAction(this.moveElement(item, new Coords(right - rect.width, item.posY)), [])
            } else if (mode === WDElementAlignment.top) {
                return new WorksheetItemAction(this.moveElement(item, new Coords(item.posX, top)), [])
            } else if (mode === WDElementAlignment.bottom) {
                return new WorksheetItemAction(this.moveElement(item, new Coords(item.posX, bottom - rect.height)), [])
            } else if (mode === WDElementAlignment.horizontal_center) {
                return new WorksheetItemAction(this.moveElement(item, new Coords((right + left) / 2 - rect.width / 2, item.posY)), [])
            } else if (mode === WDElementAlignment.vertical_center) {
                return new WorksheetItemAction(this.moveElement(item, new Coords(item.posX, (bottom + top) / 2 - rect.height / 2)), [])
            }
            return new WorksheetItemAction([], [])
        }, {applyRecursive: false, historyAction: WDHistoryAction.ALIGN, actionCategory: WDActionLogCategory.align})

        this.autoSaveWorksheet()
    }
    onDistributeElement = async (mode: WDElementDistributeMode) => {
        this.context.log.info("Distribute selected elements, mode = " + mode)
        this.context.log.flush()

        let selectedElements = this.getSelectedElements(false)
        if (selectedElements === undefined || selectedElements === null || selectedElements.length === 0) {
            return
        }
        let selectedLayouts = selectedElements.map(e => WDUtils.getBoundingRectOfElement(e))

        if (mode === WDElementDistributeMode.horizontal) {
            let left = selectedLayouts.reduce((a, b) => a.left < b.left ? a : b).left

            let rightElement = selectedLayouts.reduce((a, b) => (((a.left + a.width) > (b.left + b.width)) ? a : b))
            let right = rightElement.left + rightElement.width

            let totalItemWidth = 0
            selectedLayouts.forEach(i => totalItemWidth += i.width)

            let canvasWidth = right - left
            let itemSpace = (canvasWidth - totalItemWidth) / (selectedLayouts.length - 1)
            let w = left

            const sort = (a: WorksheetItem, b: WorksheetItem) => {
                let rectA = WDUtils.getBoundingRectOfElement(a)
                let rectB = WDUtils.getBoundingRectOfElement(b)

                return rectA.left - rectB.left
            }

            await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
                let rect = WDUtils.getBoundingRectOfElement(item)
                let updates = this.moveElement(item, new Coords(w, item.posY))

                w += rect.width + itemSpace

                return new WorksheetItemAction(updates, [])
            }, {
                applyRecursive: false,
                historyAction: WDHistoryAction.BORDER_COLOR,
                actionCategory: WDActionLogCategory.distribute
            }, sort)
        } else if (mode === WDElementDistributeMode.vertical) {
            let top = selectedLayouts.reduce((a, b) => a.top < b.top ? a : b).top

            let bottomElement = selectedLayouts.reduce((a, b) => (((a.top + a.height) > (b.top + b.height)) ? a : b))
            let bottom = bottomElement.top + bottomElement.height

            let totalItemHeight = 0
            selectedLayouts.forEach(i => totalItemHeight += i.height)

            let canvasHeight = bottom - top
            let itemSpace = (canvasHeight - totalItemHeight) / (selectedLayouts.length - 1)
            let h = top

            const sort = (a: WorksheetItem, b: WorksheetItem) => {
                let rectA = WDUtils.getBoundingRectOfElement(a)
                let rectB = WDUtils.getBoundingRectOfElement(b)

                return rectA.top - rectB.top
            }

            await this.performUpdatesOnSelectedElements((item: WorksheetItem) => {
                let rect = WDUtils.getBoundingRectOfElement(item)
                let updates = this.moveElement(item, new Coords(item.posX, h))

                h += rect.height + itemSpace

                return new WorksheetItemAction(updates, [])
            }, {
                applyRecursive: false,
                historyAction: WDHistoryAction.DISTRIBUTE,
                actionCategory: WDActionLogCategory.distribute
            }, sort)
        }

        this.autoSaveWorksheet()
    }

    applyZoomOnCoordinates = (currentPos: Coords) => {
        let zoom = this.context.getZoom()
        return new Coords(currentPos.x / zoom, currentPos.y / zoom)
    }

    onPropagateElementEvent = (event: React.UIEvent, itemKey: string) => {
        this.context.log.debug("Propagate event = " + event.type + " to element = " + itemKey)
        this.context.log.flush()

        const item = this.getChildElementsRecursiveByElements(this.state.elements)
            .find(item => item.itemKey === itemKey)
        if (item) {
            item.ref?.current?.onPropagateEvent(event)
        }
    }

    /**
     * Page events
     * */
    onPageAdd = async (pageBefore: WorksheetPage, amount: number, scroll?: boolean) => {
        this.context.log.info("Adding " + amount + " page(s) after " + pageBefore.sort)

        const ws = this.state.worksheet
        if (ws) {
            const newSort = pageBefore.sort + 1
            let newPageKey = ""

            if (ws.pages === undefined) {
                ws.pages = []
            }

            // Check max. page count
            if (ws.pages.length + amount > Const.DESIGNER_MAX_PAGE_COUNT) {
                this.context.setNotification(new Notification(
                    this.context.translate(translations.notification.max_pages),
                    NotificationStatus.error,
                    this.context.translate(translations.notification.max_pages),
                    this.context.translate(translations.notification.title_error)
                ))
                return
            }

            // Re-Index pages
            let updates = ws.pages
                .filter(p => p.sort >= newSort)
                .map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {sort: p.sort + amount}))

            let newPages: WorksheetPage[] = []
            for (let i = 0; i < amount; i++) {
                newPageKey = WorksheetPage.getNewPageKey()
                newPages.push(new WorksheetPage(WorksheetPage.getNewPageKey(), false,
                    new PageBorderPosition(pageBefore.borderLeft, pageBefore.borderTop, pageBefore.borderRight, pageBefore.borderBottom, pageBefore.linkBorders),
                    new PageBorderPosition(pageBefore.trimBorderLeft, pageBefore.trimBorderTop, pageBefore.trimBorderRight, pageBefore.trimBorderBottom, pageBefore.linkBorders),
                    newSort, pageBefore.orientation))

                this.context.log.info("Append new page")
            }

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.PAGE_ADD,
                actionCategory: WDActionLogCategory.add_page,
                actionDescription: "Add " + amount + " pages after " + WorksheetPage.getUniqueElementIdentifier(pageBefore)
            }, newPages)

            this.autoSaveWorksheet()

            if (scroll === true) {
                WDUtils.scrollToPage(newPageKey, -200, this.context.log.info)
            }
        }

        this.context.log.flush()
    }
    onPageDelete = async (pages: WorksheetPage[]) => {
        if (this.state.worksheet) {
            let updates = pages.map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {deleted: true}))

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.PAGE_DELETE,
                actionCategory: WDActionLogCategory.delete_page,
                actionDescription: "Delete " + pages.map(p => WorksheetPage.getUniqueElementIdentifier(p)).join(", ")
            })

            this.setState({generateThumbnailOnSave: true}, this.autoSaveWorksheet)
        }
    }
    onPageCopy = async (pages: WorksheetPage[]) => {
        const ws = this.state.worksheet
        if (ws && ws.pages) {

            // Check max. page count
            if (ws.pages.length + pages.length > Const.DESIGNER_MAX_PAGE_COUNT) {
                this.context.setNotification(new Notification(
                    this.context.translate(translations.notification.max_pages),
                    NotificationStatus.error,
                    this.context.translate(translations.notification.max_pages),
                    this.context.translate(translations.notification.title_error)))
                return
            }

            // console.log("All pages", pages.sort((a, b) => a.sort - b.sort))

            // Get the highest sort index of pages to copy
            let index = pages
                .map(p => p.sort)
                .reduce(((p, c) => (p < c ? c : p)))

            this.context.log.debug("Highest page index = " + index)
            ws.pages.forEach(p => this.context.log.debug(WorksheetPage.getUniqueElementIdentifier(p) + " = " + p.sort))

            // Re-Index pages
            let updates: WorksheetPageUpdate[] = ws.pages
                .filter(p => p.sort > index)
                .map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {sort: p.sort + pages.length}))

            // Duplicate pages
            let actionDescription = ""
            let newPages: WorksheetPage[] = []
            for (const c of pages) {
                let copyPageKey = WorksheetPage.getUniqueElementIdentifier(c)
                index++

                let page = ws.pages!.find(p => WorksheetPage.getUniqueElementIdentifier(p) === copyPageKey)
                if (page) {
                    this.context.log.info("Duplicate page " + copyPageKey + " on index " + c.sort + " to index " + index)

                    if (actionDescription !== "") {
                        actionDescription += ", "
                    }
                    actionDescription += copyPageKey + " to " + index

                    newPages.push(await this.duplicatePage(page, index))
                }
            }

            ws.pages.forEach(p => this.context.log.debug(WorksheetPage.getUniqueElementIdentifier(p) + " = " + p.sort))

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.PAGE_COPY,
                actionCategory: WDActionLogCategory.copy_page,
                actionDescription: actionDescription
            }, newPages)

            this.context.log.flush()
            this.setState({worksheet: ws}, this.autoSaveWorksheet)
        }
    }
    onPageMove = async (pages: WorksheetPage[], pageBefore: WorksheetPage) => {
        this.context.log.info("Moving pages before page " + pageBefore.sort)

        const ws = this.state.worksheet
        if (ws) {
            const newSort = pageBefore.sort

            if (ws.pages === undefined) {
                ws.pages = []
            }

            // Re-Index pages
            const movedPageKeys = pages.map(p => WorksheetPage.getUniqueElementIdentifier(p))
            let updates: WorksheetPageUpdate[] = ws.pages
                .filter(p => p.sort >= newSort && !movedPageKeys.includes(WorksheetPage.getUniqueElementIdentifier(p)))
                .map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {sort: p.sort + pages.length}))

            let sortIndex = newSort
            updates = updates.concat(pages
                .sort((a, b) => a.sort - b.sort)
                .map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {sort: sortIndex++})))

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.PAGE_MOVE,
                actionCategory: WDActionLogCategory.move_page,
                actionDescription: "Move " + pages.map(p => WorksheetPage.getUniqueElementIdentifier(p)).join(", ") + " before " + pageBefore.sort
            })

            this.setState({worksheet: ws, generateThumbnailOnSave: true}, this.autoSaveWorksheet)
        }

        this.context.log.flush()
    }

    onChangePageKeySolution = (coords: Coords) => {
        if (this.state.worksheet?.pages) {
            let page = WDUtils.getPageElementByCoords(coords, this.state.worksheet?.pages)
            if (page) {
                this.onChangePageSolutions([page])
            }
        }
    }
    onChangePageSolution = async(page: WorksheetPage) => {
        await this.onChangePageSolutions([page])
    }
    onChangePageSolutions = async (pages: WorksheetPage[]) => {
        let worksheet = this.state.worksheet
        if (worksheet) {
            if (worksheet.pages === undefined) {
                worksheet.pages = []
            }

            let actionDescription = ""

            let updates = pages.map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {solution: !p.solution}))

            updates.forEach(update => {
                actionDescription += update.key + ".solution = " + update.value.solution
            })

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.PAGE_SOLUTION,
                actionCategory: WDActionLogCategory.page_solution,
                actionDescription: actionDescription
            })

            this.closeContextMenu()
            this.autoSaveWorksheet()
        }
    }
    onChangePageOrientation = async(pages: WorksheetPage[], orientation: boolean) => {
        let worksheet = this.state.worksheet
        if (worksheet) {
            if (worksheet.pages === undefined) {
                worksheet.pages = []
            }

            let generateThumbnailOnSave = false

            let updates: WorksheetPageUpdate[] = [];
            for (const page of pages) {
                let position = worksheet.pages.sort((a, b) => a.sort - b.sort).lastIndexOf(page)
                if (position === 0) {
                    generateThumbnailOnSave = true
                }

                let update = new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(page), {
                    orientation: orientation
                })

                if (page.borderImage) {
                    let image = await GetImageWithCounterpartImageInfo(page.borderImage)
                    if (image.counterpartImage && image.counterpartImage.id) {
                        update.value.borderImage = image.counterpartImage?.id
                    }
                }

                updates.push(update);
            }

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.ORIENTATION,
                actionCategory: WDActionLogCategory.orientation,
                actionDescription: "Change orientation to " + (orientation ? "portrait" : "landscape")
            })

            if (generateThumbnailOnSave) {
                this.setState({generateThumbnailOnSave: true}, () => {
                    this.closeContextMenu()
                    this.autoSaveWorksheet()
                })
            }
            else {
                this.closeContextMenu()
                this.autoSaveWorksheet()
            }
        }
    }
    onChangePageBorder = (pageActionSubject: FrameSubject, borderPosition: PageBorderPosition, borderStyle?: BorderStyle,
                          borderRadius?: number, borderTransparency?: number, borderImage?: number, pageIdentifier?: string) => {
        if (this.state.worksheet && this.state.worksheet.pages) {
            let pages: WorksheetPage[] = []

            switch (pageActionSubject) {
                case FrameSubject.ALL_PAGES:
                    pages = this.state.worksheet.pages
                    break

                case FrameSubject.CURRENT_PAGE:
                    if (pageIdentifier) {
                        pages = this.state.worksheet.pages.filter(p => WorksheetPage.getUniqueElementIdentifier(p) === pageIdentifier)
                    } else {
                        pages = this.state.currentPageIndex < this.state.worksheet.pages.length ? [this.state.worksheet.pages[this.state.currentPageIndex]] : []
                    }
                    break

                case FrameSubject.FIRST_PAGE:
                    pages = [this.state.worksheet.pages[0]]
                    break
            }

            this.updatePageBorder(pages, borderPosition, borderStyle, borderRadius, borderTransparency, borderImage)
        }
    }
    updatePageBorder = async (pages: WorksheetPage[], borderPosition: PageBorderPosition, borderStyle?: BorderStyle, borderRadius?: number, borderTransparency?: number, borderImage?: number) => {
        if (this.state.worksheet && this.state.worksheet.pages && pages.length > 0) {
            let updates = pages.map(p => new WorksheetPageUpdate(WorksheetPage.getUniqueElementIdentifier(p), {
                borderLeft: borderPosition.left,
                borderBottom: borderPosition.bottom,
                borderRight: borderPosition.right,
                borderTop: borderPosition.top,
                borderStyle: borderStyle?.style,
                borderColor: borderStyle?.color,
                borderWeight: borderStyle?.weight,
                borderRadius: borderRadius,
                borderTransparency: borderTransparency,
                borderImage: borderImage
            }))

            let actionDescription = pages.map(p => WorksheetPage.getUniqueElementIdentifier(p)).join(", ")
            let borderOptions = {
                borderPosition: borderPosition,
                borderStyle: borderStyle,
                borderRadius: borderRadius,
                borderTransparency: borderTransparency,
                borderImage: borderImage
            }
            actionDescription += " - " + JSON.stringify(borderOptions)

            await this.updatePages(updates, {
                historyAction: WDHistoryAction.PAGE_BORDER,
                actionCategory: WDActionLogCategory.page_border,
                actionDescription: actionDescription
            })

            this.closeContextMenu()
            this.autoSaveWorksheet()
        }
    }

    refreshPageSorting = (pages: WorksheetPage[] | undefined) => {
        pages?.sort(((a, b) => a.sort - b.sort))
            .forEach((p, i) => p.sort = i + 1)
    }
    duplicatePage = async (page: WorksheetPage, sort: number) => {
        let newPage = WorksheetPage.duplicate(page)
        newPage.sort = sort

        const pageKey = WorksheetPage.getUniqueElementIdentifier(newPage)

        let newElements: WorksheetItem[] = []
        this.state.elements
            .filter(e => !e.deleted && e.worksheetPageKey === WorksheetPage.getUniqueElementIdentifier(page!))
            .forEach(e => {
                let newElement = WorksheetItem.duplicate(e, 0, 0, undefined, pageKey)
                newElements.push(newElement)
            })

        await this.updateElements([], {historyAction: WDHistoryAction.CREATE}, newElements)

        //this.state.refSidebarPageManager.current?.generateImages([pageKey])

        return newPage
    }
    // createDuplicatedElement = async (element: WorksheetItem): Promise<HTMLElement> => {
    //     while (true) {
    //         let pageElement = document.getElementById(element.worksheetPageKey)
    //         if (pageElement) {
    //             return this.addElementToDesigner(element, WDElementOrigin.duplicated, WDElementOrigin.loaded)
    //         }
    //
    //         // Delay few milliseconds
    //         await new Promise(f => setTimeout(f, 50));
    //     }
    // }

    onToggleNonPrintObjectsVisibility = () => {
        this.setState({showNonPrintableObjects: !this.state.showNonPrintableObjects}, () => {
            this.closeContextMenu()

            let elements = this.getAllElementsRecursive()
            elements.forEach(e => e.ref?.current?.toggleNonPrintObjectsVisibility(this.state.showNonPrintableObjects))
        })
    }

    /**
     * Mouse events on content area
     * */
    onMouseDown = async (e: MouseEvent) => {
        const target = (e.target as HTMLElement)

        if (!this.isEditingAllowed()) {
            return
        }

        this.mouseButtonPressed = 0
        if (e.buttons === 4) {
            e.preventDefault();

            this.mouseButtonPressed = 4
            this.mouseButtonCoords = new Coords(e.clientX, e.clientY)

            document.addEventListener("mousemove", this.onMouseMove)
            document.addEventListener("mouseup", this.onMouseUp)
        } else if (e.buttons === 1) {
            this.context.log.debug("Designer - Primary MouseDown")
            this.mouseButtonPressed = 1

            // Ignore click on specific elements
            if (Util.getParentByClass(target, "ws-designer-textbox-resize-handler") ||
                Util.getParentByClass(target, "ws-designer-textbox-resize-handler-error") ||
                Util.getParentByClass(target, "ws-designer-toolbar-button-locked") ||
                Util.getParentByClass(target, "ws-designer-textbox-syllable-manually-container")) {

                this.context.log.debug("Mouse down on exception")
                this.context.log.flush(LogLevel.INFO)
                return
            }

            this.mouseButtonCoords = new Coords(e.clientX, e.clientY)

            document.addEventListener("mousemove", this.onMouseMove)
            document.addEventListener("mouseup", this.onMouseUp)

            // Creating element mode
            if (this.state.mode === WDElementMode.ADD_TEXTBOX ||
                this.state.mode === WDElementMode.ADD_BALLOON ||
                this.state.mode === WDElementMode.ADD_TABLE) {

                e.preventDefault();
                e.stopPropagation();

                await this.unselectAllElements();

                // Get worksheet or return if the click was not on the worksheet
                const sheet = WDUtils.getSheetByCoords(new Coords(e.clientX, e.clientY))
                if (sheet === undefined || sheet === null) {
                    return
                }

                let pos = new Coords(e.pageX, e.pageY)
                pos.toMmGrid()
                pos = WDUtils.convertToSheetCoordinates(sheet.id, pos)
                pos = this.applyZoomOnCoordinates(pos)

                let posZ = WDUtils.getMaxPosZOfElements(this.getElementsByPage(sheet.id))

                let element: WorksheetItem | undefined = undefined
                if (this.state.mode === WDElementMode.ADD_TEXTBOX) {
                    element = new WorksheetItem("textbox-" + Date.now(), sheet.id, WorksheetItemTypeEnum.TEXTBOX,
                        pos.x, pos.y, WDTextbox.getDefaultWidth(), WDTextbox.getDefaultHeight(),
                        WDTextbox.getDefaultContent(), true, true, false, false, posZ + 1)
                    element.paddingLeft = Const.ELEMENT_PADDING
                    element.paddingTop = Const.ELEMENT_PADDING
                    element.paddingRight = Const.ELEMENT_PADDING
                    element.paddingBottom = Const.ELEMENT_PADDING
                    element.ref = React.createRef<WDTextbox>()
                } else if (this.state.mode === WDElementMode.ADD_BALLOON) {
                    element = new WorksheetItem("balloon-" + Date.now(), sheet.id, WorksheetItemTypeEnum.BALLOON,
                        pos.x, pos.y, WDBalloon.getDefaultWidth(), WDBalloon.getDefaultHeight(),
                        WDBalloon.getDefaultContent(), true,
                        true, false, false, posZ + 1, 0, false, false, "#FFFFFF")
                    element.ref = React.createRef<WDBalloon>()
                } else if (this.state.mode === WDElementMode.ADD_TABLE) {
                    element = new WorksheetItem("table-" + Date.now(), sheet.id, WorksheetItemTypeEnum.TABLE,
                        pos.x, pos.y, WDTable.getDefaultWidth(), WDTable.getDefaultHeight(),
                        WDTable.getDefaultContent(), true, true, false, false, posZ + 1)
                    element.ref = React.createRef<WDTable>()
                }

                if (element) {
                    element.changed = true
                    await this.updateElements([], {
                        historyAction: WDHistoryAction.CREATE,
                        actionCategory: WDActionLogCategory.create,
                        actionData: {
                            itemKey: element.itemKey,
                            worksheetItemTypeId: element.worksheetItemTypeId,
                            worksheetPageKey: element.worksheetPageKey,
                            posX: element.posX,
                            posY: element.posY
                        }
                    }, [element])
                }

                // Reset the mode after a textbox was created
                this.setElementMode(WDElementMode.NONE)
            } else if (this.state.mode === WDElementMode.NONE && target.className === "ws-designer-element-wrapper") { // !this.isMoveElementException(target)) {
                const element = Util.getParentByClass(target, "ws-designer-element-container")
                if (element) {
                    let itemKey = (element as HTMLElement).id.replace("-container", "")
                    let selectedElement = this.getAllElementsRecursive()
                        .find(e => e.itemKey === itemKey)
                    if (selectedElement) {
                        await this.onElementSelect(itemKey, !e.ctrlKey, e.ctrlKey ? undefined : true)
                    }

                    // move all selected
                    await this.performUpdatesOnSelectedElements((item) => {
                        const workspace = WorksheetItem.getParentWorkspace(item)
                        if (workspace) {
                            let rect = workspace.getBoundingClientRect()

                            return new WorksheetItemAction([new WorksheetItemUpdate(item.itemKey, {
                                selectX: (e.clientX - rect.left) / this.context.getZoom() - item.posX,
                                selectY: (e.clientY - rect.top) / this.context.getZoom() - item.posY
                            })], [])
                        }

                        return new WorksheetItemAction([], [])

                    }, {applyRecursive: false})

                    this.setState({mode: WDElementMode.MOVE})

                    this.setToolbarVisibility(false, false)
                } else {
                    await this.unselectAllElements();
                }

            } else if (this.state.mode !== WDElementMode.EDIT) {
                await this.unselectAllElements()
                this.preventToolbar = true

                this.mouseButtonWorkspace = WDUtils.getWorksheetByCoords(this.mouseButtonCoords!)
                if (this.mouseButtonWorkspace) {
                    this.mouseButtonWorkspace.className += " ws-designer-workspace-selecting"
                }
            }

            this.context.log.flush(LogLevel.INFO)
        }
    }
    onMouseMove = async (e: MouseEvent) => {
        if (this.mouseButtonPressed === 4 && this.mouseButtonCoords) {
            this.mouseButtonCoords = WDUtils.scrollToPosition(e, this.mouseButtonCoords, "ws-designer-document")
        }
        if (this.state.mode === WDElementMode.MOVE && this.mouseButtonPressed === 1) {
            let coords = new Coords(e.clientX, e.clientY)

            const elements = this.getChildElementsRecursiveByElements(this.state.elements)
                .filter(item => item.selected && !item.resized && !item.locked)
            if (elements.length === 0) {
                return
            }

            const isGroupElement = elements.filter(e => e.groupId !== undefined || e.groupKey !== undefined).length > 0

            // Automatic scrolling when the mouse is near an edge of the visible document
            let scrollDirection = this.getScrollDirection(new Coords(e.clientX, e.clientY))
            if (scrollDirection.bottom || scrollDirection.top || scrollDirection.left || scrollDirection.right) {
                if (this.mouseMoveScrollAreaCoords === undefined) {
                    // Store the mouse position temporarily and scroll the document in a recursive function
                    this.mouseMoveScrollAreaCoords = new Coords(e.clientX, e.clientY)
                    setTimeout(this.autoScrollDocument, 10)
                } else {
                    // Update the stored mouse position
                    this.mouseMoveScrollAreaCoords = new Coords(e.clientX, e.clientY)
                }
            } else {
                this.mouseMoveScrollAreaCoords = undefined
            }

            // Moving elements start with a threshold of 3 pixels
            if (this.mouseButtonCoords &&
                Math.abs(coords.x - this.mouseButtonCoords.x) < this.MOUSE_MOVE_THRESHOLD &&
                Math.abs(coords.y - this.mouseButtonCoords.y) < this.MOUSE_MOVE_THRESHOLD) {

                return
            } else {
                // Create new history entry when elements are moved the first time
                if (this.mouseButtonCoords) {

                    // Current position of selected elements
                    let updates = elements.map(e => new WorksheetItemUpdate(e.itemKey, {
                        posX: e.posX,
                        posY: e.posY,
                        worksheetPageKey: e.worksheetPageKey
                    }))

                    const history = new WDElementHistory(
                        this.restoreElements,
                        new WDElementHistoryItem(updates),
                        new WDElementHistoryItem([])
                    )
                    this.pushHistory(history)
                }

                // Once moved more than the threshold, the check is obsolete and the mouse button coords are reset
                this.mouseButtonCoords = undefined
            }

            this.clearPositioningLines()

            // Calculate the new position for all elements moved
            let elementUpdates: WorksheetItemUpdate[] = []
            elements.forEach((item) => {
                const elementContainer: HTMLElement | null = document.getElementById(item.itemKey + "-container")
                if (elementContainer) {
                    let rect = elementContainer.getBoundingClientRect()
                    let midY = rect.top + (rect.height / 2)

                    let currentPos = new Coords(coords.x, midY)

                    // Get elements from mouse cursor position and get workspace
                    let workspace: HTMLElement | null = WDUtils.getWorksheetByCoords(currentPos)
                    if (workspace === null || workspace === undefined) {
                        workspace = Util.getParentByClass(elementContainer, "ws-designer-sheet-workspace")
                    }

                    if (workspace) {
                        const sheet = WDUtils.getSheetByCoords(currentPos)
                        let pageKey = item.worksheetPageKey
                        if (sheet && item.worksheetPageKey !== sheet.id) {
                            this.context.log.info("Move " + item.itemKey + " from " + item.worksheetPageKey + " to " + sheet.id)
                            pageKey = sheet.id
                        }

                        const rectWorkspace = workspace.getBoundingClientRect()

                        let result = WDUtils.getBoundingRectOfElement(item)
                        result.left = ((e.clientX - rectWorkspace.left) / this.context.getZoom()) - item.selectX
                        result.top = ((e.clientY - rectWorkspace.top) / this.context.getZoom()) - item.selectY

                        elementUpdates.push(new WorksheetItemUpdate(item.itemKey, {
                            posX: result.left,
                            posY: result.top,
                            width: result.width,
                            height: result.height,
                            worksheetPageKey: pageKey,
                            worksheetItemTypeId: item.worksheetItemTypeId
                        }))
                    }
                }
            })

            let updates: WorksheetItemUpdate[] = []
            let xOffset = 0, yOffset = 0

            // Get unique page keys and check positioning lines only if all selected elements are on the same page and
            // the moved item is no group element (no positioning lines for elements inside group
            if (!isGroupElement) {
                let uniquePageKeys = elementUpdates
                    .map(u => u.value.worksheetPageKey!)
                    .filter((v, i, a) => a.indexOf(v) === i)
                if (uniquePageKeys.length === 1) {

                    // Calculate the minimum and maximum coordinates of all selected elements
                    let minX = elementUpdates.map(u => u.value.posX!).reduce((prev, current) => Math.min(prev, current))
                    let minY = elementUpdates.map(u => u.value.posY!).reduce((prev, current) => Math.min(prev, current))
                    let maxX = elementUpdates.map(u => u.value.posX! + u.value.width!).reduce((prev, current) => Math.max(prev, current))
                    let maxY = elementUpdates.map(u => u.value.posY! + u.value.height!).reduce((prev, current) => Math.max(prev, current))

                    elementUpdates
                        .filter(u => u.value.worksheetItemTypeId !== WorksheetItemTypeEnum.LINE)
                        .forEach(u => {

                            let unselectedElements = this.getElementsByPage(u.value.worksheetPageKey!)
                                .filter(element => !element.selected && element.worksheetItemTypeId !== WorksheetItemTypeEnum.LINE)

                            // Check only dimensions which represent the outer bounds of all selected elements
                            let check = new PositioningLineCheck(
                                u.value.posX! === minX,
                                u.value.posY! === minY,
                                u.value.posX! + u.value.width! === maxX,
                                u.value.posY! + u.value.height! === maxY
                            )

                            let result = new ElementLayout(u.value.posX!, u.value.posY!, u.value.width!, u.value.height!)
                            unselectedElements
                                .forEach(element => {
                                    let elementRect = WDUtils.getBoundingRectOfElement(element)

                                    result = WDUtils.EvalPositioningLines(
                                        PositioningMode.MOVE,
                                        elementRect,
                                        result,
                                        this.positioningLines,
                                        check,
                                        this.context.getZoom()
                                    )
                                })

                            // Calculate difference between current position and new position to apply to all selected elements
                            if ((check.left || check.right) && xOffset === 0) {
                                xOffset = result.left - u.value.posX!
                            }
                            if ((check.top || check.bottom) && yOffset === 0) {
                                yOffset = result.top - u.value.posY!
                            }
                        })
                }
            }

            // Convert stored updates to real item updates
            elementUpdates.forEach(u => {
                let item = this.getAllElementsRecursive().find(i => i.itemKey === u.itemKey)
                if (item) {
                    let currentUpdates = this.moveElement(item, new Coords(u.value.posX! + xOffset, u.value.posY! + yOffset))
                    let currentItemUpdate = currentUpdates.find(u => u.itemKey === item!.itemKey)
                    if (currentItemUpdate) {
                        // page change
                        if (item.worksheetPageKey !== u.value.worksheetPageKey) {

                            currentItemUpdate.value.worksheetPageKey = u.value.worksheetPageKey

                            // Propagate page key update to children
                            let childUpdates = this.getGroupUpdates(item, { worksheetPageKey: u.value.worksheetPageKey })
                                .filter(u => u.itemKey !== item?.itemKey)
                            updates = updates.concat(childUpdates)

                            // Update the position Z of the element when page changes
                            let posZ = WDUtils.getMaxPosZOfElements(this.getElementsByPage(currentItemUpdate.value.worksheetPageKey!))
                            currentItemUpdate.value.posZ = posZ + 1
                        }
                    }
                    updates = updates.concat(currentUpdates)
                }

                WDUtils.DrawPositioningLines(u.value.worksheetPageKey!, this.positioningLines)
            })

            await this.updateElements(updates)

            this.updateHistory(new WDElementHistoryItem(updates))
            this.closeContextMenu()

        } else if (this.mouseButtonWorkspace !== null && this.mouseButtonPressed === 1) {
            let selectBox = document.getElementById("select-box")
            if (selectBox && this.mouseButtonCoords) {
                let boxWidth = (e.clientX - this.mouseButtonCoords.x)
                let boxHeight = (e.clientY - this.mouseButtonCoords.y)

                let x = this.mouseButtonCoords.x, y = this.mouseButtonCoords.y
                if (boxWidth < 0) {
                    x -= Math.abs(boxWidth)
                }
                if (boxHeight < 0) {
                    y -= Math.abs(boxHeight)
                }

                selectBox.style.left = x + "px"
                selectBox.style.top = y + "px"
                selectBox.style.width = Math.abs(boxWidth) + "px"
                selectBox.style.height = Math.abs(boxHeight) + "px"

                let selectRect = selectBox.getBoundingClientRect()
                this.state.elements.forEach(item => {
                    let container = WorksheetItem.getElementContainer(item)
                    if (container) {
                        let rect = container.getBoundingClientRect()

                        let selected = !(rect.right < selectRect.left || rect.left > selectRect.right ||
                            rect.bottom < selectRect.top || rect.top > selectRect.bottom)

                        if (selected !== item.selected) {
                            this.onElementSelect(item.itemKey, false, selected)
                        }
                    }
                })
            }
        }
    }
    onMouseUp = async (e: MouseEvent) => {
        if (this.mouseButtonPressed === 1) {
            this.context.log.debug("Designer - Primary MouseUp")
            this.context.log.flush(LogLevel.INFO)

            // Elements were moved and the threshold was reached
            if (this.state.mode === WDElementMode.MOVE) {

                if (this.mouseButtonCoords === undefined) {
                    this.context.log.debug("Stopped Drag")

                    this.writeHistoryItemToActionLog(WDActionLogType.Info, WDActionLogCategory.move, this.state.historyIndex)
                    this.autoSaveWorksheet()
                }

                this.clearPositioningLines()

                this.setState({mode: WDElementMode.NONE})

                this.openToolbar()
                this.setToolbarVisibility(true, true)
            }

            this.context.log.flush(LogLevel.INFO)

            let selectBox = document.getElementById("select-box")
            if (selectBox) {
                selectBox.style.left = "0px"
                selectBox.style.top = "0px"
                selectBox.style.width = "0px"
                selectBox.style.height = "0px"

                if (this.mouseButtonWorkspace) {
                    this.mouseButtonWorkspace.className =
                        this.mouseButtonWorkspace.className.replace(" ws-designer-workspace-selecting", "")
                }
                this.mouseButtonWorkspace = null
                this.preventToolbar = false

                this.openToolbar()
            }

            document.removeEventListener("mousemove", this.onMouseMove)
            document.removeEventListener("mouseup", this.onMouseUp)
        } else if (this.mouseButtonPressed === 4) {
            document.removeEventListener("mousemove", this.onMouseMove)
            document.removeEventListener("mouseup", this.onMouseUp)
        }
        this.mouseButtonPressed = 0
        this.mouseButtonCoords = undefined
        this.mouseMoveScrollAreaCoords = undefined

        this.context.log.flush(LogLevel.INFO)
    }

    getScrollDirection = (coords: Coords) => {
        let scrollDirection = new ScrollDirection()

        let doc = document.getElementById("ws-designer-document")
        if (doc) {
            let rect = doc.getBoundingClientRect()
            let y = coords.y - rect.top
            if (y > rect.height * 0.9) {
                scrollDirection.bottom = true
            } else if (y < rect.height * 0.1) {
                scrollDirection.top = true
            }
        }

        return scrollDirection
    }
    autoScrollDocument = () => {
        if (this.mouseMoveScrollAreaCoords) {
            let doc = document.getElementById("ws-designer-document")
            if (doc) {
                let rect = doc.getBoundingClientRect()

                // Get the mouse position relative to the designer document
                let y = this.mouseMoveScrollAreaCoords.y - rect.top

                // Check if mouse is in the bottom 10% of the workspace
                if (y > rect.height * 0.9) {
                    let ratio = (y - rect.height * 0.9) / (rect.height * 0.1)

                    // Scroll down by ratio and call the function again
                    doc.scrollBy({top: 8 * ratio, left: 0})
                    setTimeout(this.autoScrollDocument, 10)
                } else if (y < rect.height * 0.1) {
                    let ratio = (rect.height * 0.1 - y) / (rect.height * 0.1)

                    // Scroll up by ratio and call the function again
                    doc.scrollBy({top: -8 * ratio, left: 0})
                    setTimeout(this.autoScrollDocument, 10)
                }
            }
        }
    }

    onContextMenuDocument = async (e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        this.context.log.info("Opening context menu")
        this.context.log.flush()

        let reactElement: JSX.Element
        let refContextMenu = React.createRef<WDContextMenu>()

        let groups: WDContextMenuGroup[] = []
        let items: WDContextMenuItem[] = []

        if (this.state.worksheet?.pages === undefined) {
            return
        }

        let id = 1
        let group = 1
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.paste), this.onPasteElement,
            ImagePath.getButtonUrl() + "paste.svg", this.context.translate(translations.text_fragment.strg) + " + V", undefined,
            (this.state.clipboard.length === 0 && !this.isEditingAllowed())))
        groups.push(new WDContextMenuGroup(group++, items))

        let page = WDUtils.getPageElementByCoords(new Coords(e.x, e.y), this.state.worksheet.pages)
        if (page === undefined) {
            return
        }

        items = []
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.text.worksheet_settings.page_orientation), undefined, undefined, undefined,
            new WDContextMenuGroup(1, [
                new WDContextMenuItem(id++, this.context.translate(translations.toolbar.portrait), () => this.onChangePageOrientation([page!], true),
                    ImagePath.getButtonUrl() + "switch_to_portrait_format.svg", undefined, undefined, page.orientation),
                new WDContextMenuItem(id++, this.context.translate(translations.toolbar.landscape), () => this.onChangePageOrientation([page!], false),
                    ImagePath.getButtonUrl() + "switch_to_landscape_format.svg", undefined, undefined, !page.orientation)
            ]), !this.isEditingAllowed()
        ))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.mark_solution_sheet),
            () => this.onChangePageKeySolution(new Coords(e.x, e.y)), ImagePath.getButtonUrl() + "page_solution_mark.svg", undefined,
            undefined, !this.isEditingAllowed()
        ))

        let image = this.state.showNonPrintableObjects ? "not_printable_off.svg" : "not_printable_on.svg"
        let label = this.context.translate(translations.text.worksheet_settings.toggle_non_print_objects) as string
        label = label.replace("%1%", this.context.translate(this.state.showNonPrintableObjects ? translations.text_fragment.hide : translations.text_fragment.show))
        label = label[0].toUpperCase() + label.substring(1)
        items.push(new WDContextMenuItem(id++, label,
            this.onToggleNonPrintObjectsVisibility, ImagePath.getButtonUrl() + image, undefined, undefined))
        groups.push(new WDContextMenuGroup(group++, items))

        let props: any = {
            id: "document",
            left: e.clientX,
            top: e.clientY,
            groups: groups,
            ref: refContextMenu
        }

        reactElement = React.createElement(WDContextMenu, props, null)

        await this.unselectAllElements()
        this.closeToolbar()

        this.setState({
            contextMenu: reactElement,
            refContextMenu: refContextMenu
        })
    }
    onContextMenuElement = async (itemKey: string, e: React.MouseEvent) => {
        // Do nothing when editing of worksheet is not allowed
        if (!this.isEditingAllowed()) {
            return
        }

        this.context.log.info("Opening context menu")
        await this.onElementSelect(itemKey, false, true)

        const selected = this.getSelectedElements(true)
        const isOneGroup = (selected.length === 1 && selected[0].worksheetItemTypeId === WorksheetItemTypeEnum.GROUP)
        const isOneLocked = (selected.filter(i => i.locked).length > 0)

        let reactElement: JSX.Element
        let refContextMenu = React.createRef<WDContextMenu>()

        let id = 1
        let group = 1
        let groups: WDContextMenuGroup[] = []
        let items: WDContextMenuItem[] = []

        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.duplicate), this.onDuplicateElement,
            ImagePath.getButtonUrl() + "duplicate.svg", this.context.translate(translations.text_fragment.strg) + " + D", undefined, isOneLocked))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.cut), this.onCutElement,
            ImagePath.getButtonUrl() + "cut.svg", this.context.translate(translations.text_fragment.strg) + " + X", undefined, isOneLocked))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.copy), this.onCopyElement,
            ImagePath.getButtonUrl() + "copy.svg", this.context.translate(translations.text_fragment.strg) + " + C", undefined, isOneLocked))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.paste), this.onPasteElement,
            ImagePath.getButtonUrl() + "paste.svg", this.context.translate(translations.text_fragment.strg) + " + V", undefined, (this.state.clipboard.length === 0)))
        groups.push(new WDContextMenuGroup(group++, items))

        items = []
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.flip_horizontal), () => this.onChangeElementFlip("flipHorizontal"),
            ImagePath.getButtonUrl() + "flip_horizontally.svg", undefined, undefined, isOneLocked))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.flip_vertical), () => this.onChangeElementFlip("flipVertical"),
            ImagePath.getButtonUrl() + "flip_vertically.svg", undefined, undefined, isOneLocked))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.turn_left), () => this.onChangeElementRotationBy(-90),
            ImagePath.getButtonUrl() + "turn_left.svg", undefined, undefined, isOneLocked))
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.turn_right), () => this.onChangeElementRotationBy(90),
            ImagePath.getButtonUrl() + "turn_right.svg", undefined, undefined, isOneLocked))
        groups.push(new WDContextMenuGroup(group++, items))

        items = []
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.arrange), undefined, undefined, undefined,
            new WDContextMenuGroup(1, [
                new WDContextMenuItem(id++, this.context.translate(translations.toolbar.arrange_top), () => this.onArrangeElement(WDElementArrange.top),
                    ImagePath.getButtonUrl() + "arrange_top.svg"),
                new WDContextMenuItem(id++, this.context.translate(translations.toolbar.arrange_up), () => this.onArrangeElement(WDElementArrange.up),
                    ImagePath.getButtonUrl() + "arrange_up.svg"),
                new WDContextMenuItem(id++, this.context.translate(translations.toolbar.arrange_down), () => this.onArrangeElement(WDElementArrange.down),
                    ImagePath.getButtonUrl() + "arrange_down.svg"),
                new WDContextMenuItem(id++, this.context.translate(translations.toolbar.arrange_bottom), () => this.onArrangeElement(WDElementArrange.bottom),
                    ImagePath.getButtonUrl() + "arrange_bottom.svg")
            ]), isOneLocked
        ))
        groups.push(new WDContextMenuGroup(group++, items))

        items = []
        if (isOneLocked) {
            items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.unlock), () => this.onChangeElementLockingStatus(false),
                ImagePath.getButtonUrl() + "unlock_object.svg"))
        } else {
            items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.lock), () => this.onChangeElementLockingStatus(true),
                ImagePath.getButtonUrl() + "lock_object.svg"))
        }
        if (isOneGroup) {
            items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.ungroup), () => this.onChangeElementGroupingStatus(),
                ImagePath.getButtonUrl() + "group.svg", this.context.translate(translations.text_fragment.strg) + " + " + this.context.translate(translations.text_fragment.shift) + " + G",
                undefined, isOneLocked))
        } else {
            items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.group), () => this.onChangeElementGroupingStatus(),
                ImagePath.getButtonUrl() + "group.svg", this.context.translate(translations.text_fragment.strg) + " + G", undefined, isOneLocked))
        }
        groups.push(new WDContextMenuGroup(group++, items))

        items = []
        items.push(new WDContextMenuItem(id++, this.context.translate(translations.toolbar.delete), () => this.onDeleteElement(),
            ImagePath.getButtonUrl() + "clear.svg", this.context.translate(translations.text_fragment.entf), undefined, isOneLocked))
        groups.push(new WDContextMenuGroup(group++, items))

        let props: any = {
            id: "element",
            left: e.clientX,
            top: e.clientY,
            groups: groups,
            ref: refContextMenu
        }

        reactElement = React.createElement(WDContextMenu, props, null)

        this.closeToolbar()

        this.setState({
            contextMenu: reactElement,
            refContextMenu: refContextMenu
        })
    }
    closeContextMenu = () => {
        this.setState({contextMenu: <></>})
    }

    clearPositioningLines() {
        this.positioningLines = []
        WDUtils.ClearPositioningLines()
    }

    /**
     * Keyboard events
     * */
    onKeyDown = async (e: KeyboardEvent) => {
        if (this.state.worksheet === undefined) {
            return
        }

        if (e.key === 's' && e.ctrlKey) {
            e.preventDefault()

            await this.cancelElementEditing()
            await this.unselectAllElements()
            if (this.state.worksheet && !this.saving) {
                await this.saveWorksheet(this.state.worksheet, true)
            }
        } else if (e.key === 'z' && e.ctrlKey && this.keyReleased) {
            e.preventDefault()

            await this.cancelElementEditing()
            await this.unselectAllElements()

            if (this.state.historyStack.length > 0) {
                this.keyReleased = false
                await this.onUndo()
            }
        } else if (e.key === 'y' && e.ctrlKey) {
            e.preventDefault()

            await this.cancelElementEditing()
            await this.unselectAllElements()

            if (this.state.historyStack.length > 0) {
                this.keyReleased = false
                await this.onRedo()
            }
        } else if (e.key === 'G' && e.ctrlKey && e.shiftKey) {
            e.preventDefault()

            let selected = this.getSelectedElements(false)
            if (selected.length > 0) {
                selected
                    .filter(item => item.worksheetItemTypeId === WorksheetItemTypeEnum.GROUP)
                    .forEach(item => this.ungroupElements(item))
            }
            this.autoSaveWorksheet()

        } else if (e.key === 'g' && e.ctrlKey) {
            e.preventDefault()

            let selected = this.getSelectedElements(false)
            if (selected.length > 1) {
                await this.groupElements(selected)
            }
            this.autoSaveWorksheet()

        } else if (!this.isEditMode()) {
            if (e.key === 'Delete' || e.key === 'Backspace') {
                await this.onDeleteElement()
                this.autoSaveWorksheet()

            } else if (e.key === 'Escape' && this.keyReleased) {
                e.preventDefault()

                this.keyReleased = false
                await this.unselectAllElements()
            } else if (e.key === 'x' && e.ctrlKey) {
                e.preventDefault()
                this.onCutElement()
            } else if (e.key === 'c' && e.ctrlKey) {
                e.preventDefault()
                this.onCopyElement()
            } else if (e.key === 'd' && e.ctrlKey) {
                e.preventDefault()
                await this.onDuplicateElement()
            } else if (e.key === 'v' && e.ctrlKey) {
                e.preventDefault()
                await this.onPasteElement()
            } else if (e.key === 'ArrowRight') {
                e.preventDefault()      // Prevent scrollbar event in Chromium
                this.onChangeElementPositionOffset(Converter.mmToPx(1), 0)
            } else if (e.key === 'ArrowLeft') {
                e.preventDefault()      // Prevent scrollbar event in Chromium
                this.onChangeElementPositionOffset(Converter.mmToPx(-1), 0)
            } else if (e.key === 'ArrowUp') {
                e.preventDefault()      // Prevent scrollbar event in Chromium
                this.onChangeElementPositionOffset(0, Converter.mmToPx(-1))
            } else if (e.key === 'ArrowDown') {
                e.preventDefault()      // Prevent scrollbar event in Chromium
                this.onChangeElementPositionOffset(0, Converter.mmToPx(1))
            }
        } else {
            if (e.key === 'Escape') {
                await this.cancelElementEditing()
            }
        }
    }
    onKeyUp = () => {
        this.keyReleased = true
    }

    /**
     * Drag & Drop events
     * */
    onDropElement = async (page: WorksheetPage, pageX: number, pageY: number, dataTransfer: DataTransfer) => {
        if (dataTransfer) {
            // Bind element to innerDesigner
            const elementId = WorksheetItem.getNewItemKey()
            const worksheetItemTypeId = +dataTransfer.getData(Const.DATA_TRANSFER_WS_ITEM_ID)
            const configData = dataTransfer.getData(Const.DATA_TRANSFER_CONFIG_DATA)
            const pageKey = WorksheetPage.getUniqueElementIdentifier(page)

            await this.unselectAllElements()

            if (+worksheetItemTypeId === WorksheetItemTypeEnum.FRAME) {
                const imageColor = dataTransfer.getData(Const.DATA_TRANSFER_IMAGE_COLOR)
                this.onDropSetBackground(page, configData, imageColor)
                return
            }

            // Initial width and height
            let width = 0, height = 0, save = true
            let sourceRecordId: number | undefined = undefined

            let fillColor: string | undefined = undefined
            let borderVisible: boolean | undefined = undefined
            let borderStyle: string | undefined = undefined
            let borderColor: string | undefined = undefined
            let borderWeight: number | undefined = undefined

            let pos = new Coords(pageX, pageY)
            pos.toMmGrid()
            pos = WDUtils.convertToSheetCoordinates(pageKey, pos)
            pos = this.applyZoomOnCoordinates(pos)

            if (+worksheetItemTypeId === WorksheetItemTypeEnum.WORKSHEET_ITEMS) {
                await this.onDropWorksheetItemsPattern(pageKey, configData)
                return
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.RULE) {
                await this.onDropWorksheetItemsPattern(pageKey, configData, pos)
                return
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TRIANGLE) {
                width = WDCalculationTriangle.getDefaultWidth()
                height = WDCalculationTriangle.getDefaultHeight()
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.WRITING_LINEATURE) {
                width = WDWritingLineature.getDefaultWidth()
                height = WDWritingLineature.getDefaultHeight(undefined)
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE) {
                let data = JSON.parse(configData) as WDImageData
                sourceRecordId = data.id
                width = data.width
                height = data.height
                save = (data.status === Status.published)
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.MATH_LINEATURE) {
                width = WDMathLineature.getDefaultWidth(undefined)
                height = WDMathLineature.getDefaultHeight(undefined)
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TOWER) {
                width = WDCalculationTower.getDefaultWidth()
                height = WDCalculationTower.getDefaultHeight()
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.TABLE) {
                let data = JSON.parse(configData) as WDTableData
                if (data && data.rows && data.rows.length > 0 && data.cols && data.cols.length > 0) {
                    width = data.cols.map(c => c.width).reduce((prev, current) => prev + current)
                    height = data.rows.map(r => r.height).reduce((prev, current) => prev + current)

                    width += 2 * Const.ELEMENT_PADDING
                    height += 2 * Const.ELEMENT_PADDING
                } else {
                    width = WDTable.getDefaultWidth()
                    height = WDTable.getDefaultHeight()
                }
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.TEXTBOX) {
                width = WDTextbox.getDefaultWidth()
                height = WDTextbox.getDefaultHeight()
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.TEXT_EXERCISE) {
                width = WDTextExercise.getDefaultWidth()
                height = WDTextExercise.getDefaultHeight()
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.WRITING_COURSE) {
                width = WDWritingCourse.getDefaultWidth()
                height = WDWritingCourse.getDefaultHeight()
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE2D) {
                width = WDShape2d.getDefaultWidth(configData)
                height = WDShape2d.getDefaultHeight()
                fillColor = "#DADFF2"
                borderVisible = true
                borderStyle = "solid"
                borderColor = "#DADFF2"
                borderWeight = 1
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE3D) {
                width = WDShape3d.getDefaultWidth(configData)
                height = WDShape3d.getDefaultHeight()
                fillColor = "#DADFF2"
                borderVisible = true
                borderStyle = "solid"
                borderColor = "6D80BF"
                borderWeight = 1
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK) {
                width = WDShapeBuildingBrick.getDefaultWidth()
                height = WDShapeBuildingBrick.getDefaultHeight()
                fillColor = "#f2bd20"
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN) {
                width = WDShapeCraftPattern.getDefaultWidth()
                height = WDShapeCraftPattern.getDefaultHeight()
                fillColor = "#6D80BF"
                borderVisible = true
                borderStyle = "solid"
                borderColor = "DADFF2"
                borderWeight = 1
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.LINE) {
                width = WDLine.getDefaultWidth()
                height = WDLine.getDefaultHeight()
            } else if (+worksheetItemTypeId === WorksheetItemTypeEnum.BALLOON) {
                width = WDBalloon.getDefaultWidth()
                height = WDBalloon.getDefaultHeight()
                fillColor = "#FFFFFF"
                borderVisible = true
                borderStyle = "solid"
                borderColor = "000000"
            }

            let posZ = WDUtils.getMaxPosZOfElements(this.getElementsByPage(pageKey))

            let element = new WorksheetItem(elementId, pageKey, +worksheetItemTypeId, pos.x, pos.y,
                width, height, configData, true, false, false, false,
                posZ + 1, undefined, undefined, undefined, fillColor, undefined,
                undefined, borderVisible, borderStyle, borderColor, borderWeight,
                undefined, undefined, undefined, undefined, undefined, undefined,
                undefined, undefined, undefined, undefined, undefined, sourceRecordId)
            element.save = save
            element.changed = true

            this.context.log.debug("Created worksheet item " + elementId + " on page " + page.id)
            this.context.log.flush()

            await this.updateElements([], {historyAction: WDHistoryAction.CREATE}, [element])

            this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.create,
                [new WDActionLogEntryDetails(element.itemKey, element.worksheetItemTypeId, undefined, element)])

            this.autoSaveWorksheet()
            this.openToolbar()
        }
    }
    onDropWorksheetItemsPattern = async (pageKey: string, configData: string, dropCoords?: Coords) => {
        let data = JSON.parse(configData)

        if (data.worksheetId) {
            let items = await GetWorksheetItemsOfFirstPage(data.worksheetId)
            let posZ = WDUtils.getMaxPosZOfElements(this.getElementsByPage(pageKey))

            let mapElementIds: {[key: number]: string} = []

            let elements = items.map(item => {
                let element = WorksheetItem.duplicate(item, undefined, undefined, item.groupId?.id?.toString())
                element.posZ = posZ + (element.posZ !== undefined ? element.posZ : 1)

                if (dropCoords && element.groupKey === undefined) {
                    element.posX += dropCoords.x
                    element.posY += dropCoords.y
                }

                element.worksheetPageKey = pageKey
                element.save = true

                // write old and new id into mapping array
                mapElementIds[item.id!] = element.itemKey

                return element
            })

            // Update new groupKeys to children
            elements
                .filter(item => item.groupKey !== undefined)
                .forEach(item => {
                    item.groupKey = mapElementIds[item.groupKey!]
                })

            await this.updateElements([], {historyAction: WDHistoryAction.CREATE}, elements)
        }
    }
    onDropSetBackground = (page: WorksheetPage, configData: string, imageColor: string) => {
        let data = JSON.parse(configData)
        let borderStyle = BorderStyle.getNonBorderStyle()
        borderStyle.color = imageColor

        this.updatePageBorder([page], PageBorderPosition.getNoPageBorder(), borderStyle, undefined, undefined, data.imageId)
    }

    /**
     * Element toolbar
     * */
    openToolbar = () => {
        if (this.preventToolbar) {
            return
        }

        let worksheetItemTypeId: number | undefined = undefined
        let coordsX: Coords | undefined = undefined
        let posY: number | undefined = undefined
        let elementProps = new ElementProps(
            ElementLayout.defaultLayout(),
            ElementTransformation.defaultTransformation(),
            ElementBorder.defaultBorder(),
            ElementFillStyle.defaultColor(),
            0, 0, 0, 0,
            false)
        let data: string[] = []
        let keys: string[] = []
        let additionalToolbarData: string[] = []

        let elementsOnSameSheet = true
        let worksheetPageKey: string | undefined = undefined

        let deleteHandler: (() => void) | undefined = this.onDeleteElement
        const selectedItems = this.getSelectedElements(true)

        /*// check if data should be displayed in toolbar - only show value if equal
        let showWidth = ElementTransformation.multiselectSearchForValue(selectedItems, "width")
        let showHeight = ElementTransformation.multiselectSearchForValue(selectedItems, "height")*/

        for (let i = 0; i < selectedItems.length; i++) {
            let selected = selectedItems[i];

            const container = document.getElementById(selected.itemKey + "-container")
            if (container) {
                const clientRect = container.getBoundingClientRect()
                coordsX = new Coords(
                    coordsX ? Math.min(coordsX.x, clientRect.left) : clientRect.left,
                    coordsX ? Math.max(coordsX.y, clientRect.right) : clientRect.right
                )

                posY = posY ? Math.min(posY, clientRect.top) : clientRect.top

                let minLeft: number | undefined = undefined
                let minTop: number | undefined = undefined
                let maxLeft: number | undefined = undefined
                let maxTop: number | undefined = undefined
                let maxWidth = Const.MaxElementSize
                let maxHeight = Const.MaxElementSize

                // Save worksheet item type
                if (worksheetItemTypeId === undefined) {
                    worksheetItemTypeId = selected.worksheetItemTypeId

                    // For text exercises (= compound component) use the worksheet item type of the selected component
                    if (worksheetItemTypeId === WorksheetItemTypeEnum.TEXT_EXERCISE) {

                        let element = selected.ref?.current?.getFocusElement()
                        if (element === undefined) {
                            worksheetItemTypeId = this.state.worksheet?.context === WSContextType.standard ? WorksheetItemTypeEnum.TEXT_EXERCISE : -1
                        } else {
                            worksheetItemTypeId = element.worksheetItemTypeId
                            elementProps = element.props

                            data.push(element.content)
                            keys.push(element.itemKey)

                            break
                        }
                    } else if (worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE) {
                        let parent = Util.getParentByClass(container, "ws-designer-text-exercise-section")
                        if (parent) {
                            let parentRect = parent.getBoundingClientRect()
                            minLeft = 0
                            minTop = 0
                            maxLeft = parentRect.width - clientRect.width
                            maxTop = parentRect.height - clientRect.height
                            maxWidth = parentRect.width
                            maxHeight = parentRect.height
                            if (this.state.worksheet?.context === WSContextType.standard) {
                                deleteHandler = undefined
                            }
                        }
                    }
                } else if (worksheetItemTypeId !== selected.worksheetItemTypeId) {
                    worksheetItemTypeId = -1
                }

                if (worksheetPageKey === undefined) {
                    worksheetPageKey = selected.worksheetPageKey
                } else if (worksheetPageKey !== selected.worksheetPageKey) {
                    elementsOnSameSheet = false
                }

                elementProps = new ElementProps(
                    new ElementLayout(container.offsetLeft, container.offsetTop, selected.width, selected.height),
                    new ElementTransformation(selected.rotation, selected.flipHorizontal, selected.flipVertical, selected.skew),
                    WorksheetItem.getElementBorder(selected),
                    new ElementFillStyle(selected.fillColor, selected.transparency),
                    selected.ref?.current?.getMinWidth(),
                    selected.ref?.current?.getMinHeight(),
                    maxWidth,
                    maxHeight,
                    selected.locked)
                elementProps.minLeft = minLeft
                elementProps.minTop = minTop
                elementProps.maxLeft = maxLeft
                elementProps.maxTop = maxTop

                if (selected.ref && selected.ref.current) {
                    additionalToolbarData.push(selected.ref?.current?.getAdditionalToolbarData())
                }

                data.push(selected.content)
                keys.push(selected.itemKey)
            }
        }

        if (worksheetItemTypeId !== undefined) {
            let reactElement: JSX.Element,
                refToolbar = WorksheetItemType.getToolbarReactRefByWorksheetItemType(worksheetItemTypeId)

            let props: any = {
                left: coordsX ? ((coordsX.x + coordsX.y) / 2) : 0,
                top: posY ? posY : 0,
                elementProps: elementProps,
                selectedElementCount: selectedItems.length,
                worksheetItemTypeId: worksheetItemTypeId,
                worksheetItemData: data,
                worksheetItemKeys: keys,
                elementsOnSameSheet: elementsOnSameSheet,
                additionalToolbarData: worksheetItemTypeId !== -1 ? additionalToolbarData : "{}",
                context: this.state.worksheet?.context || WSContextType.standard,
                editMode: this.state.mode === WDElementMode.EDIT,
                onToolbarAction: this.onToolbarAction,
                onUpdateSelectedElements: this.onUpdateSelectedElements,
                onChangeBorder: this.onChangeElementContainerBorder,
                onFlipHorizontal: () => this.onChangeElementFlip("flipHorizontal"),
                onFlipVertical: () => this.onChangeElementFlip("flipVertical"),
                onChangeLockingStatus: this.onChangeElementLockingStatus,
                onDuplicate: this.onDuplicateElement,
                onCut: this.onCutElement,
                onCopy: this.onCopyElement,
                onPaste: this.onPasteElement,
                onElementDeleted: deleteHandler,
                onChangeGroupingStatus: this.onChangeElementGroupingStatus,
                changeEditModeWithClickTab: this.onChangeEditModeOnClickToolbarTab,
                onArrange: this.onArrangeElement,
                onAlign: this.onAlignElement,
                onDistribute: this.onDistributeElement,
                ref: refToolbar
            }

            switch (worksheetItemTypeId) {
                case WorksheetItemTypeEnum.CALCULATION_TRIANGLE:
                    props.editMode = false
                    props.onAddRule = this.onAddRule

                    reactElement = React.createElement(WDCalculationTriangleToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.TEXTBOX:
                    props.onShowConfirmation = this.onShowConfirmationDialog
                    reactElement = React.createElement(WDTextboxToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.WRITING_LINEATURE:
                    reactElement = React.createElement(WDWritingLineatureToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.BALLOON:
                    props.onChangeBorder = this.onChangeElementBorder
                    props.onShowConfirmation = this.onShowConfirmationDialog
                    reactElement = React.createElement(WDBalloonToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.IMAGE:
                    props.editMode = false
                    props.onAddRule = this.onAddRule
                    reactElement = React.createElement(WDImageToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.MATH_LINEATURE:
                    reactElement = React.createElement(WDMathLineatureToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.CALCULATION_TOWER:
                    props.onAddRule = this.onAddRule
                    reactElement = React.createElement(WDCalculationTowerToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.GROUP:
                    reactElement = React.createElement(WDGroupToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.TABLE:
                    props.onShowConfirmation = this.onShowConfirmationDialog
                    reactElement = React.createElement(WDTableToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.TEXT_EXERCISE:
                    props.onShowConfirmation = this.onShowConfirmationDialog
                    reactElement = React.createElement(WDTextExerciseToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.WRITING_COURSE:
                    props.onShowConfirmation = this.onShowConfirmationDialog
                    props.onUngroup = this.removeAllWrapperElements
                    props.getElementExerciseWorksheet = selectedItems[0].ref?.current?.getElementExerciseWorksheet
                    props.onAddRule = this.onAddRule
                    reactElement = React.createElement(WDWritingCourseToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.LINE:
                    reactElement = React.createElement(WDLineToolbar, props, null)
                    break

                case WorksheetItemTypeEnum.SHAPE2D:
                    props.onChangeBorder = this.onChangeElementBorder
                    reactElement = React.createElement(WDShapeToolbar2D, props, null)
                    break

                case WorksheetItemTypeEnum.SHAPE3D:
                    props.onChangeBorder = this.onChangeElementBorder
                    reactElement = React.createElement(WDShapeToolbar3D, props, null)
                    break

                case WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK:
                    reactElement = React.createElement(WDShapeToolbarBuildingBrick, props, null)
                    break

                case WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN:
                    reactElement = React.createElement(WDShapeToolbarCraftPattern, props, null)
                    break

                default:
                    props.editMode = false
                    reactElement = React.createElement(WDToolbarMixed, props, null)
                    break
            }

            this.setState({
                isToolbarOpen: true,
                elementToolbar: reactElement,
                contextMenu: <></>,
                refToolbar: refToolbar
            })
        }

        this.context.log.flush()
    }
    closeToolbar = () => {
        this.setState({
            isToolbarOpen: false,
            elementToolbar: <></>
        })
    }
    setToolbarVisibility = (visible: boolean, resetPosition: boolean) => {
        this.state.refToolbar.current?.setVisibility(visible, resetPosition)
    }

    onToolbarAction = async (action: WDToolbarAction, data?: any) => {

        // Delete is implemented separately - unselect all elements
        if (action === WDToolbarAction.DELETE) {
            await this.unselectAllElements()
            return
        }

        let historyActionData = data !== undefined ? _.clone(data) : {}
        historyActionData["action"] = action

        // action is propagated to all selected items - specific implementation of action is found in items
        await this.performUpdatesOnSelectedElements(
            (item: WorksheetItem) => {
                if (item.ref && item.ref.current) {
                    return new WorksheetItemAction([item.ref.current.doAction(action, data)], [])
                }
                return new WorksheetItemAction([], [])
            }, {
                applyRecursive: action === WDToolbarAction.CHANGE_SOLUTION_SHOW,
                historyAction: WDHistoryAction.TOOLBAR_ACTION,
                actionCategory: WDActionLogCategory.toolbar_action,
                actionData: { actionData: historyActionData }
            })

        this.openToolbar()
        this.autoSaveWorksheet()
    }
    onPresentationAction = async (e: MouseEvent, action: WDPresentationAction, data?: any) => {
        if (this.state.inPresentationMode) {
            let worksheetItem = WDUtils.getWorksheetItemByCoords(new Coords(e.clientX, e.clientY))

            if (worksheetItem && worksheetItem.id) {
                let elements = this.state.elements.filter(element => element.itemKey === worksheetItem.id)

                if (elements.length > 0) {
                    await this.performUpdatesOnElements((item: WorksheetItem) => {
                        if (item.ref && item.ref.current) {
                            let updates = [item.ref.current.doPresentationAction(action, data)]

                            return new WorksheetItemAction(updates, [])
                        }
                        return new WorksheetItemAction([], [])
                    }, elements, {actionCategory: WDActionLogCategory.presentation_action})
                }
            }
        }
    }
    onChangeEditModeOnClickToolbarTab = (key: string, editMode: boolean) => {
        this.getChildElementsRecursiveByElements(this.state.elements)
            .filter(item => item.itemKey === key)
            .forEach(async (item) => {
                if (item.ref && item.ref.current) {

                    await item.ref.current?.onEditElement(editMode)

                    if (editMode) {
                        // call onEdit method of container
                        item.ref.current?.state?.elementRef.current?.onEdit()
                    } else {
                        item.ref.current?.state?.elementRef.current?.onStopEdit()
                    }
                }
            })
    }

    /**
     * Cache Handling
     */
    preloadCacheObjects = (worksheetItems: WorksheetItem[]): Promise<void>[] => {
        return worksheetItems
            .filter(e => e.worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE)
            .map(e => (JSON.parse(e.content) as WDImageData).id)
            .filter((id, i, self) => self.indexOf(id) === i)
            .map(imageId => {
                return new Promise((resolve) => {
                    let cacheObject = this.context.getCacheObject(imageId, WorksheetItemTypeEnum.IMAGE)
                    if (cacheObject === undefined) {
                        GetImage(imageId).then(image => {
                            if (image.url) {
                                this.context.log.debug("Preload image " + imageId + " into cache")
                                this.context.log.flush()

                                let imageData = JSON.stringify(new WDImageCacheData(image.url, image.colorable))
                                this.context.setCacheObject(new ImageCacheObject(imageId, imageData))

                                resolve()
                            }
                        })
                    } else {
                        resolve()
                    }
                })
            })
    }

    /**
     * Save & Load operations
     * */
    onCreateWorksheet = async (worksheetSettings: WorksheetSettings) => {
        let worksheet = WorksheetSettings.mapSettingsToWorksheet(worksheetSettings, WSContextType.standard)
        if (worksheet) {
            try {
                // Create worksheet
                let ws = await CreateWorksheet(worksheet)

                // Create first worksheet page
                let p = new WorksheetPage(WorksheetPage.getNewPageKey(), false,
                    worksheetSettings.pageBorder, worksheetSettings.trimBorder, 1, worksheet.orientation)

                let page = await CreateWorksheetPage(p, ws.id)
                ws.pages = []
                ws.pages.push(page)

                // Push to state and generate thumbnail
                this.setState({worksheet: ws}, () => this.saveThumbnail(this.state.worksheet!.id))
            } catch (error) {
                this.context.handleError(
                    error,
                    this.context.translate(translations.notification.worksheet_not_created),
                    undefined,
                    this.context.translate(translations.notification.title_error)
                )
            }
        }
    }
    onSaveWorksheet = async(worksheet: Worksheet, close: boolean, showNotification: boolean, callback?: () => void) => {
        if (this.saving) {
            return
        }

        // save worksheet pages
        if (worksheet && worksheet.pages) {

            await this.unselectAllElements()

            this.saveWorksheet(worksheet, showNotification).then(() => {
                if (close) {
                    if (this.showRatingDialog()) {
                        this.unselectAllElements()
                        this.setState({showRateWorksheetMarketplace: true})
                    } else {
                        this.redirectBack()
                    }
                }

                callback?.()
            })
        }
    }
    onCancel = () => {
        if (this.showRatingDialog()) {
            this.unselectAllElements()
            this.setState({showRateWorksheetMarketplace: true})
        } else {
            this.redirectBack()
        }
    }
    redirectBack = () => {
        this.props.history.goBack()
    }

    itemHasUnsavedChanges = (item: WorksheetItem): boolean => {
        if (item.changed) {
            return true
        }

        return item.children.find(child => this.itemHasUnsavedChanges(child)) !== undefined
    }
    saveThumbnail = async (worksheetId: number) => {
        return new Promise<void>(async (resolve, reject) => {
            let tempDiv = await WDUtils.generateThumbnailElement(document.querySelector(".ws-designer-thumbnail")!)
            if (tempDiv === null) {
                resolve()
                return
            }

            try {
                let imageUrl = await domToImage.toPng(tempDiv)
                if (imageUrl) {
                    SaveWorksheetThumbnail(worksheetId, imageUrl.split(';base64,')[1]).then(
                        () => {
                            this.setState({generateThumbnailOnSave: false}, resolve)
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.worksheet_thumbnail_not_saved))
                            reject(error)
                        }
                    )
                }
            } catch (e) {
                console.error(e)
            } finally {
                // Remove temporary thumbnail div from DOM
                tempDiv.remove()
            }
        })
    }
    autoSaveWorksheet = () => {
        // Currently saving?
        if (this.saving || !this.isEditingAllowed()) {
            return
        }

        if (this.state.lastSaved === undefined || (new Date().getTime() - this.state.lastSaved.getTime()) > this.AUTO_SAVE_INTERVAL) {
            this.context.getUserSettings().then(settings => {
                if (this.state.worksheet && settings.autoSave && !this.saving) {
                    this.saveWorksheet(this.state.worksheet, false)
                }
            })
        } else {
            this.context.log.debug("Auto save skipped")
            this.context.log.flush(LogLevel.INFO)
        }
    }
    saveWorksheet = async (worksheet: Worksheet, showNotification: boolean) => {
        if (this.saving) {
            return
        }

        let notification: Notification | undefined = undefined
        this.saving = true

        this.context.log.info("Save worksheet ...")

        try {
            if (worksheet && worksheet.pages) {
                if (showNotification) {
                    this.context.log.debug("Show saving dialog ...")
                    notification = Notification.handleLoading(
                        this.context.translate(translations.notification.saving)
                    )
                    this.context.setNotification(notification)
                }

                this.context.log.debug("Save worksheet ...")
                await UpdateWorksheet(worksheet)

                // Update pages and save items
                this.context.log.debug("Create and update worksheet pages ...")
                let pages = worksheet.pages.sort((a, b) => a.sort - b.sort)
                for (let i = 0; i < pages.length; i++) {

                    // When element on first page was changed or any page action happened generate thumbnail
                    if (i === 0) {
                        let generateThumbnail = this.state.generateThumbnailOnSave || pages[i].changed
                        if (!generateThumbnail) {
                            let elements = this.state.elements
                                .filter(e => e.changed && e.worksheetPageKey === WorksheetPage.getUniqueElementIdentifier(pages[0]))

                            generateThumbnail = elements.length > 0
                        }

                        // Generate thumbnail of the first page and save it
                        if (generateThumbnail) {
                            this.context.log.debug("Generate thumbnail ...")
                            await this.saveThumbnail(worksheet.id)
                        }
                    }

                    this.context.log.info("Save worksheet page " + i)
                    this.context.log.flush()

                    await this.saveWorksheetPage(pages[i]);
                }

                if (showNotification) {
                    this.context.log.debug("Show success notification")
                    this.context.setNotification(Notification.handleSuccess(
                        this.context.translate(translations.notification.saved)), notification?.id)
                }

                this.setState({worksheet: this.state.worksheet, lastSaved: new Date()})
                // this.setState({lastSaved: new Date()})

                this.context.addWDAction(WDActionLogType.Info, WDActionLogCategory.save, [])

                this.context.log.info("Save worksheet OK")
                this.context.log.flush()
            }
        } catch (e) {
            if (e !== undefined) {
                this.context.handleError(
                    e, this.context.translate(translations.notification.saving_error), notification?.id,
                    this.context.translate(translations.notification.title_error_save),
                    WDActionLogCategory.save)
            }

            this.context.log.flush(LogLevel.DEBUG)
        } finally {
            this.saving = false
        }
    }
    saveWorksheetSettings = async (worksheet: Worksheet) => {
        UpdateWorksheet(worksheet).then(
            () => {
                this.setState({worksheet: worksheet, showSettingsDialog: false})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.worksheet_not_saved))
            }
        )
    }
    saveWorksheetPage = async (page: WorksheetPage) => {
        if (this.state.worksheet) {
            let pageKey = WorksheetPage.getUniqueElementIdentifier(page)

            // Delete page marked for deletion
            if (page.deleted) {
                if (page.id) {
                    this.context.log.debug("Delete Worksheet Page " + page.id)
                    await DeleteWorksheetPage(page.id, this.state.worksheet.id)

                    // Reset page id so undo does not restore the original item, and it will be recreated on save
                    page.key = page.id.toString()
                    page.id = undefined
                }

                // Mark items as unchanged so the "unsaved changes" dialog is not shown
                let elements = this.state.elements.filter(e => e.worksheetPageKey === pageKey && e.changed)
                elements.forEach(item => { item.changed = false })
            } else {

                // Create or update worksheet page
                if (page.id === undefined) {
                    this.context.log.debug("Worksheet Page " + page.sort + ": Create")

                    let createdPage = await CreateWorksheetPage(page, this.state.worksheet.id)
                    page.id = createdPage.id
                    let newPageKey = WorksheetPage.getUniqueElementIdentifier(page)

                    this.state.elements
                        .filter(e => e.worksheetPageKey === pageKey)
                        .forEach(e => e.worksheetPageKey = newPageKey)

                    pageKey = newPageKey
                } else if (page.changed) {
                    this.context.log.debug("Worksheet Page " + page.sort + ": Update " + page.id)
                    await UpdateWorksheetPage(page, this.state.worksheet.id)
                } else {
                    this.context.log.debug("Worksheet Page not changed")
                }

                // Save worksheet items unless the worksheet page was deleted
                this.context.log.debug("Save worksheet items of page " + pageKey + " with id = " + page.id)

                // Filter by worksheet page
                let elements = this.state.elements.filter(e => e.worksheetPageKey === pageKey)
                let updates: WorksheetItemUpdate[] = []
                await this.saveWorksheetItems(page.id!, elements, updates);

                await this.updateElements(updates)
            }
            page.changed = false

            this.context.log.flush()
        }
    }
    saveWorksheetItems = async (pageId: number, elements: WorksheetItem[], updates: WorksheetItemUpdate[]) => {

        // Mark items which should not be saved (unpublished images) as unchanged so the "unsaved changes" dialog is not shown
        elements
            .filter(item => !item.save)
            .forEach(item => {item.changed = false})

        // Remove elements which should not be changed from elements array
        elements = elements.filter(item => item.save)

        // Remove border for all elements which are not showing border
        elements
            .filter(item => !item.borderVisible && item.worksheetItemTypeId !== WorksheetItemTypeEnum.LINE)
            .forEach(item => {
                let border = ElementBorder.defaultBorder()
                item.borderStyle = border.style
                item.borderColor = border.color
                item.borderWeight = border.weight
            })

        // Save group items and ungrouped items
        elements = elements.filter(item => this.itemHasUnsavedChanges(item) || item.id === undefined)
        this.context.log.info(elements.length + " have changed")

        for (const item1 of elements) {
            this.context.log.info(item1.itemKey + " has " + item1.children.length + " children")
            let itemUpdates = await this.saveWorksheetItem(item1, pageId)
            itemUpdates.forEach(u => updates.push(u))
        }
        this.context.log.flush(LogLevel.INFO)
    }
    saveWorksheetItem = async (item: WorksheetItem, pageId: number) => {
        let update: Partial<WorksheetItem> = { changed: false }
        let updates: WorksheetItemUpdate[] = []
        if (this.state.worksheet) {
            // delete worksheet items marked with deleted Flag
            if (item.id && item.deleted) {
                this.context.log.debug("Delete worksheet item " + item.id + "/" + item.itemKey)

                await DeleteWorksheetItem(this.state.worksheet.id, pageId, item.id)

                // Reset item id so undo does not restore the original item, and it will be recreated on save
                update.id = undefined
            }
            // save worksheet items otherwise
            else if (!item.deleted) {
                this.context.log.debug("Save worksheet item " + item.id + "/" + item.itemKey + "/" + item.content)

                let worksheetItem = await SaveWorksheetItem(item, this.state.worksheet.id, pageId)
                if (worksheetItem && worksheetItem.id) {
                    update.id = worksheetItem.id
                }

                // Save name config elements if the worksheet item has the feature enabled
                if (item.ref?.current?.hasNameConfigInstancesEnabled()) {
                    // Get all name config instances of the item and reduce to get a unique item list
                    let nameConfigInstanceIds = item.ref.current.getNameConfigInstances()
                        .filter((v, i, a) => a.indexOf(v) === i)
                    this.context.log.info("Number of name config instances = " + nameConfigInstanceIds.length)

                    if (worksheetItem && worksheetItem.id && nameConfigInstanceIds.length > 0) {
                        this.context.log.info("Merge name config elements for worksheet item " + worksheetItem.id)

                        MergeNameConfigElements(this.state.worksheet.id!, pageId, worksheetItem.id, nameConfigInstanceIds)
                            .then(elements => {
                                    this.setState({nameConfigElement: elements})
                                }
                            )
                    }
                }
            }

            // Update worksheet item to set changed to false and the id for new items
            if (item.children !== null && item.children.length > 0) {

                // Generate worksheet item updates for children to set group id (so the structure is updated correctly)
                let childUpdates = item.children
                    .filter(i => i.groupId === undefined)
                    .map(i => new WorksheetItemUpdate(i.itemKey, {groupId: new Entity("", update.id)}))
                childUpdates.forEach(u => updates.push(u))

                // Set group id for newly grouped items (to it is saved)
                for (const sub of item.children) {
                    if (sub.groupId === undefined) {
                        sub.groupId = new Entity("", update.id)
                        sub.changed = true
                    }
                }

                // Save items of this group
                await this.saveWorksheetItems(pageId, item.children, updates)
            }
        }

        updates.push(new WorksheetItemUpdate(item.itemKey, update))
        return updates
    }
    loadWorksheetItems = (worksheet: Worksheet) => {
        if (worksheet.pages) {
            const worksheetId = worksheet.id
            let promises: Promise<WorksheetItem[]>[] = []

            worksheet.pages.forEach(i => {
                let pageKey = WorksheetPage.getUniqueElementIdentifier(i)
                this.context.log.debug("Load elements of page " + pageKey)

                promises.push(new Promise((resolve) => {
                    GetWorksheetItems(worksheetId, i.id!).then(
                        async (worksheetItems: WorksheetItem[]) => {
                            this.context.log.debug("Elements on page " + pageKey + " = " + worksheetItems.length)

                            worksheetItems.forEach(item => {
                                item.save = true
                                item.worksheetPageKey = pageKey
                                item.borderVisible = (item.borderStyle !== "none")
                                item.children = []
                                item.ref = WorksheetItemType.getReactRefByWorksheetItemType(item.worksheetItemTypeId)
                            })

                            let preloadPromises = this.preloadCacheObjects(worksheetItems)

                            Promise.all(preloadPromises).then(_ => {
                                this.context.log.debug("All images preloaded")
                                this.context.log.flush(LogLevel.INFO)

                                worksheetItems = WDUtils.loadGroupStructureToHierarchy(worksheetItems)
                                resolve(worksheetItems)
                            })
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.worksheet_not_loaded))
                        }
                    )
                }))
            })

            // When all items from all pages are loaded start rendering
            Promise.all(promises).then(worksheetItems => {
                this.context.log.debug("All worksheet items loaded")
                this.context.log.flush(LogLevel.INFO)

                let elements: WorksheetItem[] = []
                worksheetItems.forEach(items => elements = elements.concat(items))
                this.context.log.flush()

                this.setState({elements: elements, loaded: true}, this.renderElements)
            })

            this.context.log.flush()
        }
    }
    loadWorksheetNameElements = (worksheet: Worksheet) => {
        GetAllNameConfigWS(worksheet.id).then(
            (names) => {
                this.setState({nameConfigWS: names})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )

        GetAllNameConfigElements(worksheet.id).then(
            (names) => {
                this.setState({nameConfigElement: names})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }

    /**
     * Presentation mode
     */
    onStartPresentation = async () => {
        await this.unselectAllElements()
        this.context.setZoom(1)

        this.setState({inPresentationMode: true}, () => {
            document.documentElement.requestFullscreen()
        })
    }
    onEndPresentation = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        }
        this.setState({inPresentationMode: false})
    }

    /**
     * print operations
     * **/
    onPrintWorksheet = async (printOptions: WDPrintOptions) => {
        if (this.state.worksheet && this.state.worksheet.editingAllowed) {
            await this.saveWorksheet(this.state.worksheet, false)
        }

        if (this.state.worksheet) {
            const documentName = this.state.worksheet.name.toLowerCase().replace(" ", "_")

            // Generate worksheet PDF (no solution sheets, no solutions displayed
            if (printOptions.printWorksheet) {
                await this.printPdf(documentName, PrintSolutionMode.NoSolutions)
            }

            // Generate solution PDF, either only solution sheets or with automatic solutions
            if (printOptions.printAutomaticSolutions || printOptions.printSolutionSheets) {
                await this.printPdf(
                    documentName + (printOptions.printAutomaticSolutions || printOptions.printSolutionSheets ? "_" +
                        this.context.translate(translations.text.solution_sheet) : ""),
                    printOptions.printSolutionSheets ? PrintSolutionMode.SolutionSheet : PrintSolutionMode.AutomaticSolutions)
            }
        }
    }
    onShowPrintDialog = () => {
        this.setState({showPrintDialog: true})
    }
    onCancelPrint = () => {
        this.setState({showPrintDialog: false})
    }
    printPdf = async (fileName: string, solutionMode: PrintSolutionMode) => {
        if (this.state.worksheet) {
            GetPrintableWorksheet(this.state.worksheet, solutionMode).then(
                async (response) => {
                    const blob = await response.blob()
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = fileName + `.pdf`
                    link.click()

                    this.setState({showPrintDialog: false}, () => {
                        if (this.showRatingDialog()) {
                            this.setState({showRateWorksheetMarketplace: true})
                        }
                    })
                }
            )
        }
    }

    /**
     * confirmation dialog
     * **/
    onShowConfirmationDialog = (title: string, description: string, onSubmit: () => void) => {
        this.setState({
            showConfirmationDialog: true,
            confirmationDialogOptions: new ConfirmationDialogOptions(title, description, onSubmit)
        })
    }
    onSubmitConfirmationDialog = () => {
        this.state.confirmationDialogOptions?.onSubmit()

        this.setState({
            showConfirmationDialog: false,
            confirmationDialogOptions: undefined
        })
    }
    onCancelConfirmationDialog = () => {
        this.setState({
            showConfirmationDialog: false,
            confirmationDialogOptions: undefined
        })
    }

    /**
     * Name Config
     * */
    removeNameConfigWS = (nameConfig: NameConfig) => {
        let nameConfWS = this.state.nameConfigWS.filter(conf => conf !== nameConfig)
        this.setState({nameConfigWS: nameConfWS})
    }
    addNameConfigWS = (nameConfig: NameConfig) => {
        let nameConfWS = this.state.nameConfigWS
        nameConfWS.push(nameConfig)
        this.setState({nameConfigWS: nameConfWS})
    }

    getCurrentViewCenterY = () => {
        let element = document.querySelector(".ws-designer-document")
        if (element) {
            let documentRect = element.getBoundingClientRect()

            let sheets = document.querySelectorAll(".ws-designer-sheet-workspace")
            let currentPosition = element.scrollTop

            for (let i = 0; i < sheets.length; i++) {
                let rect = sheets[i].getBoundingClientRect()
                if (currentPosition <= (rect.height / 2)) {
                    return currentPosition + (documentRect.height / 2)
                }
                currentPosition -= rect.height
            }
        }

        return 0
    }
    getCurrentPage = () => {
        let pages = this.state.worksheet?.pages?.filter(p => !p.deleted)
        if (pages && pages.length > this.state.currentPageIndex && this.state.currentPageIndex >= 0) {
            return pages[this.state.currentPageIndex]
        }

        return undefined
    }
    getCurrentPageIndex = () => {
        let element = document.querySelector(".ws-designer-document")
        if (element) {
            let sheets = document.querySelectorAll(".ws-designer-sheet-workspace")
            let currentPosition = 0

            for (let i = 0; i < sheets.length; i++) {
                let rect = sheets[i].getBoundingClientRect()
                if (element.scrollTop <= currentPosition + (rect.height / 2)) {
                    return i
                }
                currentPosition += rect.height
            }

            return sheets.length - 1
        }
        return -1
    }
    getCurrentPageKey = () => {
        let workspaces = document.querySelectorAll(".ws-designer-sheet-workspace")
        let id = ""
        if (workspaces && workspaces.length > this.state.currentPageIndex - 1) {
            let workspace = workspaces[this.state.currentPageIndex]
            let sheet = workspace.querySelector(".ws-designer-sheet")
            if (sheet) {
                id = sheet.id
            }
        }
        return id
    }

    scrollDocument = () => {
        const newIndex = this.getCurrentPageIndex()
        if (this.state.currentPageIndex !== newIndex) {
            this.setState({currentPageIndex: newIndex})
        }

        this.closeContextMenu()
    }
    onResizeWindow = () => {
        this.openToolbar()
    }
    onCloseWindow = (event) => {
        // Message will not be shown by browser
        if (this.hasUnsavedChanges()) {
            event.preventDefault();
            return (event.returnValue = this.context.translate(translations.text.sure_to_close));
        }
    }

    /**
     * Sidebar
     * */
    openSidebar = (sidebar: SidebarElement) => {
        this.state.refSidebar.current?.onItemClick(sidebar)
    }
    onOpenSidebar = (sidebar: SidebarElement) => {
        let stateObj = {activeSidebar: sidebar}

        if (sidebar !== SidebarElement.None) {
            // Close menu on the left
            this.state.refMenu.current?.closeMenu()
        }
        else {
            stateObj["errorReportMessage"] = undefined
        }

        // Open active sidebar
        this.setState(stateObj, () => this.unselectAllElements())
    }
    onMenuItemClicked = (item?: MenuItem) => {
        if (item) {
            this.state.refSidebar.current?.onItemClick(SidebarElement.None)
        }
        this.unselectAllElements()
    }
    onSendErrorReport = (message: string) => {
        this.setState({errorReportMessage: message}, () => this.openSidebar(SidebarElement.Help))
    }
    onErrorReportSent = () => {
        this.openSidebar(SidebarElement.None)
    }

    /**
     * Popout-Menu
     */
    onOpenPopoutMenu = (e: React.MouseEvent) => {
        this.state.refPopoutMenu.current?.openProfileMenu(e.nativeEvent)
    }
    onOpenSettings = (e: React.MouseEvent) => {
        this.unselectAllElements()
        this.state.refPopoutMenu.current?.closeProfileMenu(e.nativeEvent)
        this.setState({showSettingsDialog: true})
    }
    onOpenPublishInMarketplace = async (e: React.MouseEvent) => {
        await this.unselectAllElements()

        // Close profile menu, sidebar and menu
        this.state.refPopoutMenu.current?.closeProfileMenu(e.nativeEvent)
        this.state.refSidebar.current?.onItemClick(SidebarElement.None)
        this.state.refMenu.current?.closeMenu()

        if (this.state.worksheet) {
            await this.saveThumbnail(this.state.worksheet.id).then(() => {
                this.setState({showPublishInMarketplace: true})
            })
        }
    }

    onSaveWorksheetSettings = async (worksheetSettings: WorksheetSettings) => {
        let newWorksheet = WorksheetSettings.mapSettingsToWorksheet(worksheetSettings, WSContextType.standard, this.state.worksheet)
        if (newWorksheet) {
            if (newWorksheet.pages) {
                for (let i = 0; i < newWorksheet.pages.length; i++) {
                    const p = newWorksheet.pages[i];

                    // Apply page border and trim border on page
                    if (worksheetSettings.pageBorder) {
                        p.borderLeft = worksheetSettings.pageBorder.left
                        p.borderTop = worksheetSettings.pageBorder.top
                        p.borderRight = worksheetSettings.pageBorder.right
                        p.borderBottom = worksheetSettings.pageBorder.bottom
                        p.changed = true
                    }
                    if (worksheetSettings.trimBorder) {
                        p.trimBorderLeft = worksheetSettings.trimBorder.left
                        p.trimBorderTop = worksheetSettings.trimBorder.top
                        p.trimBorderRight = worksheetSettings.trimBorder.right
                        p.trimBorderBottom = worksheetSettings.trimBorder.bottom
                        p.changed = true
                    }
                }
            }

            await this.saveWorksheetSettings(newWorksheet)
        }
    }
    onSavePublishInMarketplace = async (worksheetSettings: WorksheetSettings) => {
        if (this.state.worksheet) {
            let worksheet = WorksheetSettings.mapSettingsToWorksheet(
                worksheetSettings,
                this.state.worksheet?.context || WSContextType.standard,
                this.state.worksheet
            ) || this.state.worksheet

            worksheet.marketplaceStatus = WSMarketplaceStatus.approval

            await this.onSaveWorksheet(worksheet, false, true, async () => {
                this.state.refMenu.current?.closeMenu()

                this.setState({
                    showPublishInMarketplace: false,
                    announcement: new NotificationData(NotificationStatus.info, this.context.translate(translations.text.marketplace.while_approval_locked))
                })
            })
        }
    }
    onSaveRateWorksheet = async (rating: number, showName: boolean, description?: string) => {
        if (this.state.worksheet === undefined) {
            return
        }

        let obj = new WorksheetRating(this.state.worksheet, rating, showName, false, description)
        CreateWorksheetRating(this.state.worksheet.id, obj).then(() => {
                let worksheet = this.state.worksheet
                worksheet!.ratingDone = true
                this.setState({worksheet: worksheet, showRateWorksheetMarketplace: false})
            },
            (error) => {
                this.context.handleError(error, undefined, undefined, this.context.translate(translations.notification.title_error))

                let worksheet = this.state.worksheet
                worksheet!.ratingDone = false
                this.setState({worksheet: worksheet, showRateWorksheetMarketplace: false})
            })
    }

    onCloseRateWorksheet = () => {
        RejectWorksheetRating(this.state.worksheet!.id).then(() => {
                let worksheet = this.state.worksheet
                worksheet!.ratingDone = false

                this.setState({worksheet: worksheet, showRateWorksheetMarketplace: false})
            },
            (error) => {
                this.context.handleError(error, undefined, undefined, this.context.translate(translations.notification.title_error))
            })
    }
    onCloseSettings = () => {
        this.setState({showSettingsDialog: false})
    }
    onClosePublishInMarketplace = () => {
        this.setState({showPublishInMarketplace: false})
    }

    /**
     * Rendering
     * */
    isEditingAllowed = () => {
        return this.state.worksheet === undefined
            || (this.state.worksheet.editingAllowed && this.state.worksheet.marketplaceStatus !== WSMarketplaceStatus.approval && this.state.worksheet.marketplaceStatus !== WSMarketplaceStatus.updated)
            || (Auth.isAdmin() && Auth.getUserId() !== this.state.worksheet.ownerId?.id)
    }
    isMPPublishAllowed = () => {
        return this.isEditingAllowed() && this.state.worksheet?.marketplaceStatus !== WSMarketplaceStatus.approval && this.state.worksheet?.context === WSContextType.standard
    }
    showRatingDialog = () => {
        return (this.state.worksheet?.ratingDone === undefined || this.state.worksheet?.ratingDone === null) &&
            this.state.worksheet?.marketplaceStatus === WSMarketplaceStatus.downloaded
    }

    getSolutionSheets = () => {
        return this.state.worksheet?.pages?.filter(p => !p.deleted && p.solution)
    }
    getPagesToRender = () => {
        if (this.state.worksheet && this.state.worksheet.pages) {
            let pages = this.state.worksheet?.pages?.filter(p => !p.deleted)

            if (this.state.renderingMedia === RenderingMedia.print) {
                if (this.state.printSolutionMode === PrintSolutionMode.SolutionSheet) {
                    pages = pages?.filter(p => p.solution)
                } else if (this.state.printSolutionMode === PrintSolutionMode.NoSolutions) {
                    pages = pages?.filter(p => !p.solution)
                }
            }

            return pages
        }
        else {
            return [new WorksheetPage(WorksheetPage.getNewPageKey(), false, PageBorderPosition.getDefaultPageBorder(),
                PageBorderPosition.getNoPageBorder(), 1, true)]
        }
    }
    hasAutomaticSolutionElements = () => {
        let items = this.getAllElementsRecursive()
            .filter(item => !item.deleted && (
                item.worksheetItemTypeId === WorksheetItemTypeEnum.TEXT_EXERCISE ||
                item.worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TRIANGLE ||
                item.worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TOWER
            ))

        return items.length > 0
    }

    getWorksheetContentSize = (): Coords => {
        let size = new Coords(0, 0)
        this.state.worksheet?.pages?.filter(p => !p.deleted)
            .forEach(p => {
            let result = this.getWorksheetPageSize(p)
            size.x = Math.max((result.x + 180) * this.context.getZoom(), size.x)
            size.y += ((result.y + 180) * this.context.getZoom())
        })

        return size
    }
    getWorksheetPageSize = (page: WorksheetPage) => {
        const portrait = page.orientation === undefined ? true : page.orientation
        const format = this.state.worksheet?.format || WSPageFormat.A4

        return new Coords(
            WDUtils.getWorksheetPageWidth(format, portrait),
            WDUtils.getWorksheetPageHeight(format, portrait)
        )
    }

    isNew = () => {
        return isNaN(+this.props.match.params.id) && this.state.worksheet === undefined
    }
    renderElements = () => {
        let loadElement = (item: WorksheetItem) => {
            item.resized = false
            item.selected = false
        }

        this.state.elements
            .filter(item => item.worksheetItemTypeId === WorksheetItemTypeEnum.GROUP || item.worksheetItemTypeId === WorksheetItemTypeEnum.WRITING_COURSE)
            .forEach(item => loadElement(item))

        this.state.elements
            .filter(item => item.worksheetItemTypeId !== WorksheetItemTypeEnum.GROUP && item.worksheetItemTypeId !== WorksheetItemTypeEnum.WRITING_COURSE)
            .forEach(item => loadElement(item))

        this.setState({elements: this.state.elements})
    }
    renderSheets = () => {
        let solutionForceMode = SolutionForceMode.Off
        if (this.state.renderingMedia === RenderingMedia.print) {
            switch (this.state.printSolutionMode) {
                case PrintSolutionMode.AutomaticSolutions:
                    solutionForceMode = SolutionForceMode.ForceShow
                    break
                case PrintSolutionMode.SolutionSheet:
                    solutionForceMode = SolutionForceMode.ForceShow
                    break
                case PrintSolutionMode.NoSolutions:
                    solutionForceMode = SolutionForceMode.ForceHide
                    break
            }
        }

        return this.getPagesToRender()?.sort((a, b) => a.sort - b.sort)
            .map(i => {
                let pageKey = WorksheetPage.getUniqueElementIdentifier(i)
                return <WDSheet
                    key={pageKey}
                    worksheetPage={i}
                    elements={!this.state.loaded ? undefined : this.getElementsByPage(pageKey)}
                    format={this.state.worksheet ? this.state.worksheet.format : WSPageFormat.A4}
                    worksheetOrientation={this.state.worksheet ? this.state.worksheet.orientation : true}
                    orientation={i.orientation}
                    solution={i.solution}
                    isEditingAllowed={this.isEditingAllowed()}
                    showNonPrintableObjects={this.state.showNonPrintableObjects}
                    inPresentationMode={false}
                    renderingMedia={this.state.renderingMedia}
                    zoom={this.context.getZoom()}

                    onChangePageSettings={this.onChangePageSettings}
                    onDropElement={this.onDropElement}
                    onPageAdd={this.onPageAdd}
                    onChangeSolution={this.onChangePageSolution}

                    onElementLoaded={this.onElementLoaded}
                    onElementEdit={this.onElementEdit}
                    onElementSelect={this.onElementSelect}
                    onElementResize={this.onElementResize}
                    onElementConvert={this.onElementConvert}

                    onUpdateElement={this.onUpdateElement}
                    onResizeStateChanged={this.onResizeStateChanged}
                    onPropagateElementEvent={this.onPropagateElementEvent}
                    onContextMenu={this.onContextMenuElement}

                    addElementToGroup={this.addElementToGroup}
                    addElementToDesigner={this.addElementToDesigner}
                    deleteChildren={this.deleteChildren}
                    openSidebar={this.openSidebar}
                    updateElementData={this.updateElementData}
                    updateHistory={this.updateHistory}
                    pushHistory={this.pushElementHistory}

                    solutionForceMode={solutionForceMode}
                    context={this.state.worksheet?.context}
                />
            })
    }

    render() {
        const contentSize = this.getWorksheetContentSize()

        if (this.state.renderingMedia === RenderingMedia.print) {
            return <>{this.renderSheets()}</>
        }

        const allowEditing = this.isEditingAllowed()
        const allowMarketplacePublish = this.isMPPublishAllowed()
        const allowUndo = allowEditing && (this.state.historyIndex >= 0)
        const allowRedo = allowEditing && (this.state.historyIndex < this.state.historyStack.length - 1)

        let popoutMenuItems = [
            new PopupMenuItem(this.context.translate(translations.text.worksheet_settings.worksheet_settings), this.onOpenSettings)
        ]

        let marketplaceStatus: WSMarketplaceStatus | undefined = undefined
        if (this.state.worksheet) {
            marketplaceStatus = Worksheet.getMarketplaceStatus(this.state.worksheet)
            if (allowMarketplacePublish) {
                const label = this.context.translate(this.state.worksheet?.marketplaceStatus === WSMarketplaceStatus.published ? translations.text.marketplace.update_to_marketplace : translations.text.marketplace.send_to_marketplace)
                popoutMenuItems.push(new PopupMenuItem(label, this.onOpenPublishInMarketplace))
            }
        }

        return <div className="ws-designer">

            {this.state.inPresentationMode && this.state.worksheet &&
                <WDPresentation
                    worksheet={this.state.worksheet!}
                    elements={this.state.elements}
                    contentWidth={contentSize.x}
                    contentHeight={contentSize.y}
                    onEndPresentationMode={this.onEndPresentation}
                    onScrollDocument={this.scrollDocument}
                    onToolbarAction={this.onPresentationAction}
                    onChangeZoom={zoom => this.context.setZoom(zoom)}
                />
            }

            {!this.state.inPresentationMode &&
                <>

                    {/* App header with specific buttons */}
                    <AppHeader isAdminArea={false}
                               app={this.context.translate(translations.app.designer)}
                               notification={this.state.announcement}
                    >
                        <div className="app-header-group-center" id={"app-header-group-center"}>

                            <div
                                className={"ws-designer-header-button tooltip tooltip-below " + (allowEditing ? "ws-designer-header-button-active" : "ws-designer-header-button-inactive")}
                                style={{borderWidth: 0}}
                                onClick={allowEditing
                                    ? () => { if (this.state.worksheet) { this.onSaveWorksheet(this.state.worksheet, false, true) }}
                                    : () => {}
                                }>

                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save_ws.svg"}
                                     alt={this.context.translate(translations.command.save)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.command.save),
                                    this.context.translate(translations.tooltip.save))}
                                          translateX={-5} translateY={45}
                                />
                            </div>

                            <div
                                className={"ws-designer-header-button tooltip tooltip-below " + (allowEditing ? "ws-designer-header-button-active" : "ws-designer-header-button-inactive")}
                                style={{borderWidth: 0}}
                                onClick={allowEditing
                                    ? () => { if (this.state.worksheet) { this.onSaveWorksheet(this.state.worksheet, true, true)} }
                                    : () => {}
                                }>

                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "save_and_close.svg"}
                                     alt={this.context.translate(translations.command.save_and_close)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.command.save_and_close),
                                    this.context.translate(translations.tooltip.save_and_close))}
                                          translateX={-5} translateY={45}
                                />
                            </div>

                            <div
                                className={"ws-designer-header-button tooltip tooltip-below " + (allowMarketplacePublish ? "ws-designer-header-button-active" : "ws-designer-header-button-inactive")}
                                style={{borderWidth: 0}}
                                onClick={allowMarketplacePublish ? (e) => this.onOpenPublishInMarketplace(e) : () => {
                                }}>

                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "marketplace_upload.svg"}
                                     alt={this.context.translate(translations.text.marketplace.send_to_marketplace)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.command.mp_upload),
                                    this.context.translate(translations.tooltip.mp_upload))}
                                          translateX={-5} translateY={45}
                                />
                            </div>

                            <div
                                className={"ws-designer-header-button ws-designer-header-button-active tooltip tooltip-below"}
                                onClick={this.onShowPrintDialog}>
                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "print.svg"}
                                     alt={this.context.translate(translations.command.print)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.command.print),
                                    this.context.translate(translations.tooltip.print))}
                                          translateX={-5} translateY={45}
                                />
                            </div>

                            <div
                                className={"ws-designer-header-button ws-designer-header-button-active tooltip tooltip-below"}
                                style={{borderWidth: 0}}
                                onClick={this.onStartPresentation}>

                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "presentation.svg"}
                                     alt={this.context.translate(translations.text.presentation_mode.name)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.text.presentation_mode.name),
                                    this.context.translate(translations.tooltip.presentation_mode.name))}
                                          translateX={-5} translateY={45}
                                />
                            </div>

                            <div
                                className={"ws-designer-header-button tooltip tooltip-below " + (allowUndo ? "ws-designer-header-button-active" : "ws-designer-header-button-inactive")}
                                onClick={allowUndo ? this.onUndo : () => {
                                }} style={{borderWidth: 0}}>

                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "navigation_backward.svg"}
                                     alt={this.context.translate(translations.toolbar.undo)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.command.undo),
                                    this.context.translate(translations.tooltip.undo))}
                                          translateX={-5} translateY={45}
                                />
                            </div>
                            <div
                                className={"ws-designer-header-button tooltip tooltip-below " + (allowRedo ? "ws-designer-header-button-active" : "ws-designer-header-button-inactive")}
                                onClick={allowRedo ? this.onRedo : () => {
                                }} style={{borderLeftWidth: 0}}>
                                <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "navigation_forward.svg"}
                                     alt={this.context.translate(translations.toolbar.redo)}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault()}
                                />
                                <Tooltips text={new TooltipText(
                                    this.context.translate(translations.command.redo),
                                    this.context.translate(translations.tooltip.redo))}
                                          translateX={-5} translateY={45}
                                />
                            </div>
                        </div>
                        <div className="app-header-group-right">
                            {marketplaceStatus &&
                                <MPStatusIcon mpStatus={marketplaceStatus}
                                              tooltipPosition={TooltipPosition.below}
                                              translateX={-80}
                                              translateY={-15}/>
                            }

                            <div className={"app-header-text-small"}
                                 onClick={this.onOpenPopoutMenu}>{this.state.worksheet?.name}</div>

                            <PopoutMenu id={"worksheet-title"} width={300} items={popoutMenuItems}
                                        ref={this.state.refPopoutMenu}/>
                        </div>
                    </AppHeader>

                    {this.saving &&
                        <div className={"ws-designer-auto-save"}>
                            <img src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "loading.gif"}
                                 alt="loading"
                                 className={"svg-color-AEABAB"}
                                 draggable={"false"}
                                 onContextMenu={(e) => e.preventDefault()}
                            />
                        </div>
                    }

                    <div className="ws-designer-main">
                        {/* Main menu (left) */}
                        <div className="menu-container">
                            <Menu menuType={MenuType.toolbox}
                                  menuContext={MenuContext.toolbox}
                                  ref={this.state.refMenu}
                                  onMenuItemClicked={this.onMenuItemClicked}
                                  onMenuItemBack={this.onCancel}
                                  isEditingAllowed={this.isEditingAllowed()}
                            />
                        </div>

                        {/* Main content - static frame */}
                        <div className={"ws-designer-content-frame"}>

                            {/* Floating add element button */}
                            {this.isEditingAllowed() &&
                                <PlusButton setElementMode={this.setElementMode}
                                            zoom={this.context.getZoom()}
                                            onChangeZoom={zoom => this.context.setZoom(zoom)}
                                />
                            }

                            {/* Scrollable container */}
                            <div id="ws-designer-document" className="ws-designer-document"
                                 onScroll={this.scrollDocument}>

                                {/*Content frame with all sheets*/}
                                <div id="ws-designer-content" className="ws-designer-content"
                                     style={{
                                         height: contentSize.y
                                     }}

                                     onMouseDown={event => this.onMouseDown(event.nativeEvent)}
                                     onContextMenu={event => this.onContextMenuDocument(event.nativeEvent)}

                                    // onDrop={e => this.onDropPage(e)}
                                    // onDragOver={e => e.preventDefault()}
                                    // onDragEnter={e => e.preventDefault()}
                                    // onDragLeave={e => e.preventDefault()}
                                >
                                    {this.renderSheets()}
                                </div>
                            </div>

                            {/* Sidebar buttons (right) */}
                            <Sidebar onOpenSidebar={this.onOpenSidebar}
                                     wsContext={this.state.worksheet?.context || WSContextType.standard}
                                     isEditingAllowed={this.isEditingAllowed()}
                                     ref={this.state.refSidebar}/>
                        </div>

                        {/* Sidebar content (images, help, frames, etc.) */}
                        {this.state.activeSidebar !== undefined &&
                            <div
                                className={this.state.activeSidebar === SidebarElement.None ? "ws-designer-sidebar-container-hide" : "ws-designer-sidebar-container-show"}>
                                {this.state.activeSidebar === SidebarElement.PageManager && this.state.worksheet && this.state.worksheet.pages &&
                                    <SidebarPageManager
                                        pages={this.state.worksheet.pages}
                                        currentPageIndex={this.state.currentPageIndex}
                                        onPageAdd={this.onPageAdd}
                                        onPageDelete={this.onPageDelete}
                                        onPageCopy={this.onPageCopy}
                                        onPageMove={this.onPageMove}
                                        onPageSolution={this.onChangePageSolutions}
                                        onChangePageOrientation={this.onChangePageOrientation}
                                        isEditingAllowed={this.isEditingAllowed()}
                                        ref={this.state.refSidebarPageManager}
                                    />
                                }

                                {this.state.activeSidebar === SidebarElement.Frames && this.state.worksheet &&
                                    <SidebarFrames
                                        worksheetType={this.state.worksheet.context}
                                        page={_.clone(this.getCurrentPage())}
                                        onChangePageBorder={this.onChangePageBorder}
                                        onToggleNonPrintObjectsVisibility={this.onToggleNonPrintObjectsVisibility}
                                        showNonPrintableObjects={this.state.showNonPrintableObjects}
                                        isEditingAllowed={this.isEditingAllowed()}
                                    />
                                }

                                {this.state.activeSidebar === SidebarElement.Images && this.state.worksheet &&
                                    <SidebarImages worksheetType={this.state.worksheet.context}/>
                                }
                                {this.state.activeSidebar === SidebarElement.Names && this.state.worksheet &&
                                    <SidebarNames
                                        worksheetId={this.state.worksheet}
                                        nameConfigWS={this.state.nameConfigWS}
                                        removeWSConfig={this.removeNameConfigWS}
                                        addWSConfig={this.addNameConfigWS}
                                    />
                                }
                                {this.state.activeSidebar === SidebarElement.Dictionary && this.state.worksheet &&
                                    <SidebarDictionary
                                        worksheetId={this.state.worksheet}
                                        currentPageKey={this.getCurrentPageKey()}
                                        addElementToDesigner={this.addElementToDesigner}
                                    />
                                }
                                {this.state.activeSidebar === SidebarElement.Help && this.state.worksheet &&
                                    <SidebarHelp
                                        worksheet={this.state.worksheet}
                                        sendErrorReport={this.state.errorReportMessage !== undefined}
                                        description={this.state.errorReportMessage}
                                        onErrorReportSent={this.state.errorReportMessage !== undefined ? this.onErrorReportSent : undefined}
                                    />
                                }
                            </div>
                        }
                    </div>

                    {this.state.elementToolbar}
                    {this.state.contextMenu}

                    {this.isNew() &&
                        <WDSettings worksheet={this.state.worksheet}
                                    onClose={this.onCancel}
                                    onSave={this.onCreateWorksheet}
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                        />
                    }
                    {this.state.showSettingsDialog &&
                        <WDSettings worksheet={this.state.worksheet}
                                    onClose={this.onCloseSettings}
                                    onSave={this.onSaveWorksheetSettings}
                                    history={this.props.history}
                                    location={this.props.location}
                                    match={this.props.match}
                        />
                    }
                    {this.state.showPublishInMarketplace &&
                        <PublishInMarketplaceModal
                            worksheet={this.state.worksheet}
                            onSave={this.onSavePublishInMarketplace}
                            onClose={this.onClosePublishInMarketplace}
                        />
                    }

                    {this.state.showRateWorksheetMarketplace &&
                        <RateWorksheetModal
                            worksheet={this.state.worksheet}
                            onSave={this.onSaveRateWorksheet}
                            onClose={this.onCloseRateWorksheet}
                        />
                    }

                    {this.state.showPrintDialog &&
                        <WDPrintModal
                            numberOfSolutionSheets={this.getSolutionSheets()?.length || 0}
                            hasAutomaticSolutionElements={this.hasAutomaticSolutionElements()}
                            onCancel={this.onCancelPrint}
                            onPrint={this.onPrintWorksheet}
                        />
                    }

                    {this.state.showConfirmationDialog && this.state.confirmationDialogOptions &&
                        <Modal id={"confirmationDialog"}
                               onFormSubmit={this.onSubmitConfirmationDialog}
                               onFormCancel={this.onCancelConfirmationDialog}
                               title={this.state.confirmationDialogOptions.title}
                               buttons={[
                                   new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onCancelConfirmationDialog, {}),
                                   new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.command.ok), undefined, {marginLeft: "10px"})
                               ]}
                               dialogStyle={{width: "15%", height: "250px", minWidth: "400px"}}
                               contentAlignment={"flex-start"}
                        >
                            {this.state.confirmationDialogOptions.description}
                        </Modal>
                    }

                    {/* Handle unsaved changes - show dialog */}
                    <Prompt when={this.hasUnsavedChanges()} message={l => this.blockNavigation(l)}/>
                    {!this.isNew() && this.state.showCloseDialog && !this.saving &&
                        <UnsavedChangesModal checkSaveDecision={this.closeDialogInteraction}
                                             showSaveAlwaysCheckbox={true}
                                             cancel={this.cancelSaveDialog}/>
                    }

                    {/* Drag image container hosting the image while dragging */}
                    <div id={"dragImageContainer"} style={{position: "absolute", left: "-100%"}}/>

                    <div id={"select-box"} className={"ws-designer-select-box"}/>

                </>}
        </div>
    }
}

export default withRouter(WDesigner)