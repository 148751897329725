import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarButtonDeleteRow} from "../../Toolbar/Button/Lineature/WDToolbarButtonDeleteRow";
import {WDRowPosition, WDToolbarButtonInsertRow} from "../../Toolbar/Button/Lineature/WDToolbarButtonInsertRow";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import {ElementBorder, ElementLayout} from "../WDElementContainer";
import {
    WDColumnPosition,
    WDToolbarButtonInsertColumn
} from "../../Toolbar/Button/Lineature/WDToolbarButtonInsertColumn";
import {WDToolbarButtonDeleteColumn} from "../../Toolbar/Button/Lineature/WDToolbarButtonDeleteColumn";
import {WDToolbarButtonSeparator} from "../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonSolution} from "../../Toolbar/Button/Admin/WDToolbarButtonSolution";
import {Coords} from "../../../Framework/Coords";
import {WSContext, WSContextType} from "../WSContext";
import {WDToolbarSectionText, WDToolbarSectionTextSettings} from "../../Toolbar/Section/WDToolbarSectionText";
import {
    ImageToolbarButtonType,
    WDToolbarButtonSelectImage
} from "../../Toolbar/Button/Image/WDToolbarButtonSelectImage";
import {WDToolbarButtonConvertToSyllableText} from "../../Toolbar/Button/Text/WDToolbarButtonConvertToSyllableText";
import {SyllableMethod} from "../../../_model/Dictionary/Word";
import _ from "lodash";
import translations from "../../../Framework/translations.json";
import {TooltipPosition, Tooltips, TooltipText} from "../../../Components/Tooltips";
import {NumberInput} from "../../../Components/Controls/NumberInput";
import {LinkBracketInfo, WDToolbarLinkBracket} from "../../Toolbar/General/WDToolbarLinkBracket";
import {BorderType, WDToolbarButtonCellBorder} from "../../Toolbar/Button/Lineature/WDToolbarButtonCellBorder";
import {WDToolbarButton} from "../../Toolbar/Button/WDToolbarButton";
import {ImageCategory, ImagePath} from "../../../Framework/CategoryImage";
import {ManualBorderMode, VerticalAlignment} from "../../../Framework/Enums";
import {WDToolbarButtonTextDecoration} from "../../Toolbar/Button/Text/WDToolbarButtonTextDecoration";
import {WDToolbarButtonColor} from "../../Toolbar/Button/General/WDToolbarButtonColor";
import {WDTableCellData, WDTableColumnData, WDTableRowData} from "./WDTableData";
import {WDToolbarButtonList} from "../../Toolbar/Button/Text/WDToolbarButtonList";
import {BorderPadding} from "../../../Components/Controls/BorderPadding";

export interface WDTableProps extends WDToolbarElementProps {
    syllableMethod: SyllableMethod

    getSelectedCells: () => Coords[]
}
export interface WDTableState extends WDToolbarElementState {
    borderType: string
    link: boolean
    linkBorder: boolean
}

export class WDTableToolbar extends WDToolbarElement<WDTableProps, WDTableState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    textToolbarSettings: WDToolbarSectionTextSettings = new WDToolbarSectionTextSettings(true, true,
        true, true, true, true, true, true
        , true, true, true, true, true)

    constructor(props: WDTableProps) {
        super(props)

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            borderType: BorderType.bottom.toUpperCase(),
            link: true,
            linkBorder: false,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }
    }

    componentDidUpdate(prevProps: WDTableProps) {
        // TODO: check new color of table cells!
        // maybe just change selected cells to Celldata with color instead of only coords
    }
    shouldComponentUpdate(nextProps: Readonly<WDTableProps>, nextState: Readonly<WDTableState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    onChangeToolbarTab = (type: WDToolbarTypeEnum) => {
        if(this.state.activeToolbarType !== type) {
            this.setState({activeToolbarType: type, activeId: -1})
        }
    }

    onToolbarAction = (action: WDToolbarAction, data?: any) => {
        this.props.onToolbarAction(action, data)

        if (action !== WDToolbarAction.CELL_BORDER_MANUAL_COLOR) {
            this.setState({activeId: -1})
        }
    }
    onToggleLink = () => {
        this.setState({link: !this.state.link})
    }
    onChangeCellWidth = (width: number, height: number, value: number) => {
        this.props.onToolbarAction(WDToolbarAction.CELL_SIZE, {width: value, height: undefined})
    }
    onChangeCellHeight = (width: number, height: number, value: number) => {
        this.props.onToolbarAction(WDToolbarAction.CELL_SIZE, {width: undefined, height: value})
    }
    onBorderTypeChange = (action: WDToolbarAction, data?: any) => {
        if (data && data["type"]) {
            this.setState({borderType: data["type"].toUpperCase()})
        }

        this.onToolbarAction(action, data)
    }
    onChangeBorder = (borderOptions: ElementBorder) => {
        let data: any = {}
        if (borderOptions.paddingTop >= 0) {
            data.paddingTop = borderOptions.paddingTop
        }
        if (borderOptions.paddingRight >= 0) {
            data.paddingRight = borderOptions.paddingRight
        }
        if (borderOptions.paddingBottom >= 0) {
            data.paddingBottom = borderOptions.paddingBottom
        }
        if (borderOptions.paddingLeft >= 0) {
            data.paddingLeft = borderOptions.paddingLeft
        }

        this.props.onToolbarAction(WDToolbarAction.BORDER_OPTIONS, data)

        if (borderOptions.linkPadding !== this.state.linkBorder) {
            this.setState({linkBorder: borderOptions.linkPadding})
        }
    }

    getSelectedCells = (): Coords[] => {
        const selectedCellsObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "selectedCells")
        const selectedCells = (selectedCellsObj?.valueEqual && selectedCellsObj.newValue !== undefined) ? selectedCellsObj.newValue : [] as Coords[]

        return JSON.parse(selectedCells) as Coords[]
    }

    getBorderPadding = (cells: WDTableCellData[][]): ElementBorder => {
        let paddingLeft: number | undefined = undefined,
            paddingRight: number | undefined = undefined,
            paddingTop: number | undefined = undefined,
            paddingBottom: number | undefined = undefined

        let selected = this.getSelectedCells()
        for (let i = 0; i < cells.length; i++) {
            for (let j = 0; j < cells[0].length; j++) {
                let cell = cells[i][j]

                if (selected.length === 0 || selected.find(c => c.x === i && c.y === j) !== undefined) {
                    if (paddingLeft === undefined) {
                        paddingLeft = cell.paddingLeft || 0
                    } else if (paddingLeft !== cell.paddingLeft) {
                        paddingLeft = -1
                    }

                    if (paddingRight === undefined) {
                        paddingRight = cell.paddingRight || 0
                    } else if (paddingRight !== cell.paddingRight) {
                        paddingRight = -1
                    }

                    if (paddingTop === undefined) {
                        paddingTop = cell.paddingTop || 0
                    } else if (paddingTop !== cell.paddingTop) {
                        paddingTop = -1
                    }

                    if (paddingBottom === undefined) {
                        paddingBottom = cell.paddingBottom || 0
                    } else if (paddingBottom !== cell.paddingBottom) {
                        paddingBottom = -1
                    }
                }
            }
        }

        let border = ElementBorder.defaultBorder()
        border.linkPadding = this.state.linkBorder
        border.paddingLeft = paddingLeft !== undefined ? paddingLeft : -1
        border.paddingRight = paddingRight !== undefined ? paddingRight : -1
        border.paddingTop = paddingTop !== undefined ? paddingTop : -1
        border.paddingBottom = paddingBottom !== undefined ? paddingBottom : -1

        return border
    }

    getVerticalAlignment = (cells: WDTableCellData[][]) => {
        let selected = this.getSelectedCells()
        let verticalAlign: VerticalAlignment | undefined = undefined

        for (let i = 0; i < cells.length; i++) {
            for (let j = 0; j < cells[0].length; j++) {
                let cell = cells[i][j]

                if (selected.length === 0 || selected.find(c => c.x === i && c.y === j) !== undefined) {
                    if (verticalAlign === undefined) {
                        verticalAlign = cell.data.verticalAlignment || VerticalAlignment.top
                    } else if (verticalAlign !== cell.data.verticalAlignment) {
                        return null
                    }
                }
            }
        }

        return verticalAlign
    }

    getNumberOfSelectedCellsPerDimension = () => {
        let rows: number[] = []
        let cols: number[] = []

        let selected = this.getSelectedCells()

        if (selected.length === 0) {
            return new Coords(0, 0)
        }

        for(let i = 0; i < selected.length; i++) {
            if (rows.find(r => r === selected[i].x) === undefined) {
                rows.push(selected[i].x)
            }
            if (cols.find(c => c === selected[i].y) === undefined) {
                cols.push(selected[i].y)
            }
        }

        return new Coords (new Set(cols).size, new Set(rows).size)
    }
    getSelectedCellSize = (rows: WDTableRowData, cols: WDTableColumnData) => {
        let selected = this.getSelectedCells()
        if (selected.length === 0) {
            return new Coords(-1, -1)
        }

        let result: Coords = new Coords(0, 0)
        let rIndex: number[] = [], cIndex: number[] = []
        for(let i = 0; i < selected.length; i++) {
            if (cIndex.find(c => c === selected[i].y) === undefined) {
                cIndex.push(selected[i].y)
                if (cols[selected[i].y]) {
                    result.x += cols[selected[i].y].width
                }
            }
            if (rIndex.find(r => r === selected[i].x) === undefined) {
                rIndex.push(selected[i].x)
                if (rows[selected[i].x]) {
                    result.y += rows[selected[i].x].height
                }
            }
        }

        return result
    }

    render() {
        // Get values to display data - important if more than one element is selected
        let cells = this.multiselectSearchForValue("cells")
        let cols = this.multiselectSearchForValue("cols")
        let rows = this.multiselectSearchForValue("rows")
        let activateSyllableValueObj = this.multiselectSearchForValue("syllableActivated")
        let borderModeObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "borderMode")
        let backgroundObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "color")

        const activateSyllable = (activateSyllableValueObj?.valueEqual && activateSyllableValueObj.newValue !== undefined) ? activateSyllableValueObj.newValue : false
        let background = (backgroundObj?.valueEqual && backgroundObj.newValue !== undefined) ? backgroundObj.newValue : "transparent"

        let rowCount = (cells && cells.newValue) ? cells.newValue.length : 0
        let colCount = (cells && cells.newValue && cells.newValue[0]) ? cells.newValue[0].length : 0
        let width: number | undefined = undefined
        let height: number | undefined = undefined

        let borderPadding = this.getBorderPadding(cells?.newValue)

        let selected = this.getNumberOfSelectedCellsPerDimension()
        let deleteColumnEnabled = colCount > selected.x
        let deleteRowEnabled = rowCount > selected.y

        if (rows && cols) {
            let size = this.getSelectedCellSize(rows.newValue, cols.newValue)
            if (size.x > 0) {
                width = Math.round(Converter.pxToMm(size.x))
            }
            if (size.y > 0) {
                height = Math.round(Converter.pxToMm(size.y))
            }
        }

        let verticalAlign = this.getVerticalAlignment(cells?.newValue)

        const borderMode = (borderModeObj?.valueEqual && borderModeObj.newValue !== undefined) ? borderModeObj.newValue : ManualBorderMode.Off

        let isCellBorderPressed = (borderMode === ManualBorderMode.Border && this.state.borderType === BorderType.manual.toUpperCase())
        isCellBorderPressed = isCellBorderPressed || (borderMode === ManualBorderMode.Erase && this.state.borderType === BorderType.erase.toUpperCase())

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, true, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&
            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={false}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={true}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.TEXT &&
                <ul className={"ws-designer-toolbar-buttons"}>
                    <WDToolbarSectionText
                        toolbarSettings={this.textToolbarSettings}
                        onToolbarAction={this.onToolbarAction}
                    />

                    <WDToolbarButtonSeparator/>

                    <BorderPadding
                        borderOptions={borderPadding}
                        onChangeBorder={this.onChangeBorder}
                    />

                    <WDToolbarButtonSeparator/>

                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonTextDecoration
                                id={330}
                                clickable={true}
                                colorPicker={false}
                                command={"justifyLeft"}
                                action={WDToolbarAction.JUSTIFY_LEFT}
                                icon={ImagePath.getButtonUrl() + "justify_left.svg"}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.justify_left),
                                    this.context.translate(translations.tooltip.align_horizontal_left)
                                )}
                                onActionButton={this.onToolbarAction}/>

                            <WDToolbarButtonTextDecoration
                                id={331}
                                clickable={true}
                                colorPicker={false}
                                command={"justifyCenter"}
                                action={WDToolbarAction.JUSTIFY_CENTER}
                                icon={ImagePath.getButtonUrl() + "justify_center.svg"}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.justify_center),
                                    this.context.translate(translations.tooltip.align_horizontal_middle)
                                )}
                                onActionButton={this.onToolbarAction}/>

                            <WDToolbarButtonTextDecoration
                                id={332}
                                clickable={true}
                                colorPicker={false}
                                command={"justifyRight"}
                                action={WDToolbarAction.JUSTIFY_RIGHT}
                                icon={ImagePath.getButtonUrl() + "justify_right.svg"}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.justify_right),
                                    this.context.translate(translations.tooltip.align_horizontal_right)
                                )}
                                onActionButton={this.onToolbarAction}/>

                            <WDToolbarButtonTextDecoration
                                id={333}
                                clickable={true}
                                colorPicker={false}
                                command={"justifyFull"}
                                action={WDToolbarAction.JUSTIFY_FULL}
                                icon={ImagePath.getButtonUrl() + "justify_block.svg"}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.justify_full),
                                    this.context.translate(translations.tooltip.align_horizontal_justify)
                                )}
                                onActionButton={this.onToolbarAction}
                            />
                        </div>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButton
                                id={320}
                                arrow={false}
                                pressed={verticalAlign === VerticalAlignment.top}
                                enabled={true}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.align_vertical_top),
                                    this.context.translate(translations.tooltip.align_vertical_top)
                                )}
                                onToggleButton={() => this.onToolbarAction(WDToolbarAction.VERTICAL_ALIGN, {verticalAlign: VerticalAlignment.top})}
                                icon={ImagePath.getButtonUrl() + "vertical_alignment_top.svg"}
                            />
                            <WDToolbarButton
                                id={321}
                                arrow={false}
                                pressed={verticalAlign === VerticalAlignment.middle}
                                enabled={true}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.align_vertical_middle),
                                    this.context.translate(translations.tooltip.align_vertical_middle)
                                )}
                                onToggleButton={() => this.onToolbarAction(WDToolbarAction.VERTICAL_ALIGN, {verticalAlign: VerticalAlignment.middle})}
                                icon={ImagePath.getButtonUrl() + "vertical_alignment_middle.svg"}
                            />
                            <WDToolbarButton
                                id={322}
                                arrow={false}
                                pressed={verticalAlign === VerticalAlignment.bottom}
                                enabled={true}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.align_vertical_bottom),
                                    this.context.translate(translations.tooltip.align_vertical_bottom)
                                )}
                                onToggleButton={() => this.onToolbarAction(WDToolbarAction.VERTICAL_ALIGN, {verticalAlign: VerticalAlignment.bottom})}
                                icon={ImagePath.getButtonUrl() + "vertical_alignment_bottom.svg"}
                            />
                        </div>
                    </div>

                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonList
                                id={501}
                                clickable={!this.isUsedInTextExerciseChild()}
                                command={"insertUnorderedList"}
                                action={WDToolbarAction.UNORDERED_LIST}
                                options={{
                                    action: WDToolbarAction.UNORDERED_LIST_IMAGE,
                                    open: this.state.activeId === 501,
                                    imageCategory: ImageCategory.UNORDERED_BULLET_LIST,
                                    imagePath: ImagePath.getListStyleUrl(),
                                    width: 215,
                                    height: 150,
                                    itemWidth: 55,
                                    itemHeight: 55
                                }}
                                icon={ImagePath.getButtonUrl() + "bulletpoints.svg"}
                                tooltip={new TooltipText(this.context.translate(translations.toolbar.unordered_list))}
                                onToggleButton={this.onToggleButton}
                                onActionButton={this.onToolbarAction}
                            />
                        </div>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonList
                                id={502}
                                clickable={!this.isUsedInTextExerciseChild()}
                                command={"insertOrderedList"}
                                action={WDToolbarAction.ORDERED_LIST}
                                options={{
                                    action: WDToolbarAction.ORDERED_LIST_IMAGE,
                                    open: this.state.activeId === 502,
                                    imageCategory: ImageCategory.ORDERED_BULLET_LIST,
                                    imagePath: ImagePath.getListStyleUrl(),
                                    width: 215,
                                    height: 150,
                                    itemWidth: 55,
                                    itemHeight: 55
                                }}
                                icon={ImagePath.getButtonUrl() + "numbering.svg"}
                                tooltip={new TooltipText(this.context.translate(translations.toolbar.ordered_list))}
                                onToggleButton={this.onToggleButton}
                                onActionButton={this.onToolbarAction}
                            />
                        </div>
                    </div>

                    {/* Only in admin mode! */}
                    {WSContext.showToolbarButtonMarkSolution(this.props.context) &&
                        <>
                            <WDToolbarButtonSeparator/>

                            <div className={"ws-designer-toolbar-section"}>
                                <WDToolbarButtonSolution
                                    id={602}
                                    clickable={true}
                                    icon={"solution_mark.svg"}
                                    onActionButton={this.onToolbarAction}
                                    action={WDToolbarAction.SOLUTION}
                                />
                            </div>
                        </>
                    }

                    <WDToolbarButtonSeparator/>

                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonSelectImage
                                id={603}
                                width={310}
                                height={210}
                                type={ImageToolbarButtonType.insert}
                                pressed={this.state.activeId === 603}
                                clickable={true}
                                worksheetType={this.props.context}
                                onToggleButton={this.onToggleButton}
                                onActionButton={this.onToolbarAction}
                            />
                        </div>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonConvertToSyllableText
                                id={604}
                                pressed={activateSyllable}
                                clickable={this.props.syllableMethod !== SyllableMethod.noSyllable}
                                onActionButton={this.onToolbarAction}
                                onToggleButton={this.onToggleButton}
                                onShowConfirmation={this.props.onShowConfirmation}
                            />
                        </div>
                    </div>
                </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row tooltip-above"}>
                        <li className={"ws-designer-toolbar-item tooltip-control"}>
                            <label className={"label tooltip-control"} style={{width: 14}}>
                                {this.context.translate(translations.toolbar.width_short)}:
                            </label>
                            <NumberInput width={90}
                                         clickable={this.props.context !== WSContextType.text_exercise_child}
                                         required={false}
                                         maxlength={3}
                                         minValue={1}
                                         maxValue={999}
                                         unit={"mm"}
                                         unitWidth={18}
                                         value={width}
                                         onChangeValue={value => this.onChangeCellWidth(width || 0, height || 0, value)}/>
                        </li>
                        <Tooltips
                            text={new TooltipText(
                                this.context.translate(translations.toolbar.column_width),
                                this.context.translate(translations.tooltip.column_width)
                            )}
                            translateX={-30} translateY={-38}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row tooltip-above"}>
                        <li className={"ws-designer-toolbar-item tooltip-control"}>
                            <label className={"label tooltip-control"} style={{width: 14}}>
                                {this.context.translate(translations.toolbar.height_short)}:
                            </label>
                            <NumberInput width={90}
                                         clickable={this.props.context !== WSContextType.text_exercise_child}
                                         required={false}
                                         maxlength={3}
                                         minValue={1}
                                         maxValue={999}
                                         unit={"mm"}
                                         unitWidth={18}
                                         value={height}
                                         onChangeValue={value => this.onChangeCellHeight(width || 0, height || 0, value)}/>
                        </li>
                        <Tooltips
                            text={new TooltipText(
                                this.context.translate(translations.toolbar.row_height),
                                this.context.translate(translations.tooltip.row_height)
                            )}
                            translateX={-30} translateY={-38}
                        />
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <WDToolbarLinkBracket
                        readonly={true}
                        linked={false}
                        linkBracketInfo={new LinkBracketInfo(true, true, false, false)}
                        onClickLink={this.onToggleLink}/>
                </div>

                <div className={"ws-designer-toolbar-button-separator"}/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonInsertColumn
                            id={1}
                            pressed={false}
                            clickable={true}
                            position={WDColumnPosition.LEFT}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonInsertRow
                            id={2}
                            pressed={false}
                            clickable={true}
                            position={WDRowPosition.ABOVE}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>

                        <WDToolbarButtonInsertColumn
                            id={3}
                            pressed={false}
                            clickable={true}
                            position={WDColumnPosition.RIGHT}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonInsertRow
                            id={4}
                            pressed={false}
                            clickable={true}
                            position={WDRowPosition.BELOW}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonDeleteColumn
                            id={5}
                            pressed={false}
                            clickable={deleteColumnEnabled}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonDeleteRow
                            id={6}
                            pressed={false}
                            clickable={deleteRowEnabled}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}/>
                    </div>

                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <li
                            key={"ws-designer-toolbar-button-connect_cells"}
                            id={"connect_cells"}>

                            <WDToolbarButton
                                id={7}
                                pressed={false}
                                enabled={selected.x > 1 || selected.y > 1}
                                onToggleButton={() => this.onToolbarAction(WDToolbarAction.CONNECT_CELLS)}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.connect_cells),
                                    this.context.translate(translations.tooltip.connect_cells)
                                )}
                                icon={ImagePath.getButtonUrl() + "connect_cells.svg"}
                                arrow={false}
                            />
                        </li>
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <li
                            key={"ws-designer-toolbar-button-disconnect_cells"}
                            id={"disconnect_cells"}>

                            <WDToolbarButton
                                id={8}
                                pressed={false}
                                enabled={selected.x > 0 || selected.y > 0}
                                onToggleButton={() => this.onToolbarAction(WDToolbarAction.DISCONNECT_CELLS)}
                                tooltip={new TooltipText(
                                    this.context.translate(translations.toolbar.disconnect_cells),
                                    this.context.translate(translations.tooltip.disconnect_cells)
                                )}
                                icon={ImagePath.getButtonUrl() + "disconnect_cells.svg"}
                                arrow={false}
                            />
                        </li>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <li
                            key={"ws-designer-toolbar-button-distribute-columns"}
                            id={"distribute-columns"}>

                            <WDToolbarButton id={9}
                                             icon={ImagePath.getButtonUrl() + "distribute_table_cols.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.distribute_columns),
                                                 this.context.translate(translations.tooltip.distribute_columns)
                                             )}
                                             onToggleButton={() => this.onToolbarAction(WDToolbarAction.DISTRIBUTE_COLUMNS)}
                                             arrow={false}
                                             enabled={true}
                                             pressed={false}
                            />
                        </li>
                    </div>

                    <div className={"ws-designer-toolbar-row"}>
                        <li
                            key={"ws-designer-toolbar-button-distribute-columns"}
                            id={"distribute-columns"}>

                            <WDToolbarButton id={10}
                                             icon={ImagePath.getButtonUrl() + "distribute_table_rows.svg"}
                                             tooltip={new TooltipText(
                                                 this.context.translate(translations.toolbar.distribute_rows),
                                                 this.context.translate(translations.tooltip.distribute_rows)
                                             )}
                                             onToggleButton={() => this.onToolbarAction(WDToolbarAction.DISTRIBUTE_ROWS)}
                                             arrow={false}
                                             enabled={true}
                                             pressed={false}
                            />
                        </li>
                    </div>
                </div>

                <div className={"ws-designer-toolbar-button-separator"}/>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonCellBorder
                            id={102}
                            borderKey={this.state.borderType}
                            open={this.state.activeId === 102}
                            width={235}
                            height={80}
                            clickable={true}
                            pressed={isCellBorderPressed}
                            onActionButton={this.onBorderTypeChange}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonColor
                            id={103}
                            clickable={true}
                            command={"backColor"}
                            options={{
                                action: WDToolbarAction.FILL_COLOR,
                                open: this.state.activeId === 103,
                                width: 70,
                                height: 150
                            }}
                            tooltipPosition={TooltipPosition.above}
                            showColor={true}
                            transparency={true}
                            pressed={false}
                            initialColor={background}
                            action={WDToolbarAction.FILL_COLOR}
                            icon={ImagePath.getButtonUrl() + "fill_color.svg"}
                            tooltip={new TooltipText(
                                this.context.translate(translations.toolbar.fillColor),
                                this.context.translate(translations.tooltip.fill_color))
                            }
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={false}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onElementDeleted={this.props.onElementDeleted}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />
            </ul>
            }
        </WDToolbar>
    }
}
