import React from 'react';
import translations from "../../../Framework/translations.json";
import SelectBox from "../../../Components/Controls/SelectBox";
import TextBox from "../../../Components/Controls/TextBox";
import {
    EnumToEntityArray,
    EnumToEntityArrayWithoutTranslate,
    EnumValueToValueNumber,
} from "../../../Framework/Enums";
import {MainContext} from "../../../_base/MainContext";
import Noun, {
    ArticleDefiniteEnum,
    ArticleIndefiniteEnum,
    CaseFirstEnum,
    CaseFirstIndefiniteEnum,
    CaseFourthEnum,
    CaseFourthIndefiniteEnum,
    CaseSecondEnum,
    CaseSecondIndefiniteEnum,
    CaseThirdEnum,
    CaseThirdIndefiniteEnum,
    CharacteristicEnum,
    GenderEnum
} from "../../../_model/Dictionary/Noun";
import {WordType, WordTypeProps, WordTypeState} from "../WordType";
import {
    CreateNoun,
    GetNoun,
    GetPossessivePronouns,
    UpdateNoun
} from "../../../_endpoint/WordEndpoint";
import PossessivePronouns, {CaseEnum, PersonEnum} from "../../../_model/Dictionary/PossessivePronouns";
import Word from "../../../_model/Dictionary/Word";
import {EntityData} from "../../../_model/Entity";

export interface IProps extends WordTypeProps {
    wordId?: number
    readonly: boolean
}

interface IState extends WordTypeState {
    item: Noun
    unsavedChanges: boolean
    possessives: PossessivePronouns[]
}

export default class NounFormPart extends WordType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Noun(),
            unsavedChanges: false,
            possessives: []
        }
    }

    componentDidMount() {
        if(this.props.wordId) {
            this.fetchData()
        }
    }

    fetchData = () => {
        if(this.props.wordId) {
            GetNoun(this.props.wordId).then(
                (itemData) => {
                    this.setState({item: itemData}, () => {
                        this.fetchPossessivePronoun()
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }
    fetchPossessivePronoun = (gender?: GenderEnum) => {
        if(gender || this.state.item.gender) {
            let genderForSearch = gender ? gender : this.state.item.gender!

            GetPossessivePronouns(genderForSearch).then(
                (data) => {
                    this.setState({possessives: data})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                }
            )
        }
    }

    isFormValid = () => {
        const form1 = document.getElementById("formData1")  as HTMLFormElement;
        return form1 && form1.reportValidity()
    }

    // close and success messages are done in word
    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        if (this.isFormValid()) {
            let result

            if (word.id) {
                result = await UpdateNoun(this.state.item, word)
            } else {
                result = await CreateNoun(this.state.item, word)
            }

            this.setState({unsavedChanges: false})
            return result
        }
    }

    onChangeEnum = (value: number, enumType: any, itemProperty: string) => {
        // Get enum value by index (value)
        let enumValue = Object.values(enumType).find((e, i) => i === value)
        if (enumValue) {
            let currentItem = this.state.item
            currentItem[itemProperty] = enumValue
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeGender = (value: number) => {
        let enumValue = Object.values(GenderEnum).find((e, i) => i === value)
        if (enumValue) {
            this.fetchPossessivePronoun(enumValue as GenderEnum)
            let currentItem = this.state.item
            currentItem.gender = enumValue

            switch (enumValue) {
                case GenderEnum.male:
                    currentItem.articleDefinite = ArticleDefiniteEnum.der
                    currentItem.articleIndefinite = ArticleIndefiniteEnum.ein
                    currentItem.caseFirst = CaseFirstEnum.der
                    currentItem.caseFirstIndefinite = CaseFirstIndefiniteEnum.ein
                    currentItem.caseSecond = CaseSecondEnum.des
                    currentItem.caseSecondIndefinite = CaseSecondIndefiniteEnum.eines
                    currentItem.caseThird = CaseThirdEnum.dem
                    currentItem.caseThirdIndefinite = CaseThirdIndefiniteEnum.einem
                    currentItem.caseFourth = CaseFourthEnum.den
                    currentItem.caseFourthIndefinite = CaseFourthIndefiniteEnum.einen
                    break

                case GenderEnum.female:
                    currentItem.articleDefinite = ArticleDefiniteEnum.die
                    currentItem.articleIndefinite = ArticleIndefiniteEnum.eine
                    currentItem.caseFirst = CaseFirstEnum.die
                    currentItem.caseFirstIndefinite = CaseFirstIndefiniteEnum.eine
                    currentItem.caseSecond = CaseSecondEnum.der
                    currentItem.caseSecondIndefinite = CaseSecondIndefiniteEnum.einer
                    currentItem.caseThird = CaseThirdEnum.der
                    currentItem.caseThirdIndefinite = CaseThirdIndefiniteEnum.einer
                    currentItem.caseFourth = CaseFourthEnum.die
                    currentItem.caseFourthIndefinite = CaseFourthIndefiniteEnum.eine
                    break

                case GenderEnum.neuter:
                    currentItem.articleDefinite = ArticleDefiniteEnum.das
                    currentItem.articleIndefinite = ArticleIndefiniteEnum.ein
                    currentItem.caseFirst = CaseFirstEnum.das
                    currentItem.caseFirstIndefinite = CaseFirstIndefiniteEnum.ein
                    currentItem.caseSecond = CaseSecondEnum.des
                    currentItem.caseSecondIndefinite = CaseSecondIndefiniteEnum.eines
                    currentItem.caseThird = CaseThirdEnum.dem
                    currentItem.caseThirdIndefinite = CaseThirdIndefiniteEnum.einem
                    currentItem.caseFourth = CaseFourthEnum.das
                    currentItem.caseFourthIndefinite = CaseFourthIndefiniteEnum.ein
                    break
            }

            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeCheckbox = (fieldName: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = !currentItem[fieldName]
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeText = (fieldName: string, value: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }

    render() {
        return <>
            <div className={"form-row"}>
                <TextBox id={"txtSyllabificationSingular"}
                         label={this.context.translate(translations.fields.word.noun.syllabificationSingular)}
                         width={400}
                         required={false}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabificationSingular", text)}
                         value={this.state.item.syllabificationSingular || ""}
                />

                <TextBox id={"txtSyllabificationPlural"}
                         width={400}
                         label={this.context.translate(translations.fields.word.noun.syllabificationPlural)}
                         required={false}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabificationPlural", text)}
                         value={this.state.item.syllabificationPlural || ""}
                />
            </div>
            <div className="form-row">
                <SelectBox id={"selCharacteristic"}
                           width={395}
                           label={this.context.translate(translations.fields.word.noun.characteristic)}
                           data={EnumToEntityArray(CharacteristicEnum, translations.enum.characteristic, this.context.translate)}
                           required={true}
                           value={EnumValueToValueNumber(CharacteristicEnum, this.state.item.characteristic)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeEnum(value, CharacteristicEnum, "characteristic")}
                           positioningRow={false}
                />
                <SelectBox id={"selGender"}
                           width={395}
                           label={this.context.translate(translations.fields.word.noun.gender)}
                           data={EnumToEntityArray(GenderEnum, translations.enum.noun_gender, this.context.translate)}
                           required={true}
                           value={EnumValueToValueNumber(GenderEnum, this.state.item.gender)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeGender(value)}
                           positioningRow={false}
                />
            </div>
            <div className="form-row">
                <SelectBox id={"selArticleDefinite"}
                           label={this.context.translate(translations.fields.word.noun.article_definite)}
                           width={395}
                           data={EnumToEntityArrayWithoutTranslate(ArticleDefiniteEnum)}
                           required={true}
                           value={EnumValueToValueNumber(ArticleDefiniteEnum, this.state.item.articleDefinite)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeEnum(value, ArticleDefiniteEnum, "articleDefinite")}
                           positioningRow={false}
                />

                <SelectBox id={"selArticleIndefinite"}
                           width={395}
                           label={this.context.translate(translations.fields.word.noun.article_indefinite)}
                           data={EnumToEntityArrayWithoutTranslate(ArticleIndefiniteEnum)}
                           required={true}
                           value={EnumValueToValueNumber(ArticleIndefiniteEnum, this.state.item.articleIndefinite)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeEnum(value, ArticleIndefiniteEnum, "articleIndefinite")}
                           positioningRow={false}
                />
            </div>

            {/* Cases */}

            <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.noun.cases)}</div>
            <div className="form-row">
                    <div className={"form-column"}>
                        <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.first_case)}</div>

                        <div className={"form-row"}>
                            <SelectBox id={"selCaseFirst"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseFirstEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseFirstEnum, this.state.item.caseFirst)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseFirstEnum, "caseFirst")}
                                       positioningRow={false}
                            />

                            <SelectBox id={"selCaseFirstIndefinite"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseFirstIndefiniteEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseFirstIndefiniteEnum, this.state.item.caseFirstIndefinite)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseFirstIndefiniteEnum, "caseFirstIndefinite")}
                                       positioningRow={false}
                            />
                        </div>

                        <TextBox id={"txtCaseFirstValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFirstValue", text)}
                                 value={this.state.item.caseFirstValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.second_case)}</div>

                        <div className={"form-row"}>
                            <SelectBox id={"selCaseSecond"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseSecondEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseSecondEnum, this.state.item.caseSecond)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseSecondEnum, "caseSecond")}
                                       positioningRow={false}
                            />

                            <SelectBox id={"selCaseSecondIndefinite"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseSecondIndefiniteEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseSecondIndefiniteEnum, this.state.item.caseSecondIndefinite)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseSecondIndefiniteEnum, "caseSecondIndefinite")}
                                       positioningRow={false}
                            />
                        </div>

                        <TextBox id={"txtCaseSecondValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseSecondValue", text)}
                                 value={this.state.item.caseSecondValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.third_case)}</div>

                        <div className={"form-row"}>
                            <SelectBox id={"selCaseThird"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseThirdEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseThirdEnum, this.state.item.caseThird)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseThirdEnum, "caseThird")}
                                       positioningRow={false}
                            />

                            <SelectBox id={"selCaseThirdIndefinite"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseThirdIndefiniteEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseThirdIndefiniteEnum, this.state.item.caseThirdIndefinite)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseThirdIndefiniteEnum, "caseThirdIndefinite")}
                                       positioningRow={false}
                            />
                        </div>

                        <TextBox id={"txtCaseThirdValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseThirdValue", text)}
                                 value={this.state.item.caseThirdValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.fourth_case)}</div>

                        <div className={"form-row"}>
                            <SelectBox id={"selCaseFourth"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseFourthEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseFourthEnum, this.state.item.caseFourth)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseFourthEnum, "caseFourth")}
                                       positioningRow={false}
                            />

                            <SelectBox id={"selCaseFourthIndefinite"}
                                       width={93}
                                       data={EnumToEntityArrayWithoutTranslate(CaseFourthIndefiniteEnum)}
                                       required={false}
                                       value={EnumValueToValueNumber(CaseFourthIndefiniteEnum, this.state.item.caseFourthIndefinite)}
                                       readonly={this.props.readonly}
                                       onChange={(value) => this.onChangeEnum(value, CaseFourthIndefiniteEnum, "caseFourthIndefinite")}
                                       positioningRow={false}
                            />
                        </div>

                        <TextBox id={"txtCaseFourthValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFourthValue", text)}
                                 value={this.state.item.caseFourthValue || ""}
                        />
                    </div>
                </div>

            <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.plural)}</div>
            <div className="form-row">
                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>die</div>
                        <TextBox id={"txtCaseFirstValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFirstValuePL", text)}
                                 value={this.state.item.caseFirstValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>der</div>
                        <TextBox id={"txtCaseSecondValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseSecondValuePL", text)}
                                 value={this.state.item.caseSecondValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>den</div>
                        <TextBox id={"txtCaseThirdValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseThirdValuePL", text)}
                                 value={this.state.item.caseThirdValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>die</div>
                        <TextBox id={"txtCaseFourthValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFourthValuePL", text)}
                                 value={this.state.item.caseFourthValuePL || ""}
                        />
                    </div>
                </div>

            {this.state.item.gender &&
                <>
                {/* Possessive Pronoun */}

                <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.noun.possessive)}</div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === false && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValue", text)}
                                 value={this.state.item.caseFirstPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === false && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValue", text)}
                                 value={this.state.item.caseSecondPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === false && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValue", text)}
                                 value={this.state.item.caseThirdPossessiveValue || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === false && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValue"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValue", text)}
                                 value={this.state.item.caseFourthPossessiveValue || ""}
                        />
                    </div>

                </div>

                {/* Possessive Pronoun Plural */}

                <div className={"sub-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.noun.plural)}</div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.ich})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={this.props.readonly}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.du})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.er})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.sie})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.es})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.wir})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.ihr})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                <div className="form-row">

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.first && value.plural === true && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFirstPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFirstPossessiveValuePL", text)}
                                 value={this.state.item.caseFirstPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.second && value.plural === true && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseSecondPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseSecondPossessiveValuePL", text)}
                                 value={this.state.item.caseSecondPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.third && value.plural === true && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseThirdPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseThirdPossessiveValuePL", text)}
                                 value={this.state.item.caseThirdPossessiveValuePL || ""}
                        />
                    </div>

                    <div className={"form-column"}>
                        <div className="admin-form-text" style={{width: "200px"}}>{this.state.possessives.find(value => {
                            return value.casePos === CaseEnum.fourth && value.plural === true && value.person === PersonEnum.sie_pl})?.possessiveValue}
                        </div>
                        <TextBox id={"txtCaseFourthPosValuePL"}
                                 width={200}
                                 required={false}
                                 readonly={true}
                                 onChange={(text: string) => this.onChangeText("caseFourthPossessiveValuePL", text)}
                                 value={this.state.item.caseFourthPossessiveValuePL || ""}
                        />
                    </div>

                </div>
                </>
            }
        </>
    }
}

