import Entity from "../_model/Entity";
import {Resource} from "./Util";
import {ListFilterDefinitionItem} from "../Components/List/ListFilter";

export enum RenderingMedia {
    screen,
    print
}

export enum ProductLicense {
    employee = 1,
    smart = 2
}

export enum Gender {
    male = "male",
    female = "female",
}

export enum Status {
    draft = "draft",
    published = "published",
    approval = "approval",
    deactivated = "deactivated"
}

export enum Color {
    black = "black",
    blue = "blue",
    brown = "brown",
    green = "green",
    grey = "grey",
    orange = "orange",
    pink = "pink",
    purple = "purple",
    red = "red",
    white = "white",
    yellow = "yellow",
}

export enum ElementPosition {
    left = "left",
    right = "right",
    above = "above",
    below = "below",
    belowRight = "below-right",
    belowLeft = "below-left"
}

export enum ColumnRenderOption {
    Text,
    Thumbnail,
    Date,
    DateTime,
    Status,
    Icon,
    MarketplaceStatus,
    Rating
}

export enum WDTableDimensionMode {
    ROW,
    COLUMN
}

export enum WSStatus {
    active = "active",
    paperbin = "paperbin",
    loading = "loading"
}

export enum WSMarketplaceStatus {
    approval = "approval",
    published = "published",
    updated = "updated",
    downloaded = "downloaded",
    downloaded_locked = "downloaded_locked",
    rejected = "rejected"
}

export enum WSPageFormat {
    A4 = "A4",
    A5 = "A5",
    A3 = "A3"
}

export enum ListStyle {
    list = "list",
    card = "card"
}

export enum NotificationStatus {
    notShow = "notShow",
    ok = "ok",
    error = "error",
    info = "info",
    loading = "loading"
}

export enum Difficulty {
    none = "none",
    easy = "easy",
    medium = "medium",
    hard = "hard"
}

export enum ExerciseType {
    normal = "normal",
    text_exercise = "text_exercise",
    exercise_pattern = "exercise_pattern"
}

export enum ExerciseCalculationType {
    none = "none",
    add = "add",
    subtract = "subtract",
    add_subtract = "add_subtract",
    multiply = "multiply",
    divide = "divide",
    multiply_divide = "multiply_divide",
    mixed = "mixed"
}

export enum ExerciseTextType {
    report = "report",
    letter = "letter",
    protocol = "protocol",
    commentary = "commentary",
    characteristics = "characteristics",
    opinion = "opinion",
    process_description = "process_description",
    essay = "essay",
    description = "description",
    speech = "speech"
}

export enum ExerciseEpicType {
    anecdote = "anecdote",
    fable = "fable",
    short_story = "short_story",
    legend = "legend",
    fairy_tale = "fairy_tale",
    novella = "novella",
    parabola = "parabola",
    novel = "novel",
    saga = "saga",
    satire = "satire"
}

export enum UserSettingsAutoSave {
    showDialog= "showDialog",
    alwaysSave = "alwaysSave",
    neverSave = "neverSave"
}

// All objects and helper in admin area regardless if presented to the user
export enum AdminObjectType {
    worksheet = "worksheet",
    worksheet_log = "worksheet_log",
    name = "name",
    country = "country",
    rule = "rule",
    sales_material = "sales_material",
    exercise = "exercise",
    exercise_pattern = "exercise_pattern",
    text_exercise_main = "text_exercise_main",
    text_exercise_child = "text_exercise_child",
    text_exercise = "text_exercise",
    writing_course_main = "writing_course_main",
    writing_course_child = "writing_course_child",
    writing_course = "writing_course",
    image = "image",
    word = "word",
    approval_item = "approval_item",
    search_result = "search_result",
    marketplace_worksheet = "marketplace_worksheet",
    marketplace_worksheet_admin = "marketplace_worksheet_admin"

}

// Content types created in backend for a user (Logical Object Type)
export enum AdminContentType {
    image = "image",
    rule = "rule",
    exercise = "exercise",
    text_exercise = "text_exercise",
    name = "name",
    country = "country",
    word = "word",
    writing_course = "writing_course"
}

export enum VerticalAlignment {
    top = "top",
    middle = "middle",
    bottom = "bottom"
}

export enum ManualBorderMode {
    Off,
    Border,
    Erase
}

export enum PrintSolutionMode {
    NoSolutions,
    SolutionSheet,
    AutomaticSolutions
}
export enum SolutionForceMode {
    Off,
    ForceShow,
    ForceHide
}

export function EnumToEntityArray(enumEntity: { [s: string]: string }, translationPath: any, translate:(r: Resource) => string, sortByValue: boolean = false): Entity[] {
    let entities: Entity[] = []
    let values = Object.values(enumEntity)

    values.forEach((value, i) => {
        // Get entry at path translationPath with id value
        if (translationPath[value]) {
            entities.push(new Entity(translate(translationPath[value]), i))
        }
    })

    if(sortByValue) {
        entities.sort((first, second) => (first.name < second.name ? -1 : 1))
    }

    return entities
}
export function EnumToEntityArrayWithoutTranslate(enumEntity: { [s: string]: string }): Entity[] {
    let entities: Entity[] = []
    let values = Object.values(enumEntity)

    values.forEach((value, i) => {
        let entity = new Entity(value, i)
        entities.push(entity)
    })

    return entities
}

export function NumEnumToEntity(enumEntity: { [s: string]: string }): Entity[] {
    let entities: Entity[] = []
    let values = Object.values(enumEntity)

    values.forEach((value) => {
        let entity = new Entity(value, +value)
        entities.push(entity)
    })

    return entities
}

export function EnumValueToValueNumber(enumEntity: { [s: string]: string }, entity?: string): number | undefined {
    let number: number | undefined = undefined

    if(entity) {
        Object.values(enumEntity).forEach((value, i) => {
            if(value === entity) {
                return number = i
            }
        })
        Object.keys(enumEntity).forEach((value, i) => {
            if(value === entity) {
                return number = i
            }
        })
    }
    return number
}
export function EnumValueToEntity(enumEntity: { [s: string]: string }, value: string, translationPath: any, translate:(r: Resource) => string): Entity {
    for (const item of Object.values(enumEntity)) {
        const i = Object.values(enumEntity).indexOf(item);
        if(item === value) {
            return new Entity(translate(translationPath[value]), i);
        }
    }

    throw new Error("Enum value '" + value + "' not found")
}
export function EnumValuesToEntityArray(enumEntity: { [s: string]: string }, values: string[], translationPath: any, translate:(r: Resource) => string): Entity[] {
    let result: Entity[] = []
    values?.forEach(value => {
        result.push(EnumValueToEntity(enumEntity, value, translationPath, translate))
    })
    return result
}
export function EntityArrayToEnumValues(values: Entity[], enumEntity: { [s: string]: string }): any[] {
    let enumValues: any[] = []
    values.forEach(item => {
        let enumEntry = Object.values(enumEntity).find((e, i) => i === item.id)
        if (enumEntry) {
            enumValues.push(enumEntry)
        }
    })
    return enumValues
}
export function EntityToEnumValue(value: Entity, enumEntity: { [s: string]: string }): any {
    return Object.values(enumEntity).find((e, i) => i === value.id)
}
export function EnumValueToIndex(value: string, enumEntity: { [s: string]: string }): any {
    return Object.values(enumEntity).indexOf(value)
}
export function NameValueToEnumKey(value: string, enumEntity: { [s: string]: string }): any {
    return Object.keys(enumEntity)[Object.values(enumEntity).indexOf(value)]
}
export function NameKeyToEnumValue(value: string, enumEntity: { [s: string]: string }): any {
    return Object.values(enumEntity)[Object.keys(enumEntity).indexOf(value)]
}
export function EnumToListFilterDefinitionItem(enumEntity: { [s: string]: string }, values: string[], translationPath: any, translate:(r: Resource) => string): ListFilterDefinitionItem[] {
    let listFilterDefinitionItem: ListFilterDefinitionItem[] = []
    let i = 0
    for(let value of values) {
        listFilterDefinitionItem.push(new ListFilterDefinitionItem(i,translate(translationPath[value])))
        i++
    }
    return listFilterDefinitionItem
}
