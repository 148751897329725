import React from "react";
import translations from "../../Framework/translations.json";
import {MainContext} from "../../_base/MainContext";
import {Hint, NotificationData} from "../Notification/Hint";
import {NotificationStatus, Status} from "../../Framework/Enums";
import Conf from "../../Framework/Conf";
import {Util} from "../../Framework/Util";
import {ImagePath} from "../../Framework/CategoryImage";
import Image from "../../_model/Image";

interface IProps {
    items?: Image[]
    itemHeight: number
    itemWidth?: number
    highlightImages?: Image[]
    galleryTitle?: string
    galleryHeight?: string
    imageFieldAsImageTitle?: string
    imageTitleHoverEffect?: boolean
    imageClass?: string
    imageColorClass?: string

    onFetchNextPage: (callback?: (page: number, lastPage: boolean) => void) => void
    onClickImage: (image: Image) => void
    onDragImage?: (event: React.DragEvent, image: Image) => void
}
interface IState {
    page: number
    lastItem: boolean
}

export class ImageGallery extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // Prevents loading multiple pages
    loading: boolean = false

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            page: 0,
            lastItem: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.items?.length !== prevProps.items?.length) {
            this.setState({lastItem: false})
        }
    }

    onScrollContent = (e: React.UIEvent<HTMLDivElement>) => {
        let element = e.target as HTMLDivElement

        if (element.scrollTop + 10 >= (element.scrollHeight - element.offsetHeight) && !this.loading) {
            this.context.log.info("Loading page: " + (this.state.page + 1))
            this.context.log.flush()

            this.loading = true
            this.props.onFetchNextPage(this.onFinish)
        }
    }
    onFinish = (page: number, lastPage: boolean) => {
        this.context.log.info("Finished loading page: " + page)
        this.context.log.flush()

        this.loading = false

        this.setState({page: page, lastItem: lastPage})
    }

    render() {
        return <div className={"ws-designer-images-gallery"} style={{height: this.props.galleryHeight}} onScroll={this.onScrollContent}>

            {this.props.galleryTitle &&
                <div className={"form-group"} style={{marginBottom: 0}}>
                    <div className={"label-bold"}> {this.props.galleryTitle} </div>
                </div>
            }

                <div className={"ws-designer-images-gallery-list"}>
                    {this.props.items === undefined &&
                    <div className={"ws-designer-images-gallery-list-empty"}>
                        <Hint id={"sidebar-image-loading"}
                              notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                    </div>
                    }

                    {this.props.items && this.props.items.length === 0 &&
                    <div className={"ws-designer-images-gallery-list-empty"}>
                        <Hint id={"sidebar-image-noresult"}
                              notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                    </div>
                    }

                    {this.props.items && this.props.items.length > 0 && this.props.items.map(item => {
                        let itemClass = "ws-designer-images-gallery-list-item-image"
                        if (item.status !== Status.published) {
                            itemClass += " ws-designer-images-gallery-list-item-disabled"
                        }
                        if (this.props.highlightImages && this.props.highlightImages.includes(item)) {
                            itemClass += " ws-designer-images-gallery-list-item-image-selected"
                        }
                        if (this.props.imageClass) {
                            itemClass += " " + this.props.imageClass
                        }
                        if (this.props.imageColorClass && item.colorable) {
                            itemClass += " " + this.props.imageColorClass
                        }

                        let imageWidth = this.props.itemWidth
                            ? this.props.itemWidth
                            : Util.calculateProportionalImageThumbWidth(item, this.props.itemHeight)

                        let imageGalleryClass = "ws-designer-images-gallery-list-item"
                        if(this.props.imageFieldAsImageTitle && this.props.imageTitleHoverEffect) {
                            imageGalleryClass += " ws-designer-images-gallery-list-item-image-name-container-hover"
                        }

                        return <div key={item.id} className={imageGalleryClass}
                                    onDragStart={event => this.props.onDragImage?.(event, item)}
                                    onClick={() => this.props.onClickImage(item)}>
                            <img id={"sidebar-image-" + item.id!.toString()}
                                 src={Conf.IMAGE_URL() + Image.getThumbnailUrl(item)}
                                 className={itemClass}
                                 alt={item.name}
                                 style={{
                                     height: this.props.itemHeight,
                                     width: imageWidth
                                 }}
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                            {item.licenseRequired &&
                                <img src={process.env.PUBLIC_URL + ImagePath.getDecorationUrl() + "flag_premium.svg"}
                                     className={"ws-designer-images-gallery-list-item-license"} alt={""}
                                     draggable={"false"}
                                     onContextMenu={(e) => e.preventDefault() }
                                />
                            }
                            {this.props.imageFieldAsImageTitle &&
                                <div className={"ws-designer-images-gallery-list-item-image-name-container"} style={{width: imageWidth}}>
                                    <div className={"ws-designer-images-gallery-list-item-image-name"}>
                                        {item[this.props.imageFieldAsImageTitle]}
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div>
            </div>
    }
}

