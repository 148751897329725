import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import translations from "../../Framework/translations.json";
import TextBox from "../../Components/Controls/TextBox";
import {AdminObjectType, Status} from "../../Framework/Enums";
import {GetAllLanguages} from "../../_endpoint/MetadataEndpoint";
import Language from "../../_model/Language";
import DualList from "../../Components/Controls/DualList";
import Country from "../../_model/Country";
import {CreateCountry, GetCountry, UpdateCountry} from "../../_endpoint/CountryEndpoint";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import {AdminUtils} from "../Maintenance/AdminUtils";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

interface IState {
    item: Country
    languages: any[]
    selected: any[]

    unsavedChanges: boolean
}

export default class CountryForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Country("", Status.draft),
            languages: [],
            selected: [],
            unsavedChanges: false
        }
    }

    componentDidMount() {
        if(this.props.match.params.id !== Const.CreateNewDataUrl) {
            this.fetchData()
        }
        this.fetchLanguages()
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        GetCountry(+this.props.match.params.id).then(
            (itemData) => {
                if(itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                this.setState({
                    item: itemData,
                    selected: itemData.languages ? itemData.languages.map(language => {return language.id}) : []
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchLanguages = () => {
        GetAllLanguages().then(
            (data) => {
                let languageNames = data.map(language => {return {value: language.id, label: language.name}})
                this.setState( {
                    languages: languageNames
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    isFormValid = () => {
        const form1 = document.getElementById("formData1")  as HTMLFormElement;
        return form1 && form1.reportValidity()
    }

    onSave = async (close: boolean) => {

        if (this.isFormValid()) {

            //Set selection as languages for item
            if(this.state.selected) {
                let saveItem = this.state.item
                saveItem.languages = this.state.selected.map(languageId => {return new Language("", languageId)})
                this.setState({item: saveItem})
            }

            try {
                if (this.state.item.id) {
                    UpdateCountry(this.state.item).then(_ => {
                        this.setState({unsavedChanges: false}, () => {
                            if (close) { this.onCancel()}
                        })
                    },
                    (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
                    })
                } else {
                    let result = await CreateCountry(this.state.item)
                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) { this.onCancel() }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || AdminUtils.getObjectTypeUrl(AdminObjectType.country))
        )
    }
    onSetState = (status: Status, user?: User) => {
        if(this.state.item) {
            const item = this.state.item
            Country.setStatus(item, status, user)

            this.setState({item: item}, () => this.onSave(false))
            return true
        }
        return false
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }
    onChangeName = (value: string) => {
        let currentItem = this.state.item
        currentItem.name = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeInitial = (value: string) => {
        let currentItem = this.state.item
        currentItem.isoCode = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeAreaCode = (value: string) => {
        let currentItem = this.state.item
        currentItem.phoneCode = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeLanguages = (selected) => {
        this.setState({ selected , unsavedChanges: true});
    }

    getAuthor = () => {
        if (this.state.item.createdBy) {
            return this.state.item.createdBy?.firstname + " " + this.state.item.createdBy?.lastname
        }
        return ""
    }
    goToRecord = (index: number) => {

    }

    render() {
        return <AdminDataForm
            onSave={this.onSave}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={AdminObjectType.country}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

            <div className={"admin-form-content"}>

                <form id={"formData1"} className="admin-form-input" onSubmit={() => { return false} }>

                    <FormHeader
                        number={this.state.item.id}
                        status={this.state.item.status}
                        statusTranslationPath={translations.enum.status}
                        createdBy={this.state.item.createdBy}
                        ownerId={this.state.item.ownerId}
                    />

                    <div className="form-row">
                        <TextBox id={"txtName"}
                                 width={1000}
                                 label={this.context.translate(translations.fields.name)}
                                 required={true}
                                 readonly={this.isReadonly()}
                                 onChange={this.onChangeName}
                                 value={this.state.item.name}
                        />
                    </div>

                    <div className="form-row">
                        <TextBox id={"txtIsoCode"}
                                 width={500}
                                 label={this.context.translate(translations.fields.country.isoCode)}
                                 required={true}
                                 readonly={this.isReadonly()}
                                 onChange={this.onChangeInitial}
                                 value={this.state.item.isoCode || ""}
                        />
                        <TextBox id={"txtPhoneCode"}
                                 width={500}
                                 label={this.context.translate(translations.fields.country.phoneCode)}
                                 required={false}
                                 readonly={this.isReadonly()}
                                 onChange={this.onChangeAreaCode}
                                 value={this.state.item.phoneCode || ""}
                        />
                    </div>
                </form>

                <DualList
                    options={this.state.languages}
                    selected={this.state.selected}
                    width={1000}
                    marginTop={30}
                    marginLeft={0}
                    disabled={this.isReadonly()}
                    label={this.context.translate(translations.fields.country.languageForCountry)}
                    onChange={this.onChangeLanguages}
                />

            </div>
        </AdminDataForm>
    }
}

