import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {ImagePath} from "../../../Framework/CategoryImage";
import translations from "../../../Framework/translations.json";
import {ElementProps} from "../Toolbar/WDToolbarElement";
import {WDToolbarButtonColor} from "../Button/General/WDToolbarButtonColor";
import {WDToolbarAction} from "../WDToolbarAction";
import {WDToolbarState} from "../Toolbar/WDToolbar";
import {WDToolbarButtonSeparator} from "../Button/General/WDToolbarButtonSeparator";
import {WDToolbarTypeEnum} from "../Toolbar/WDToolbarTabs";
import {WDToolbarButtonBorder} from "../Button/General/WDToolbarButtonBorder";
import {ElementBorder} from "../../Elements/WDElementContainer";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "../../Utils/WorksheetItemUpdate";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";

export interface IProps {
    activeId: number
    elementProps: ElementProps
    isReadonly: boolean
    showBorderPadding: boolean

    onUpdateSelectedElements: (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => void
    onToolbarAction: (action: WDToolbarAction, data?: Text) => void
    onChangeActive: () => void
    onChangeBorder: (border: ElementBorder) => void
}

export interface IState extends WDToolbarState {
}

export class WDToolbarSectionObjectColor extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        // If submenu outside is opened, close all submenus of general
        if (this.props.activeId !== prevProps.activeId && this.props.activeId > 0) {
            this.setState({activeId: -1})
        }
    }

    onToggleButton = (id: number) => {
        this.setState({activeId: id}, () => this.props.onChangeActive())
    }

    onChangeFillColor = (color: string) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            fillColor: color
        }), {
            historyAction: WDHistoryAction.FILL_COLOR,
            actionCategory: WDActionLogCategory.background,
            applyRecursive: false,
            applyToChildren: true
        })
        this.setState({activeId: -1})
    }
    onChangeTransparency = (transparency: number) => {
        this.props.onUpdateSelectedElements(new WorksheetItemUpdate("", {
            transparency: transparency
        }), {
            historyAction: WDHistoryAction.TRANSPARENCY,
            actionCategory: WDActionLogCategory.background,
            applyRecursive: false,
            applyToChildren: true
        })
    }

    render () {
        return <>
            <WDToolbarButtonSeparator/>

            <div className={"ws-designer-toolbar-section"}>
                <div className={"ws-designer-toolbar-row"}>
                    <WDToolbarButtonBorder
                        id={8}
                        open={this.state.activeId === 8}
                        clickable={!this.props.isReadonly}
                        showBorderPadding={this.props.showBorderPadding}
                        onChangeBorder={this.props.onChangeBorder}
                        onToggleButton={this.onToggleButton}
                        borderOptions={this.props.elementProps.border}
                    />
                </div>
                <div className={"ws-designer-toolbar-row"}>
                    <WDToolbarButtonColor
                        id={11}
                        clickable={!this.props.isReadonly}
                        command={"foreColor"}
                        options={{
                            action: WDToolbarAction.FILL_COLOR,
                            open: this.state.activeId === 11,
                            width: 70,
                            height: 150
                        }}
                        tooltipPosition={TooltipPosition.above}
                        showColor={true}
                        transparency={true}
                        pressed={this.props.elementProps.color.fillColor !== "transparent"}
                        transparencyAmount={this.props.elementProps.color.transparency}
                        onChangeTransparency={this.onChangeTransparency}
                        initialColor={this.props.elementProps.color.fillColor}
                        action={WDToolbarAction.FILL_COLOR}
                        icon={ImagePath.getButtonUrl() + "fill_color.svg"}
                        tooltip={new TooltipText(
                            this.context.translate(translations.toolbar.fillColor),
                            this.context.translate(translations.tooltip.fill_color))
                        }
                        onActionButton={(action, data) => this.onChangeFillColor(data.color)}
                        onToggleButton={this.onToggleButton}
                    />
                </div>
            </div>
        </>

    }
}

