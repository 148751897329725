import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButtonColorPicker} from "./WDToolbarButtonColorPicker";
import {TooltipPosition, TooltipText} from "../../../../Components/Tooltips";
import {MainContext} from "../../../../_base/MainContext";

class OptionSettings {
    action: WDToolbarAction
    open: boolean

    width: number
    height: number

    constructor(action: WDToolbarAction, open: boolean) {
        this.action = action
        this.open = open

        this.width = 100
        this.height = 100
    }
}

interface IProps {
    id: number

    clickable: boolean
    icon: string
    tooltip: TooltipText
    tooltipDisabled?: TooltipText
    tooltipPosition: TooltipPosition
    options: OptionSettings
    initialColor: string
    iconClass?: string
    colorPickerHeight?: number
    showColor: boolean
    transparency: boolean
    transparencyTitle?: string
    transparencyAmount?: number
    pressed: boolean

    customColors?: string[]
    command: string
    action: WDToolbarAction

    onChangeTransparency?: (transparency: number) => void
    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    color: string
}

/**
 * Button (Yes/No) in Text Toolbar
 * Effects selected text of an element
 * Yes: marked text should get the text decoration given as command
 *      by now all entered text should get bold
 * No:  marked text should get normal
 *      by now all entered text should get normal
 */

export class WDToolbarButtonColor extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            color: this.props.initialColor
        }
    }

    onChangeColor = (color: string) => {
        this.setState({color: color})
        this.props.onActionButton(this.props.options.action, {command: this.props.command, color: color})
    }
    onToggle = () => {
        let color = this.state.color
        if (this.props.pressed) {
            color = "transparent"
        }

        this.props.onActionButton(this.props.action, {command: this.props.command, color: color})
    }
    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button-" + this.props.command}
            className={"ws-designer-toolbar-item"}
            id={this.props.command + this.props.id}>

            <WDToolbarButton id={this.props.id}
                             icon={this.props.icon}
                             iconClassSelected={this.props.iconClass || "svg-color-primary"}
                             iconClassUnselected={this.props.iconClass || "svg-color-primary"}
                             tooltip={this.props.tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             tooltipPosition={this.props.tooltipPosition}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             onToggleButton={this.onToggle}
                             arrow={false}
                             buttonWidth={28}
                             color={this.props.showColor ? this.state.color : undefined}
                             enabled={this.props.clickable}
                             pressed={this.props.pressed}/>

            <WDToolbarButtonColorPicker
                id={this.props.id}
                tooltip={this.props.tooltip}
                tooltipDisabled={this.props.tooltipDisabled}
                tooltipPosition={this.props.tooltipPosition}
                open={this.props.options.open}
                clickable={this.props.clickable}
                dialogHeight={this.props.colorPickerHeight}
                customColors={this.props.customColors}
                currentSelectedColor={this.state.color}
                transparency={this.props.transparency}
                transparencyTitle={this.props.transparencyTitle}
                transparencyAmount={this.props.transparencyAmount}
                onChangeTransparency={this.props.onChangeTransparency}
                onToggleButton={this.onToggleList}
                onChangeColor={this.onChangeColor}
            />

        </li>
    }
}
