import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import LessonSubject from "../../_model/LessonSubject";
import {CreateTag, GetLessonSubject, GetTags} from "../../_endpoint/MetadataEndpoint";
import TextBox from "../../Components/Controls/TextBox";
import {
    CloneWorksheet,
    CreateWorksheet,
    CreateWorksheetPage,
    GetWorksheetThumbnail,
} from "../../_endpoint/WorksheetEndpoint";
import {Worksheet} from "../../_model/Worksheet";
import {
    AdminObjectType,
    Color,
    Difficulty,
    EnumToEntityArray,
    EnumToEntityArrayWithoutTranslate,
    EnumValueToValueNumber,
    Status,
    WSPageFormat
} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import SelectBox from "../../Components/Controls/SelectBox";
import {PageBorderPosition} from "../../Components/PageBorder/WDPageBorder";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import {WSContextType} from "../../Designer/Elements/WSContext";
import WritingCourse, {WritingCourseCharacterTypeEnum} from "../../_model/WritingCourse";
import {
    CloneWritingCourse,
    CreateWritingCourse,
    GetWritingCourse,
    UpdateWritingCourse
} from "../../_endpoint/WritingCourseEndpoint";
import Tag from "../../_model/Tag";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import {WDWritingLineatureSize} from "../../Designer/Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDMathLineatureSize} from "../../Designer/Elements/Lineature/MathLineature/WDMathLineatureData";
import {WorksheetPage} from "../../_model/WorksheetPage";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> {
}

interface IState {
    item: WritingCourse
    unsavedChanges: boolean

    worksheetThumbnail?: string
    lessonSubjects: LessonSubject[]
    tags?: Tag[]
}

export default class WritingCourseForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        let item = new WritingCourse("", Status.draft)
        if (this.isNew() && this.props.match.params.id !== undefined && this.props.match.params.id !== "new") {
            this.context.log.info("New subtask for " + this.props.match.params.id)

            item.parentWritingCourse = new WritingCourse("", Status.draft)
            item.parentWritingCourse.id = +this.props.match.params.id

            this.context.log.flush()
        }

        this.state = {
            item: item,
            unsavedChanges: false,
            lessonSubjects: [],
        }
    }

    componentDidMount() {
        this.initWritingCourse()
        this.fetchLists()
    }

    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.initWritingCourse()
        }
    }

    initWritingCourse = () => {
        if (!this.isNew()) {
            this.fetchData()
        } else {
            if (this.state.item.parentWritingCourse && this.state.item.parentWritingCourse.id) {
                GetWritingCourse(this.state.item.parentWritingCourse.id).then(
                    (itemData) => {
                        let itemWithParentData = this.state.item
                        itemWithParentData.parentWritingCourse = itemData
                        this.setState({item: itemWithParentData})
                    },
                    (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                    }
                )
            }
        }
    }

    fetchData = () => {
        GetWritingCourse(+this.props.match.params.id).then(
            (itemData) => {
                this.context.log.info("Parent WritingCourse = " + itemData.parentWritingCourse)

                if (itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                if (itemData.worksheet) {
                    GetWorksheetThumbnail(itemData.worksheet.id).then((worksheet) => {
                        this.setState({worksheetThumbnail: worksheet.thumbnail})
                    }, () => {
                        // Ignore error, when no thumbnail is available the default image is shown
                    })
                }

                if (itemData.parentWritingCourse) {
                    this.context.log.info("Get parent WritingCourse " + itemData.parentWritingCourse.id)

                    GetWritingCourse(itemData.parentWritingCourse.id!).then((parent) => {
                        let data = this.state.item
                        data.parentWritingCourse = parent
                        this.setState({item: itemData})
                    })
                }

                this.context.log.flush()

                this.setState({
                    item: itemData
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchLists = () => {
        GetLessonSubject().then((result) => {
            this.context.log.info("Set lesson subject")
            this.setState({lessonSubjects: result})
        })
        GetTags().then((result) => {
            this.setState({tags: result})
        })
    }

    getWorksheetName = () => {
        return ("WS-Writing-Course: " + this.state.item.name).substr(0, 100)
    }

    isFormValid = () => {
        const form1 = document.getElementById("formData1") as HTMLFormElement;
        return form1 && form1.reportValidity()
    }

    onSave = async (close: boolean) => {
        if (this.isFormValid()) {

            try {
                let currentItem = this.state.item

                if (!this.hasParent()) {
                    if (currentItem.startNumber === undefined) {
                        currentItem.startNumber = false
                    }

                    if (currentItem.sequenceNumber === undefined) {
                        currentItem.sequenceNumber = false
                    }

                    if (currentItem.traceArrow === undefined) {
                        currentItem.traceArrow = false
                    }

                    if (currentItem.mixedLineature === undefined) {
                        currentItem.mixedLineature = false
                    }

                    if (currentItem.upperLowerLetterMixed === undefined) {
                        currentItem.upperLowerLetterMixed = false
                    }
                }

                if (currentItem.usedInMenu === undefined) {
                    currentItem.usedInMenu = false
                }

                // Added new collection, needs to be created
                let tags: Tag[] = []
                if (currentItem.tags) {
                    tags = currentItem.tags.filter(i => i.id !== undefined)
                    for (const i1 of currentItem.tags.filter(i => i.id === undefined)) {
                        tags.push(await CreateTag(i1));
                    }
                }
                currentItem.tags = tags

                if (currentItem.id) {
                    await UpdateWritingCourse(currentItem)
                    this.setState({unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                } else {
                    let result = await CreateWritingCourse(currentItem)
                    result.parentWritingCourse = this.state.item.parentWritingCourse

                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || this.getBackUrl())
        )
    }
    onCopy = () => {
        if (this.isFormValid()) {

            this.onSave(false).then(
                () => {
                    let context = WSContextType.writing_course_main
                    if (this.hasParent()) {
                        context = WSContextType.writing_course_child
                    }

                    CloneWritingCourse(this.state.item, this.state.item.name + " - " + this.context.translate(translations.text_fragment.copy), context).then(
                        (result: WritingCourse) => {
                            this.setState({item: result})

                            this.props.history.push(this.getBackUrl() + result.id)
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.copy_error))
                        }
                    )
                }
            )
        }
    }
    onSetState = (status: Status, user?: User) => {
        if (this.state.item) {
            const item = this.state.item
            WritingCourse.setStatus(item, status, user)
            this.setState({item: item}, () => this.onSave(false))
            return true
        }
        return false
    }
    onClickWorksheet = async () => {
        if (this.state.item.worksheet) {
            window.open(process.env.PUBLIC_URL + "/designer/" + this.state.item.worksheet.id)
        } else {
            let ws: Worksheet | undefined = undefined

            this.context.log.debug("hasParent = " + this.hasParent())
            this.context.log.debug("parentWritingCourse = ", this.state.item.parentWritingCourse)

            // Clone worksheet of parent writing course
            if (this.hasParent() && this.state.item.parentWritingCourse && this.state.item.parentWritingCourse.worksheet) {
                let worksheetId = this.state.item.parentWritingCourse.worksheet?.id

                this.context.log.info("Cloning worksheet " + worksheetId)
                ws = await CloneWorksheet(worksheetId, this.getWorksheetName(), WSContextType.writing_course_child)
            } else {
                // Create worksheet and open
                let worksheetToCreate = new Worksheet(1, this.getWorksheetName(), WSContextType.writing_course_main, WSPageFormat.A4, true)
                try {
                    ws = await CreateWorksheet(worksheetToCreate)

                    // Create worksheet page
                    let p = new WorksheetPage(WorksheetPage.getNewPageKey(), false,
                        PageBorderPosition.getDefaultPageBorder(), PageBorderPosition.getNoPageBorder(), 1, true)

                    await CreateWorksheetPage(p, ws.id)

                } catch (error) {
                    this.context.handleError(error, this.context.translate(translations.notification.saving_error))
                }
            }

            if (ws === undefined) {
                return
            }

            // Save link to newly created worksheet
            const item = this.state.item
            item.worksheet = new Worksheet(ws.id, ws.name, ws.context, ws.format, ws.editingAllowed)
            this.setState({item: item})

            // save changes
            this.onSave(false)

            // open worksheet
            window.open(process.env.PUBLIC_URL + "/designer/" + item.worksheet.id)

            this.context.log.flush()
        }
    }
    onOpenMainCourse = () => {
        this.props.history.push("/maintenance/writing-course/" + this.state.item.parentWritingCourse?.id)
    }

    onChangeStringValue = (property: string, value: string) => {
        const item = this.state.item
        item[property] = value
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeBooleanValue = (property: string) => {
        const item = this.state.item
        item[property] = item[property] ? !item[property] : true
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeEnum = (value: number, enumType: any, itemProperty: string) => {
        // Get enum value by index (value)
        let enumKey = Object.keys(enumType).find((e, i) => i === value)
        if (enumKey) {
            let currentItem = this.state.item
            currentItem[itemProperty] = enumKey
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeLessonSubject = async (value: number) => {
        const item = this.state.item
        item.lessonSubject = new LessonSubject("", "", value)
        this.setState({item: item, unsavedChanges: true}, () => {
            this.removeLessonValues()
        })
    }
    onChangeTags = (tags: Tag[]) => {
        let item = this.state.item
        item.tags = tags
        this.setState({item: item, unsavedChanges: true})
    }

    getBackUrl = () => {
        if (this.hasParent()) {
            return "/maintenance/writing-course/" + this.state.item.parentWritingCourse!.id + "/list"
        }

        if (this.isNew()) {
            return "/maintenance/writing-course/"
        }

        return "/maintenance/writing-course/" + this.state.item.id + "/list"
    }
    getAuthor = () => {
        if (this.state.item.createdBy) {
            return this.state.item.createdBy?.firstname + " " + this.state.item.createdBy?.lastname
        }

        return ""
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }
    isNew = () => {
        return (this.props.location.pathname.endsWith(Const.CreateNewDataUrl))
    }
    hasParent = () => {
        return this.state && this.state.item && this.state.item.parentWritingCourse !== undefined && this.state.item.parentWritingCourse !== null
    }

    isLessonGerman = () => {
        return this.state.item.lessonSubject?.id === 1
    }
    isLessonEnglish = () => {
        return this.state.item.lessonSubject?.id === 4
    }
    isLessonMath = () => {
        return this.state.item.lessonSubject?.id === 2
    }
    isLanguageLesson = () => {
        return this.isLessonGerman() || this.isLessonEnglish()
    }
    removeLessonValues = () => {
        const item = this.state.item

        if (!this.isLanguageLesson() && (this.state.item.syllableMethod || this.state.item.upperLowerLetterMixed)) {
            item.syllableMethod = false
            item.upperLowerLetterMixed = false
        }

        item.characterType = undefined

        this.setState({item: item})
    }

    render() {
        let itemType = AdminObjectType.writing_course_main
        if (this.hasParent()) {
            itemType = AdminObjectType.writing_course_child
        }

        return <AdminDataForm
            onSave={this.onSave}
            onCopy={this.onCopy}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={itemType}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

            <div className={"admin-form-content"}>

                <form id={"formData1"} className="admin-form-input" onSubmit={() => {
                    return false
                }}>

                    <FormHeader
                        number={this.state.item.id}
                        status={this.state.item.status}
                        statusTranslationPath={translations.enum.status}
                        createdBy={this.state.item.createdBy}
                        ownerId={this.state.item.ownerId}
                    />

                    {this.state.item.parentWritingCourse &&
                        <div className="form-group form-subheader">
                            <div className={"form-subheader-title"} onClick={this.onOpenMainCourse}>
                                {this.context.translate(translations.text.main_exercise)}: {this.state.item.parentWritingCourse.name}
                            </div>
                            <div className={"form-subheader-description"}>
                                {this.state.item.parentWritingCourse.description}
                            </div>
                        </div>
                    }

                    <div className="form-row">
                        <div className="form-group" style={{width: "500px", paddingRight: "10px", paddingLeft: "0"}}>
                            <div className="form-row">
                                <TextBox id={"txtTitle"}
                                         width={500}
                                         label={this.context.translate(translations.fields.title)}
                                         required={true}
                                         readonly={this.isReadonly()}
                                         onChange={(value) => this.onChangeStringValue("name", value)}
                                         value={this.state.item.name}
                                />
                            </div>

                            {this.hasParent() &&
                                <div className="form-row">
                                    <CheckBoxSwitch id={"chkIsUsedInMenu"}
                                                    width={220}
                                                    label={this.context.translate(translations.fields.writing_course.used_in_menu)}
                                                    checked={this.state.item.usedInMenu || false}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBooleanValue("usedInMenu")}
                                    />
                                </div>
                            }

                            {!this.hasParent() &&
                                <>
                                    <div className="form-row">
                                        <TextBox id={"txtDescription"}
                                                 width={500}
                                                 label={this.context.translate(translations.fields.description)}
                                                 required={true}
                                                 multiline={true}
                                                 readonly={this.isReadonly()}
                                                 onChange={(value) => this.onChangeStringValue("description", value)}
                                                 value={this.state.item.description || ""}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <SelectBox id={"selLessonSubject"}
                                                   label={this.context.translate(translations.fields.lesson_subject)}
                                                   width={484}
                                                   style={{marginRight: "10px"}}
                                                   data={this.state.lessonSubjects}
                                                   onChange={this.onChangeLessonSubject}
                                                   value={this.state.item.lessonSubject?.id}
                                                   required={true}
                                                   readonly={this.isReadonly()}
                                                   positioningRow={false}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <SelectBox id={"selCharacterType"}
                                                   label={this.context.translate(translations.fields.writing_course.character_type)}
                                                   width={484}
                                                   data={EnumToEntityArray(WritingCourseCharacterTypeEnum, translations.enum.character_type, this.context.translate)}
                                                   onChange={(value) => this.onChangeEnum(value, WritingCourseCharacterTypeEnum, "characterType")}
                                                   value={EnumValueToValueNumber(WritingCourseCharacterTypeEnum, this.state.item.characterType)}
                                                   required={true}
                                                   readonly={this.isReadonly()}
                                                   positioningRow={false}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <SelectBox id={"selWritingLineature"}
                                                   width={220}
                                                   style={{marginRight: "43px"}}
                                                   label={this.context.translate(translations.fields.writing_course.lineature_type_german)}
                                                   data={EnumToEntityArrayWithoutTranslate(WDWritingLineatureSize)}
                                                   required={this.isLessonGerman()}
                                                   value={EnumValueToValueNumber(WDWritingLineatureSize, this.state.item.writingLineatureSize)}
                                                   readonly={this.isReadonly()}
                                                   onChange={(value) => this.onChangeEnum(value, WDWritingLineatureSize, "writingLineatureSize")}
                                                   positioningRow={false}
                                        />

                                        <SelectBox id={"selMathLineature"}
                                                   width={220}
                                                   label={this.context.translate(translations.fields.writing_course.lineature_type_math)}
                                                   data={EnumToEntityArrayWithoutTranslate(WDMathLineatureSize)}
                                                   required={this.isLessonMath()}
                                                   value={EnumValueToValueNumber(WDMathLineatureSize, this.state.item.mathLineatureSize)}
                                                   readonly={this.isReadonly()}
                                                   onChange={(value) => this.onChangeEnum(value, WDMathLineatureSize, "mathLineatureSize")}
                                                   positioningRow={false}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <CheckBoxSwitch id={"chkMixedLineature"}
                                                        width={220}
                                                        style={{marginRight: "25px"}}
                                                        label={this.context.translate(translations.fields.writing_course.mixed_lineature)}
                                                        checked={this.state.item.mixedLineature || false}
                                                        readonly={this.isReadonly()}
                                                        onChange={() => this.onChangeBooleanValue("mixedLineature")}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <SelectBox id={"selDifficulty"}
                                                   width={220}
                                                   style={{marginRight: "43px"}}
                                                   label={this.context.translate(translations.fields.difficulty)}
                                                   data={EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate)}
                                                   required={true}
                                                   value={EnumValueToValueNumber(Difficulty, this.state.item.difficulty)}
                                                   readonly={this.isReadonly()}
                                                   onChange={(value) => this.onChangeEnum(value, Difficulty, "difficulty")}
                                                   positioningRow={false}
                                        />
                                        <SelectBox id={"selColor"}
                                                   width={220}
                                                   label={this.context.translate(translations.fields.color)}
                                                   data={EnumToEntityArray(Color, translations.enum.color, this.context.translate, true)}
                                                   required={true}
                                                   value={EnumValueToValueNumber(Color, this.state.item.color)}
                                                   readonly={this.isReadonly()}
                                                   onChange={(value) => this.onChangeEnum(value, Color, "color")}
                                                   positioningRow={false}
                                        />
                                    </div>

                                    <div className={"form-row"}>
                                        <div className={"form-group-checkbox-list"} style={{marginTop: "10px"}}>
                                            <CheckBoxSwitch id={"chkStartNumber"}
                                                            width={232}
                                                            style={{marginRight: "25px"}}
                                                            label={this.context.translate(translations.fields.writing_course.start_number)}
                                                            checked={this.state.item.startNumber || false}
                                                            readonly={this.isReadonly()}
                                                            onChange={() => this.onChangeBooleanValue("startNumber")}
                                            />
                                            <CheckBoxSwitch id={"chkSequenceNumber"}
                                                            width={232}
                                                            label={this.context.translate(translations.fields.writing_course.sequence_number)}
                                                            checked={this.state.item.sequenceNumber || false}
                                                            readonly={this.isReadonly()}
                                                            onChange={() => this.onChangeBooleanValue("sequenceNumber")}
                                            />
                                            <CheckBoxSwitch id={"chkTraceArrow"}
                                                            width={232}
                                                            style={{marginRight: "25px", marginBottom: "20px"}}
                                                            label={this.context.translate(translations.fields.writing_course.trace_arrow)}
                                                            checked={this.state.item.traceArrow || false}
                                                            readonly={this.isReadonly()}
                                                            onChange={() => this.onChangeBooleanValue("traceArrow")}
                                            />
                                            <CheckBoxSwitch id={"chkPreviewSymbol"}
                                                            width={400}
                                                            label={this.context.translate(translations.fields.writing_course.preview_symbol)}
                                                            checked={this.state.item.previewSymbol || false}
                                                            readonly={this.isReadonly()}
                                                            onChange={() => this.onChangeBooleanValue("previewSymbol")}
                                            />
                                            <CheckBoxSwitch id={"chkPreviewSymbolTrace"}
                                                            width={400}
                                                            label={this.context.translate(translations.fields.writing_course.preview_symbol_trace)}
                                                            checked={this.state.item.previewSymbolTrace || false}
                                                            readonly={this.isReadonly()}
                                                            onChange={() => this.onChangeBooleanValue("previewSymbolTrace")}
                                            />
                                            <CheckBoxSwitch id={"chkPreviewSymbolImage"}
                                                            width={400}
                                                            label={this.context.translate(translations.fields.writing_course.preview_symbol_image)}
                                                            checked={this.state.item.previewSymbolImage || false}
                                                            readonly={this.isReadonly()}
                                                            onChange={() => this.onChangeBooleanValue("previewSymbolImage")}
                                            />
                                            {this.isLanguageLesson() &&
                                                <>
                                                    <CheckBoxSwitch id={"chkUpperLowerCaseLetterMixed"}
                                                                    width={400}
                                                                    label={this.context.translate(translations.fields.writing_course.mixed_upper_lower_letter)}
                                                                    checked={this.state.item.upperLowerLetterMixed || false}
                                                                    readonly={this.isReadonly()}
                                                                    onChange={() => this.onChangeBooleanValue("upperLowerLetterMixed")}
                                                    />
                                                    <CheckBoxSwitch id={"chkSyllableMethod"}
                                                                    width={400}
                                                                    label={this.context.translate(translations.fields.writing_course.syllable_method)}
                                                                    checked={this.state.item.syllableMethod || false}
                                                                    readonly={this.isReadonly()}
                                                                    onChange={() => this.onChangeBooleanValue("syllableMethod")}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <TextBox id={"txtTags"}
                                             width={800}
                                             label={this.context.translate(translations.fields.writing_course.tags)}
                                             required={false}
                                             readonly={this.isReadonly()}
                                             data={this.state.tags}
                                             tags={this.state.item.tags || []}
                                             onChangeTags={this.onChangeTags}
                                             mode={{autoComplete: true, tags: true}}
                                             value={""}
                                    />

                                </>
                            }
                        </div>

                        <div className={"form-group"}>
                            {this.state.item.id && (
                                <div className={"admin-form-worksheet"}>
                                    {this.state.worksheetThumbnail ?
                                        <img src={"data:image/jpeg;base64," + this.state.worksheetThumbnail}
                                             width={350}
                                             alt={this.context.translate(translations.text.worksheet)}
                                             onClick={this.onClickWorksheet}
                                             draggable={"false"}
                                             onContextMenu={(e) => e.preventDefault() }
                                        />
                                        :
                                        <img
                                            src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                            width={350} alt={this.context.translate(translations.text.worksheet)}
                                            draggable={"false"}
                                            onContextMenu={(e) => e.preventDefault() }
                                            onClick={this.onClickWorksheet}/>
                                    }
                                </div>
                            )}

                        </div>
                    </div>
                </form>
            </div>
        </AdminDataForm>
    }
}

