import React from "react";
import {MainContext} from "../../../_base/MainContext";
import translations from "../../../Framework/translations.json";

interface IProps {
    customColors?: string[]
    currentSelectedColor?: string
    transparency: boolean
    transparencyTitle?: string
    onChangeColor: (color: string) => void
}

interface IState {
    showMore: boolean
}

export class WDColorPicker extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            showMore: false
        }
    }

    isSelectedColor = (color: string) => {
        let selectedCSS = " ws-designer-color-picker-item-selected"
        return (this.props.currentSelectedColor === color ? selectedCSS : "")
    }

    onShowOtherColorThemes = (more: boolean) => {
        this.setState({showMore: more})
    }

    renderCustomColors = () => {
        if (this.props.customColors) {
            return <>
                <div className={"ws-designer-options-section-label"}>
                    {this.context.translate(translations.toolbar.color_picker.colors)}
                </div>

                <div className={"ws-designer-options-section-row"}>
                    {this.props.customColors.map(c => {
                        return <div className={"ws-designer-color-picker-item" + this.isSelectedColor(c)}
                                    key={"color-" + c}
                                    style={{backgroundColor: c, border: "1px solid #F7F7F7"}}
                                    onClick={() => this.props.onChangeColor(c)}/>
                    })}
                </div>
            </>
        }
        return <></>
    }

    render() {
        if (this.props.customColors) {
            return <div className={"ws-designer-color-picker"}>
                <div className={"ws-designer-options-section"}>

                    {this.props.customColors &&
                        this.renderCustomColors()
                    }
                </div>
            </div>
        }


        return <div className={"ws-designer-color-picker"}>
            <div className={"ws-designer-options-section"}>

                <div className={"ws-designer-options-section-label"}>
                    {this.context.translate(translations.toolbar.color_picker.grey)}
                </div>
                <div className={"ws-designer-options-section-row"}>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFFFFF")}
                         style={{backgroundColor: "#FFFFFF", border: "1px solid #F7F7F7"}}
                         onClick={() => this.props.onChangeColor("#FFFFFF")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F2F2F2")}
                         style={{backgroundColor: "#F2F2F2"}}
                         onClick={() => this.props.onChangeColor("#F2F2F2")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E7E6E6")}
                         style={{backgroundColor: "#E7E6E6"}}
                         onClick={() => this.props.onChangeColor("#E7E6E6")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D8D8D8")}
                         style={{backgroundColor: "#D8D8D8"}}
                         onClick={() => this.props.onChangeColor("#D8D8D8")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#BFBFBF")}
                         style={{backgroundColor: "#BFBFBF"}}
                         onClick={() => this.props.onChangeColor("#BFBFBF")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#AEABAB")}
                         style={{backgroundColor: "#AEABAB"}}
                         onClick={() => this.props.onChangeColor("#AEABAB")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#7F7F7F")}
                         style={{backgroundColor: "#7F7F7F"}}
                         onClick={() => this.props.onChangeColor("#7F7F7F")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#757070")}
                         style={{backgroundColor: "#757070"}}
                         onClick={() => this.props.onChangeColor("#757070")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#3A3838")}
                         style={{backgroundColor: "#3A3838"}}
                         onClick={() => this.props.onChangeColor("#3A3838")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#000000")}
                         style={{backgroundColor: "#000000"}}
                         onClick={() => this.props.onChangeColor("#000000")}/>
                </div>
            </div>

            <div className={"ws-designer-options-section"}>
                <div className={"ws-designer-options-section-label"}>
                    {this.context.translate(translations.toolbar.color_picker.design)}
                </div>

                <div className={"ws-designer-options-section-row"}>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FBE8EA")}
                             style={{backgroundColor: "#FBE8EA"}}
                             onClick={() => this.props.onChangeColor("#FBE8EA")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F1B9BE")}
                             style={{backgroundColor: "#F1B9BE"}}
                             onClick={() => this.props.onChangeColor("#F1B9BE")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E68791")}
                             style={{backgroundColor: "#E68791"}}
                             onClick={() => this.props.onChangeColor("#E68791")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#DC5867")}
                             style={{backgroundColor: "#DC5867"}}
                             onClick={() => this.props.onChangeColor("#DC5867")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#CD1426")}
                             style={{backgroundColor: "#CD1426"}}
                             onClick={() => this.props.onChangeColor("#CD1426")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F7E7EA")}
                             style={{backgroundColor: "#F7E7EA"}}
                             onClick={() => this.props.onChangeColor("#F7E7EA")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E7B6BF")}
                             style={{backgroundColor: "#E7B6BF"}}
                             onClick={() => this.props.onChangeColor("#E7B6BF")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D68694")}
                             style={{backgroundColor: "#D68694"}}
                             onClick={() => this.props.onChangeColor("#D68694")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C55569")}
                             style={{backgroundColor: "#C55569"}}
                             onClick={() => this.props.onChangeColor("#C55569")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#AB182A")}
                             style={{backgroundColor: "#AB182A"}}
                             onClick={() => this.props.onChangeColor("#AB182A")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFEBD0")}
                             style={{backgroundColor: "#FFEBD0"}}
                             onClick={() => this.props.onChangeColor("#FFEBD0")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#DFA679")}
                             style={{backgroundColor: "#DFA679"}}
                             onClick={() => this.props.onChangeColor("#DFA679")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C8906A")}
                             style={{backgroundColor: "#C8906A"}}
                             onClick={() => this.props.onChangeColor("#C8906A")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#9D673F")}
                             style={{backgroundColor: "#9D673F"}}
                             onClick={() => this.props.onChangeColor("#9D673F")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#80583A")}
                             style={{backgroundColor: "#80583A"}}
                             onClick={() => this.props.onChangeColor("#80583A")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFF6E6")}
                             style={{backgroundColor: "#FFF6E6"}}
                             onClick={() => this.props.onChangeColor("#FFF6E6")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFE1B3")}
                             style={{backgroundColor: "#FFE1B3"}}
                             onClick={() => this.props.onChangeColor("#FFE1B3")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FCCC81")}
                             style={{backgroundColor: "#FCCC81"}}
                             onClick={() => this.props.onChangeColor("#FCCC81")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FAB750")}
                             style={{backgroundColor: "#FAB750"}}
                             onClick={() => this.props.onChangeColor("#FAB750")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F49819")}
                             style={{backgroundColor: "#F49819"}}
                             onClick={() => this.props.onChangeColor("#F49819")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FEF9EA")}
                             style={{backgroundColor: "#FEF9EA"}}
                             onClick={() => this.props.onChangeColor("#FEF9EA")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FDECBC")}
                             style={{backgroundColor: "#FDECBC"}}
                             onClick={() => this.props.onChangeColor("#FDECBC")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F9DE8E")}
                             style={{backgroundColor: "#F9DE8E"}}
                             onClick={() => this.props.onChangeColor("#F9DE8E")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F7D261")}
                             style={{backgroundColor: "#F7D261"}}
                             onClick={() => this.props.onChangeColor("#F7D261")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F2BD20")}
                             style={{backgroundColor: "#F2BD20"}}
                             onClick={() => this.props.onChangeColor("#F2BD20")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F8FAF0")}
                             style={{backgroundColor: "#F8FAF0"}}
                             onClick={() => this.props.onChangeColor("#F8FAF0")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E5ECCE")}
                             style={{backgroundColor: "#E5ECCE"}}
                             onClick={() => this.props.onChangeColor("#E5ECCE")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D4E0AB")}
                             style={{backgroundColor: "#D4E0AB"}}
                             onClick={() => this.props.onChangeColor("#D4E0AB")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C4D388")}
                             style={{backgroundColor: "#C4D388"}}
                             onClick={() => this.props.onChangeColor("#C4D388")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#A8BF54")}
                             style={{backgroundColor: "#A8BF54"}}
                             onClick={() => this.props.onChangeColor("#A8BF54")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F0F6E6")}
                             style={{backgroundColor: "#F0F6E6"}}
                             onClick={() => this.props.onChangeColor("#F0F6E6")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D5E5B5")}
                             style={{backgroundColor: "#D5E5B5"}}
                             onClick={() => this.props.onChangeColor("#D5E5B5")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#B9D281")}
                             style={{backgroundColor: "#B9D281"}}
                             onClick={() => this.props.onChangeColor("#B9D281")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#9CC151")}
                             style={{backgroundColor: "#9CC151"}}
                             onClick={() => this.props.onChangeColor("#9CC151")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#73A62D")}
                             style={{backgroundColor: "#73A62D"}}
                             onClick={() => this.props.onChangeColor("#73A62D")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F4F9FD")}
                             style={{backgroundColor: "#F4F9FD"}}
                             onClick={() => this.props.onChangeColor("#F4F9FD")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#DAE8F4")}
                             style={{backgroundColor: "#DAE8F4"}}
                             onClick={() => this.props.onChangeColor("#DAE8F4")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C1D9EC")}
                             style={{backgroundColor: "#C1D9EC"}}
                             onClick={() => this.props.onChangeColor("#C1D9EC")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#A8C8E5")}
                             style={{backgroundColor: "#A8C8E5"}}
                             onClick={() => this.props.onChangeColor("#A8C8E5")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#82AFD9")}
                             style={{backgroundColor: "#82AFD9"}}
                             onClick={() => this.props.onChangeColor("#82AFD9")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#EBF0F4")}
                             style={{backgroundColor: "#EBF0F4"}}
                             onClick={() => this.props.onChangeColor("#EBF0F4")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C0D0DC")}
                             style={{backgroundColor: "#C0D0DC"}}
                             onClick={() => this.props.onChangeColor("#C0D0DC")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#98B1C6")}
                             style={{backgroundColor: "#98B1C6"}}
                             onClick={() => this.props.onChangeColor("#98B1C6")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#6C90AF")}
                             style={{backgroundColor: "#6C90AF"}}
                             onClick={() => this.props.onChangeColor("#6C90AF")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#2E618D")}
                             style={{backgroundColor: "#2E618D"}}
                             onClick={() => this.props.onChangeColor("#2E618D")}/>
                    </div>

                    <div className={"ws-designer-options-section-column"}>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F2F0F5")}
                             style={{backgroundColor: "#F2F0F5"}}
                             onClick={() => this.props.onChangeColor("#F2F0F5")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D7D2DF")}
                             style={{backgroundColor: "#D7D2DF"}}
                             onClick={() => this.props.onChangeColor("#D7D2DF")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#BBB4C9")}
                             style={{backgroundColor: "#BBB4C9"}}
                             onClick={() => this.props.onChangeColor("#BBB4C9")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#A096B4")}
                             style={{backgroundColor: "#A096B4"}}
                             onClick={() => this.props.onChangeColor("#A096B4")}/>
                        <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#776893")}
                             style={{backgroundColor: "#776893"}}
                             onClick={() => this.props.onChangeColor("#776893")}/>
                    </div>

                </div>
            </div>

            <div className={"ws-designer-options-section"}>
                <div className={"ws-designer-options-section-label"}>
                    {this.context.translate(translations.toolbar.color_picker.default)}
                </div>

                <div className={"ws-designer-options-section-row"}>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C00000")}
                         style={{backgroundColor: "#C00000"}}
                         onClick={() => this.props.onChangeColor("#C00000")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FF0000")}
                         style={{backgroundColor: "#FF0000"}}
                         onClick={() => this.props.onChangeColor("#FF0000")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFC000")}
                         style={{backgroundColor: "#FFC000"}}
                         onClick={() => this.props.onChangeColor("#FFC000")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFFF00")}
                         style={{backgroundColor: "#FFFF00"}}
                         onClick={() => this.props.onChangeColor("#FFFF00")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#92D050")}
                         style={{backgroundColor: "#92D050"}}
                         onClick={() => this.props.onChangeColor("#92D050")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#00B050")}
                         style={{backgroundColor: "#00B050"}}
                         onClick={() => this.props.onChangeColor("#00B050")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#00B0F0")}
                         style={{backgroundColor: "#00B0F0"}}
                         onClick={() => this.props.onChangeColor("#00B0F0")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#0070C0")}
                         style={{backgroundColor: "#0070C0"}}
                         onClick={() => this.props.onChangeColor("#0070C0")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#002060")}
                         style={{backgroundColor: "#002060"}}
                         onClick={() => this.props.onChangeColor("#002060")}/>
                    <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#7030A0")}
                         style={{backgroundColor: "#7030A0"}}
                         onClick={() => this.props.onChangeColor("#7030A0")}/>
                </div>
            </div>

            {this.props.transparency &&
                <div className={"ws-designer-options-section"}>
                    <div className={"ws-designer-options-section-label ws-designer-color-picker-button"}
                         onClick={() => this.props.onChangeColor("transparent")}>
                        {this.props.transparencyTitle ? this.props.transparencyTitle : this.context.translate(translations.toolbar.color_picker.no_fill)}
                    </div>
                </div>
            }

            {!this.state.showMore
                ?
                <div className={"ws-designer-options-section"}>
                    <div className={"ws-designer-options-section-label ws-designer-color-picker-button"}
                         onClick={() => this.onShowOtherColorThemes(true)}>
                        {this.context.translate(translations.toolbar.color_picker.more) + " >> "}
                    </div>
                </div>
                :
                <>
                    <div className={"ws-designer-options-section"}>
                        <div className={"ws-designer-options-section-label"}>
                            {this.context.translate(translations.toolbar.color_picker.vicido)}

                            <div className={"ws-designer-options-section-row"}>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#FFFFFF")}
                                     style={{backgroundColor: "#FFFFFF", border: "1px solid #F7F7F7"}}
                                     onClick={() => this.props.onChangeColor("#FFFFFF")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F6F8FA")}
                                     style={{backgroundColor: "#F6F8FA"}}
                                     onClick={() => this.props.onChangeColor("#F6F8FA")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F0F2F8")}
                                     style={{backgroundColor: "#F0F2F8"}}
                                     onClick={() => this.props.onChangeColor("#F0F2F8")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E9ECF5")}
                                     style={{backgroundColor: "#E9ECF5"}}
                                     onClick={() => this.props.onChangeColor("#E9ECF5")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E2E6F2")}
                                     style={{backgroundColor: "#E2E6F2"}}
                                     onClick={() => this.props.onChangeColor("#E2E6F2")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#DADFF2")}
                                     style={{backgroundColor: "#DADFF2"}}
                                     onClick={() => this.props.onChangeColor("#DADFF2")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D3D9EC")}
                                     style={{backgroundColor: "#D3D9EC"}}
                                     onClick={() => this.props.onChangeColor("#D3D9EC")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#CCD3E9")}
                                     style={{backgroundColor: "#CCD3E9"}}
                                     onClick={() => this.props.onChangeColor("#CCD3E9")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#CBD2EC")}
                                     style={{backgroundColor: "#CBD2EC"}}
                                     onClick={() => this.props.onChangeColor("#CBD2EC")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#C5CCE5")}
                                     style={{backgroundColor: "#C5CCE5"}}
                                     onClick={() => this.props.onChangeColor("#C5CCE5")}/>
                            </div>
                            <div className={"ws-designer-options-section-row"}>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#BDC6E2")}
                                     style={{backgroundColor: "#BDC6E2"}}
                                     onClick={() => this.props.onChangeColor("#BDC6E2")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#B6BFDF")}
                                     style={{backgroundColor: "#B6BFDF"}}
                                     onClick={() => this.props.onChangeColor("#B6BFDF")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#A7B3D9")}
                                     style={{backgroundColor: "#A7B3D9"}}
                                     onClick={() => this.props.onChangeColor("#A7B3D9")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#99A6D2")}
                                     style={{backgroundColor: "#99A6D2"}}
                                     onClick={() => this.props.onChangeColor("#99A6D2")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#92A0CF")}
                                     style={{backgroundColor: "#92A0CF"}}
                                     onClick={() => this.props.onChangeColor("#92A0CF")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#8898BC")}
                                     style={{backgroundColor: "#8898BC"}}
                                     onClick={() => this.props.onChangeColor("#8898BC")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#8393C9")}
                                     style={{backgroundColor: "#8393C9"}}
                                     onClick={() => this.props.onChangeColor("#8393C9")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#7B8CC5")}
                                     style={{backgroundColor: "#7B8CC5"}}
                                     onClick={() => this.props.onChangeColor("#7B8CC5")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#7486C2")}
                                     style={{backgroundColor: "#7486C2"}}
                                     onClick={() => this.props.onChangeColor("#7486C2")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#6D80BF")}
                                     style={{backgroundColor: "#6D80BF"}}
                                     onClick={() => this.props.onChangeColor("#6D80BF")}/>
                            </div>

                            <div className={"ws-designer-options-section-row"}>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#F4A634")}
                                     style={{backgroundColor: "#F4A634"}}
                                     onClick={() => this.props.onChangeColor("#F4A634")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#E69D30")}
                                     style={{backgroundColor: "#E69D30"}}
                                     onClick={() => this.props.onChangeColor("#E69D30")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D9942E")}
                                     style={{backgroundColor: "#D9942E"}}
                                     onClick={() => this.props.onChangeColor("#D9942E")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#BF8328")}
                                     style={{backgroundColor: "#BF8328"}}
                                     onClick={() => this.props.onChangeColor("#BF8328")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#A16E22")}
                                     style={{backgroundColor: "#A16E22"}}
                                     onClick={() => this.props.onChangeColor("#A16E22")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#D8394C")}
                                     style={{backgroundColor: "#D8394C"}}
                                     onClick={() => this.props.onChangeColor("#D8394C")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#525566")}
                                     style={{backgroundColor: "#525566"}}
                                     onClick={() => this.props.onChangeColor("#525566")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#363B49")}
                                     style={{backgroundColor: "#363B49"}}
                                     onClick={() => this.props.onChangeColor("#363B49")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#30323C")}
                                     style={{backgroundColor: "#30323C"}}
                                     onClick={() => this.props.onChangeColor("#30323C")}/>
                                <div className={"ws-designer-color-picker-item" + this.isSelectedColor("#1E1F28")}
                                     style={{backgroundColor: "#1E1F28"}}
                                     onClick={() => this.props.onChangeColor("#1E1F28")}/>
                            </div>
                        </div>
                    </div>

                    <div className={"ws-designer-options-section"}>
                        <div className={"ws-designer-options-section-label ws-designer-color-picker-button"}
                             onClick={() => this.onShowOtherColorThemes(false)}>
                            {" << " + this.context.translate(translations.toolbar.color_picker.less)}
                        </div>
                    </div>
                </>
            }
        </div>
    }
}
