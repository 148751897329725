import React from 'react';
import {ColumnDefinition} from "../../../Components/List/List";
import translations from "../../../Framework/translations.json";
import SalesMaterialListItem from "./SalesMaterialListItem";
import {AdminObjectType, ColumnRenderOption, Status} from "../../../Framework/Enums";
import {Notification} from "../../../Components/Notification/NotificationHandler";
import AdminDataList from "../../Maintenance/AdminDataList";
import {MainContext} from "../../../_base/MainContext";
import User from "../../../_model/User";
import Const from "../../../Framework/Const";
import {AdminUtils} from "../../Maintenance/AdminUtils";
import {RouteComponentProps} from "react-router-dom";
import SalesMaterial from "../../../_model/SalesMaterial";
import {
    CloneSalesMaterial,
    GetAllSalesMaterial,
    GetSalesMaterial,
    UpdateSalesMaterial
} from "../../../_endpoint/SalesMaterialEndpoint";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: SalesMaterial[]
    listItems?: SalesMaterialListItem[]
}

export default class SalesMaterialList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 60, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.created_by), "createdBy", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 10, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        GetAllSalesMaterial().then(
            (itemData) => {

                const listItems = itemData.map(item => {
                    // Status-Text (translated)
                    let status = translations.enum.status[item.status]

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    let createdBy = ""
                    if (item.createdBy) {
                        createdBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new SalesMaterialListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        owner,
                        item.modifiedOn || item.createdOn!,
                        createdBy
                    )
                })

                this.setState({items: itemData, listItems: listItems})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onCopyItem = async (items: number[]) => {
        let notification = Notification.handleLoading(this.context.translate(translations.notification.copying))
        this.context.setNotification(notification)

        try {
            let promises: Promise<SalesMaterial>[] = []

            for (const id of items) {
                const salesMaterial = await GetSalesMaterial(id)
                if (salesMaterial && salesMaterial.status !== Status.deactivated) {

                    let clone: SalesMaterial = {...salesMaterial}
                    clone.id = undefined
                    clone.status = Status.draft

                    promises.push(CloneSalesMaterial(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy)))
                }
            }

            Promise.all(promises).then(() => {
                // If user stays on the same site
                if(this.props.history.location === this.props.location) {
                    this.setState({listItems: undefined}, () => {
                        this.fetchData()
                        this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.copied)), notification.id)
                    })
                }
                // If user navigates away
                else {
                    this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.copied)), notification.id)
                }
            })

        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }
    onSetState = async(item: number, status: Status, user?: User) => {
        try {
            const salesMaterial = this.state.items.find(d => d.id === item)
            if (salesMaterial && salesMaterial.status !== status) {
                SalesMaterial.setStatus(salesMaterial, status, user)
                await UpdateSalesMaterial(salesMaterial)

                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.setState({listItems: undefined}, () => {
            this.fetchData()
        })
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.sales_material) + Const.CreateNewDataUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.sales_material) + "{0}"}
                              objectType={AdminObjectType.sales_material}
                              onSetStatus={this.onSetState}
                              onRefresh={this.onRefresh}
                              onCopyItem={this.onCopyItem}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              paginationOptions={{ showPagination: true }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "modifiedOn",
                                  sortDescending: true
                              }}
                              filterOptions={{ externalFilter: false }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
