import React from 'react';
import translations from "../../../Framework/translations.json";
import TextBox from "../../../Components/Controls/TextBox";
import {MainContext} from "../../../_base/MainContext";
import {WordType, WordTypeProps, WordTypeState} from "../WordType";
import {
    CreateProperName,
    GetProperName,
    UpdateProperName
} from "../../../_endpoint/WordEndpoint";
import Word from "../../../_model/Dictionary/Word";
import Entity, {EntityData} from "../../../_model/Entity";
import ProperName, {ProperNameTypeEnum} from "../../../_model/Dictionary/ProperName";
import {EnumToEntityArray, EnumToEntityArrayWithoutTranslate, EnumValueToValueNumber} from "../../../Framework/Enums";
import SelectBox from "../../../Components/Controls/SelectBox";
import {
    ArticleDefiniteEnum,
    CaseFirstEnum,
    CaseFourthEnum,
    CaseSecondEnum,
    CaseThirdEnum
} from "../../../_model/Dictionary/Noun";

export interface IProps extends WordTypeProps {
    wordId?: number
    readonly: boolean
}

interface IState extends WordTypeState {
    item: ProperName
    unsavedChanges: boolean
}

export default class ProperNameFormPart extends WordType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    articleEnum: Entity[] = []
    caseFirstEnum: Entity[] = []
    caseSecondEnum: Entity[] = []
    caseThirdEnum: Entity[] = []
    caseFourthEnum: Entity[] = []

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            item: new ProperName(),
            unsavedChanges: false,
        }
    }

    componentDidMount() {
        this.fetchData()

        // Initialize enums to add 'none' possibility
        this.articleEnum = [new Entity(" - ", -1)]
        this.articleEnum = this.articleEnum.concat(EnumToEntityArrayWithoutTranslate(ArticleDefiniteEnum))

        this.caseFirstEnum = [new Entity(" - ", -1)]
        this.caseFirstEnum = this.caseFirstEnum.concat(EnumToEntityArrayWithoutTranslate(CaseFirstEnum))

        this.caseSecondEnum = [new Entity(" - ", -1)]
        this.caseSecondEnum = this.caseSecondEnum.concat(EnumToEntityArrayWithoutTranslate(CaseSecondEnum))

        this.caseThirdEnum = [new Entity(" - ", -1)]
        this.caseThirdEnum = this.caseThirdEnum.concat(EnumToEntityArrayWithoutTranslate(CaseThirdEnum))

        this.caseFourthEnum = [new Entity(" - ", -1)]
        this.caseFourthEnum = this.caseFourthEnum.concat(EnumToEntityArrayWithoutTranslate(CaseFourthEnum))
    }

    fetchData = () => {
        if (this.props.wordId) {
            GetProperName(this.props.wordId).then(
                (itemData) => {
                    this.setState({item: itemData})
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }

    // close and success messages are done in word
    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        let result

        if (word.id) {
            result = await UpdateProperName(this.state.item, word)
        } else {
            result = await CreateProperName(this.state.item, word)
        }

        this.setState({unsavedChanges: false})
        return result
    }

    onChangeText = (fieldName: string, value: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeEnum = (value: number, enumType: any, itemProperty: string) => {
        let currentItem = this.state.item

        if (value === -1) {
            currentItem[itemProperty] = null
        }
        else {
            // Get enum value by index (value)
            let enumValue = Object.values(enumType).find((e, i) => i === value)
            if (enumValue) {
                currentItem[itemProperty] = enumValue
            }
        }
        this.setState({item: currentItem, unsavedChanges: true})
    }

    render() {
        return <>
            <div className={"form-row"}>
                <TextBox id={"txtSyllabification"}
                         label={this.context.translate(translations.fields.word.article.syllabification)}
                         width={400}
                         required={true}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabification", text)}
                         value={this.state.item.syllabification || ""}
                />

                <TextBox id={"txtSyllabificationPlural"}
                         width={400}
                         label={this.context.translate(translations.fields.word.noun.syllabificationPlural)}
                         required={false}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabificationPlural", text)}
                         value={this.state.item.syllabificationPlural || ""}
                />
            </div>
            <div className={"form-row"}>
                <SelectBox id={"selArticleDefinite"}
                           label={this.context.translate(translations.fields.word.noun.article_definite)}
                           width={395}
                           data={this.articleEnum}
                           required={false}
                           value={EnumValueToValueNumber(ArticleDefiniteEnum, this.state.item.articleDefinite)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeEnum(value, ArticleDefiniteEnum, "articleDefinite")}
                           positioningRow={false}
                />
                <SelectBox id={"selProperNameType"}
                           label={this.context.translate(translations.fields.word.proper_name.proper_name_type)}
                           width={380}
                           data={EnumToEntityArray(ProperNameTypeEnum, translations.enum.proper_name_type, this.context.translate)}
                           required={true}
                           value={EnumValueToValueNumber(ProperNameTypeEnum, this.state.item.properNameType)}
                           readonly={this.props.readonly}
                           onChange={(value) => this.onChangeEnum(value, ProperNameTypeEnum, "properNameType")}
                           positioningRow={false}
                />
            </div>

            {/* Cases */}

            <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.noun.cases)}</div>
            <div className="form-row">
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.first_case)}</div>

                    <div className={"form-row"}>
                        <SelectBox id={"selCaseFirst"}
                                   width={93}
                                   data={this.caseFirstEnum}
                                   required={false}
                                   value={EnumValueToValueNumber(CaseFirstEnum, this.state.item.caseFirst)}
                                   readonly={this.props.readonly}
                                   onChange={(value) => this.onChangeEnum(value, CaseFirstEnum, "caseFirst")}
                                   positioningRow={false}
                        />
                    </div>

                    <TextBox id={"txtCaseFirstValue"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseFirstValue", text)}
                             value={this.state.item.caseFirstValue || ""}
                    />
                </div>

                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.second_case)}</div>

                    <div className={"form-row"}>
                        <SelectBox id={"selCaseSecond"}
                                   width={93}
                                   data={this.caseSecondEnum}
                                   required={false}
                                   value={EnumValueToValueNumber(CaseSecondEnum, this.state.item.caseSecond)}
                                   readonly={this.props.readonly}
                                   onChange={(value) => this.onChangeEnum(value, CaseSecondEnum, "caseSecond")}
                                   positioningRow={false}
                        />
                    </div>

                    <TextBox id={"txtCaseSecondValue"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseSecondValue", text)}
                             value={this.state.item.caseSecondValue || ""}
                    />
                </div>

                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.third_case)}</div>

                    <div className={"form-row"}>
                        <SelectBox id={"selCaseThird"}
                                   width={93}
                                   data={this.caseThirdEnum}
                                   required={false}
                                   value={EnumValueToValueNumber(CaseThirdEnum, this.state.item.caseThird)}
                                   readonly={this.props.readonly}
                                   onChange={(value) => this.onChangeEnum(value, CaseThirdEnum, "caseThird")}
                                   positioningRow={false}
                        />
                    </div>

                    <TextBox id={"txtCaseThirdValue"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseThirdValue", text)}
                             value={this.state.item.caseThirdValue || ""}
                    />
                </div>

                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.fourth_case)}</div>

                    <div className={"form-row"}>
                        <SelectBox id={"selCaseFourth"}
                                   width={93}
                                   data={this.caseFourthEnum}
                                   required={false}
                                   value={EnumValueToValueNumber(CaseFourthEnum, this.state.item.caseFourth)}
                                   readonly={this.props.readonly}
                                   onChange={(value) => this.onChangeEnum(value, CaseFourthEnum, "caseFourth")}
                                   positioningRow={false}
                        />
                    </div>

                    <TextBox id={"txtCaseFourthValue"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseFourthValue", text)}
                             value={this.state.item.caseFourthValue || ""}
                    />
                </div>
            </div>

            <div className={"sub-label"}>{this.context.translate(translations.fields.word.noun.plural)}</div>
            <div className="form-row">
                <div className={"form-column"}>
                    <div className="admin-form-text" style={{width: "200px"}}>die</div>
                    <TextBox id={"txtCaseFirstValuePL"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseFirstValuePL", text)}
                             value={this.state.item.caseFirstValuePL || ""}
                    />
                </div>

                <div className={"form-column"}>
                    <div className="admin-form-text" style={{width: "200px"}}>der</div>
                    <TextBox id={"txtCaseSecondValuePL"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseSecondValuePL", text)}
                             value={this.state.item.caseSecondValuePL || ""}
                    />
                </div>

                <div className={"form-column"}>
                    <div className="admin-form-text" style={{width: "200px"}}>den</div>
                    <TextBox id={"txtCaseThirdValuePL"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseThirdValuePL", text)}
                             value={this.state.item.caseThirdValuePL || ""}
                    />
                </div>

                <div className={"form-column"}>
                    <div className="admin-form-text" style={{width: "200px"}}>die</div>
                    <TextBox id={"txtCaseFourthValuePL"}
                             width={200}
                             required={false}
                             readonly={this.props.readonly}
                             onChange={(text: string) => this.onChangeText("caseFourthValuePL", text)}
                             value={this.state.item.caseFourthValuePL || ""}
                    />
                </div>
            </div>
        </>
    }
}

