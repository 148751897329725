import React from 'react';
import translations from "../../../Framework/translations.json";
import TextBox from "../../../Components/Controls/TextBox";
import {MainContext} from "../../../_base/MainContext";
import Adjective from "../../../_model/Dictionary/Adjective";
import {WordType, WordTypeProps, WordTypeState} from "../WordType";
import {
    CreateAdjective,
    GetAdjective,
    GetAllAdjectiveUseCases,
    UpdateAdjective
} from "../../../_endpoint/WordEndpoint";
import Word from "../../../_model/Dictionary/Word";
import Tag from "../../../_model/Tag";
import WordLookup from "../../../_model/Dictionary/WordLookup";
import WordForm from "../WordForm";
import AdjectiveUseCases from "../../../_model/Dictionary/AdjectiveUseCases";
import {EntityData} from "../../../_model/Entity";
import CheckBoxSwitch from "../../../Components/Controls/CheckBoxSwitch";

export interface IProps extends WordTypeProps {
    wordId?: number
    wordLookups: WordLookup[]
    readonly: boolean
}

interface IState extends WordTypeState {
    item: Adjective
    declension?: string
    unsavedChanges: boolean
    chosenOppositeTags: Tag[]
    useCases: AdjectiveUseCases[]
}

export default class AdjectiveFormPart extends WordType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Adjective(),
            unsavedChanges: false,
            chosenOppositeTags: [],
            useCases: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }
    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.wordLookups.length !== this.props.wordLookups.length) {
            this.fetchWordLookups()
        }
    }

    fetchData = () => {
        if(this.props.wordId) {
            GetAdjective(this.props.wordId).then(
                (itemData) => {
                    let declensionBasis = itemData.declensionStrongMasculineFirst?.substring(0,itemData.declensionStrongMasculineFirst?.length-2)

                    this.setState({item: itemData, declension: declensionBasis}, () => {
                        this.fetchWordLookups()
                        this.fetchUseCases()
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        } else {
            this.fetchWordLookups()
        }
    }
    fetchWordLookups = () => {
        let chosenOpposites = this.state.item ? WordForm.mapLookupToTag(this.state.item.opposites, this.props.wordLookups) : []
        this.setState({chosenOppositeTags: chosenOpposites})
    }
    fetchUseCases = () => {
        GetAllAdjectiveUseCases().then(
            (data) => {
                this.setState({useCases: data})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    // close and success messages are done in word
    onSave = async (word: Word) : Promise<EntityData | undefined> => {
        let result

        if (word.id) {
            result = await UpdateAdjective(this.state.item, word)
        } else {
            result = await CreateAdjective(this.state.item, word)
        }

        this.setState({unsavedChanges: false})
        return result
    }

    onChangeText = (fieldName: string, value: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeTags = (tags: Tag[]) => {
        let currentItem = this.state.item
        currentItem.opposites = tags.map(i => new WordLookup(i.name, i.id))
        this.setState({item: currentItem, chosenOppositeTags: tags, unsavedChanges: true})
    }
    onChangeUseCases = (useCases: AdjectiveUseCases[]) => {
        let currentItem = this.state.item
        currentItem.adjectiveUseCases = useCases
        this.setState({item: currentItem})
    }
    onChangeCheckbox = (fieldName: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = !currentItem[fieldName]
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeDeclension = (value: string) => {
        let newItem = this.state.item

        newItem.declensionStrongMasculineFirst = value + "er"
        newItem.declensionStrongMasculineSecond = value + "en"
        newItem.declensionStrongMasculineThird = value + "em"
        newItem.declensionStrongMasculineFourth = value + "en"

        newItem.declensionStrongFeminineFirst = value + "e"
        newItem.declensionStrongFeminineSecond = value + "er"
        newItem.declensionStrongFeminineThird = value + "er"
        newItem.declensionStrongFeminineFourth = value + "e"

        newItem.declensionStrongNeutralFirst = value + "es"
        newItem.declensionStrongNeutralSecond = value + "en"
        newItem.declensionStrongNeutralThird = value + "em"
        newItem.declensionStrongNeutralFourth = value + "es"

        newItem.declensionStrongPluralFirst = value + "e"
        newItem.declensionStrongPluralSecond = value + "er"
        newItem.declensionStrongPluralThird = value + "en"
        newItem.declensionStrongPluralFourth = value + "e"

        newItem.declensionWeakMasculineFirst = value + "e"
        newItem.declensionWeakMasculineSecond = value + "en"
        newItem.declensionWeakMasculineThird = value + "en"
        newItem.declensionWeakMasculineFourth = value + "en"

        newItem.declensionWeakFeminineFirst = value + "e"
        newItem.declensionWeakFeminineSecond = value + "en"
        newItem.declensionWeakFeminineThird = value + "en"
        newItem.declensionWeakFeminineFourth = value + "e"

        newItem.declensionWeakNeutralFirst = value + "e"
        newItem.declensionWeakNeutralSecond = value + "en"
        newItem.declensionWeakNeutralThird = value + "en"
        newItem.declensionWeakNeutralFourth = value + "e"

        newItem.declensionWeakPluralFirst = value + "en"
        newItem.declensionWeakPluralSecond = value + "en"
        newItem.declensionWeakPluralThird = value + "en"
        newItem.declensionWeakPluralFourth = value + "en"

        newItem.declensionMixedMasculineFirst = value + "er"
        newItem.declensionMixedMasculineSecond = value + "en"
        newItem.declensionMixedMasculineThird = value + "en"
        newItem.declensionMixedMasculineFourth = value + "en"

        newItem.declensionMixedFeminineFirst = value + "e"
        newItem.declensionMixedFeminineSecond = value + "en"
        newItem.declensionMixedFeminineThird = value + "en"
        newItem.declensionMixedFeminineFourth = value + "e"

        newItem.declensionMixedNeutralFirst = value + "es"
        newItem.declensionMixedNeutralSecond = value + "en"
        newItem.declensionMixedNeutralThird = value + "en"
        newItem.declensionMixedNeutralFourth = value + "es"

        newItem.declensionMixedPluralFirst = value + "en"
        newItem.declensionMixedPluralSecond = value + "en"
        newItem.declensionMixedPluralThird = value + "en"
        newItem.declensionMixedPluralFourth = value + "en"

        this.setState({item: newItem, declension: value})
    }

    render() {
        return <>
            <div className={"form-row"}>
                <TextBox id={"txtSyllabificationSingular"}
                         label={this.context.translate(translations.fields.word.adjective.syllabificationSingular)}
                         width={400}
                         required={true}
                         readonly={this.props.readonly}
                         onChange={(text: string) => this.onChangeText("syllabificationSingular", text)}
                         value={this.state.item.syllabificationSingular || ""}
                />
                <CheckBoxSwitch id={"chkIrregular"}
                                label={this.context.translate(translations.fields.word.adjective.irregular)}
                                checked={this.state.item.irregular || false}
                                onChange={() => this.onChangeCheckbox("irregular")}
                                readonly={this.props.readonly}/>
            </div>

            <div className={"form-row"}>
                <TextBox id={"txtUseCases"}
                         width={800}
                         label={this.context.translate(translations.fields.word.adjective.use_cases)}
                         required={false}
                         readonly={this.props.readonly}
                         data={this.state.useCases}
                         tags={this.state.item.adjectiveUseCases}
                         onChangeTags={this.onChangeUseCases}
                         mode={{autoComplete: true, tags: true, restrictCreation: false}}
                         value={""}
                />
            </div>

            <div className={"form-row"}>
                <TextBox id={"txtOpposite"}
                         width={800}
                         label={this.context.translate(translations.fields.word.adjective.opposite)}
                         required={false}
                         readonly={this.props.readonly}
                         data={this.props.wordLookups}
                         tags={this.state.chosenOppositeTags}
                         onChangeTags={(tags) => this.onChangeTags(tags)}
                         mode={{autoComplete: true, tags: true, restrictCreation: true}}
                         value={""}
                />
            </div>

            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.adjective.forms)}</div>
            </div>

            <div className={"form-row"}>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.adjective.basic_form)}</div>
                    <TextBox id={"txtBasicForm"}
                             width={267}
                             required={false}
                             value={this.state.item.basicForm || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("basicForm", value)}
                    />
                </div>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.adjective.comparative)}</div>
                    <TextBox id={"txtComparative"}
                             width={267}
                             required={false}
                             value={this.state.item.comparative || ""}
                             readonly={this.props.readonly}
                             onChange={(value) => this.onChangeText("comparative", value)}
                    />
                </div>
                <div className={"form-column"}>
                    <div className={"sub-label"}>{this.context.translate(translations.fields.word.adjective.superlative)}</div>
                    <div className={"form-row"} >
                        <TextBox id={"txtSuperlativeAm"}
                                 width={30}
                                 style={{paddingRight: 0}}
                                 required={false}
                                 value={"am"}
                                 readonly={true}
                        />

                        <TextBox id={"txtSuperlative"}
                                 width={237}
                                 required={false}
                                 style={{paddingLeft: 0}}
                                 value={this.state.item.superlative || ""}
                                 readonly={this.props.readonly}
                                 onChange={(value) => this.onChangeText("superlative", value)}
                        />
                    </div>
                </div>
            </div>

            <div className="form-row" style={{marginBottom: "5px"}}>
                <div className={"bold-label"} style={{paddingTop: "20px"}}>{this.context.translate(translations.fields.word.adjective.declension)}</div>
            </div>
            <div className={"form-row"}>
                <TextBox id={"txtDeclension"}
                         width={237}
                         required={true}
                         value={this.state.declension || ""}
                         onChange={this.onChangeDeclension}
                         readonly={false}
                />
            </div>

            <div className="form-row" style={{marginBottom: "5px", marginTop: "15px"}}>
                <div className={"sub-label"}>{this.context.translate(translations.fields.word.adjective.declension_strong)}</div>
            </div>
            <table className={"admin-table"} id={"admin-table-adjective-strong-declension"}>
                <tbody>
                <tr>
                    <td className={"table-label"}>{""}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.masculine)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.feminine)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.neutral)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.plural)}</td>
                </tr>

                {/* 1st case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.first_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionStrongMasculine1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "er" : "- er"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongFeminine1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongNeutral1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "es" : "- es"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongPlural1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 2nd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.second_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionStrongMasculine2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongFeminine2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "er" : "- er"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongNeutral2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongPlural2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension+ "er" : "- er"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 3rd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.third_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionStrongMasculine3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension + "em" : "- em"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongFeminine3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension + "er" : "- er"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongNeutral3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "em" : "- em"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongPlural3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 4rd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.fourth_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionStrongMasculine4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongFeminine4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongNeutral4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "es" : "- es"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionStrongPlural4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                </tr>
                </tbody>
            </table>

            <div className="form-row" style={{marginBottom: "5px", marginTop: "15px"}}>
                <div className={"sub-label"}>{this.context.translate(translations.fields.word.adjective.declension_weak)}</div>
            </div>
            <table className={"admin-table"} id={"admin-table-adjective-weak-declension"}>
                <tbody>
                <tr>
                    <td className={"table-label"}>{""}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.masculine)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.feminine)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.neutral)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.plural)}</td>
                </tr>

                {/* 1st case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.first_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionWeakMasculine1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakFeminine1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakNeutral1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakPlural1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 2nd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.second_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionWeakMasculine2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakFeminine2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakNeutral2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakPlural2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 3rd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.third_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionWeakMasculine3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension + "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakFeminine3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension + "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakNeutral3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakPlural3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 4rd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.fourth_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionWeakMasculine4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakFeminine4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakNeutral4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionWeakPlural4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>
                </tbody>
            </table>

            <div className="form-row" style={{marginBottom: "5px", marginTop: "15px"}}>
                <div className={"sub-label"}>{this.context.translate(translations.fields.word.adjective.declension_mixed)}</div>
            </div>
            <table className={"admin-table"} id={"admin-table-adjective-mixed-declension"}>
                <tbody>
                <tr>
                    <td className={"table-label"}>{""}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.masculine)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.feminine)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.neutral)}</td>
                    <td className={"table-label"}>{this.context.translate(translations.fields.word.noun.plural)}</td>
                </tr>

                {/* 1st case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.first_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionMixedMasculine1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "er" : "- er"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedFeminine1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedNeutral1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "es" : "- es"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedPlural1"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 2nd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.second_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionMixedMasculine2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedFeminine2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedNeutral2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedPlural2"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 3rd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.third_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionMixedMasculine3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension + "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedFeminine3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension ? this.state.declension + "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedNeutral3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedPlural3"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>

                {/* 4rd case*/}
                <tr>
                    <td>{this.context.translate(translations.fields.word.noun.fourth_case_simple)}</td>
                    <td>
                        <TextBox id={"txtDeclensionMixedMasculine4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedFeminine4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "e" : "- e"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedNeutral4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "es" : "- es"}
                                 readonly={true}/>
                    </td>
                    <td>
                        <TextBox id={"txtDeclensionMixedPlural4"}
                                 width={160}
                                 required={true}
                                 value={this.state.declension? this.state.declension+ "en" : "- en"}
                                 readonly={true}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    }
}

