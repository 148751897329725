import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import translations from "../../Framework/translations.json";
import SelectBox from "../../Components/Controls/SelectBox";
import TextBox from "../../Components/Controls/TextBox";
import Name from "../../_model/Name";
import {CloneName, CreateName, GetName, UpdateName} from "../../_endpoint/NameEndpoint";
import {
    AdminObjectType,
    Difficulty,
    EnumToEntityArray,
    EnumValueToValueNumber,
    Gender,
    Status
} from "../../Framework/Enums";
import {GetAllLanguages} from "../../_endpoint/MetadataEndpoint";
import Language from "../../_model/Language";
import DualList from "../../Components/Controls/DualList";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

interface IState {
    item: Name
    languages: any[]
    selected: any[]

    unsavedChanges: boolean
}

export default class NameForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Name("", Status.draft),
            languages: [],
            selected: [],
            unsavedChanges: false
        }
    }

    componentDidMount() {
        if(this.props.match.params.id !== Const.CreateNewDataUrl) {
            this.fetchData()
        }
        this.fetchLanguages()
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        GetName(+this.props.match.params.id).then(
            (itemData) => {
                if(itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                this.setState({
                    item: itemData,
                    selected: itemData.languages ? itemData.languages.map(language => {return language.id}) : []
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchLanguages = () => {
        GetAllLanguages().then(
            (data) => {
                let languageNames = data.map(language => {return {value: language.id, label: language.name}})
                this.setState( {
                    languages: languageNames
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    isFormValid = () => {
        const form1 = document.getElementById("formData1")  as HTMLFormElement;
        const form2 = document.getElementById("formData2")  as HTMLFormElement;
        return form1 && form1.reportValidity() && form2 && form2.reportValidity()
    }

    onSave = async (close: boolean) => {

        if (this.isFormValid()) {

            //Set selection as languages for item
            if(this.state.selected) {
                let saveItem = this.state.item
                saveItem.languages = this.state.selected.map(languageId => {return new Language("", languageId)})
                this.setState({item: saveItem})
            }

            try {
                if (this.state.item.id) {
                    await UpdateName(this.state.item)
                    this.setState({unsavedChanges: false}, () => {
                        if (close) { this.onCancel() }
                    })
                }
                else {
                    let result = await CreateName(this.state.item)
                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) { this.onCancel() }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || "/maintenance/names")
        )
    }
    onCopy = () => {
        if (this.isFormValid()) {

            this.onSave(false).then(
                () => {
                    CloneName(this.state.item, this.state.item.name + " - " + this.context.translate(translations.text_fragment.copy)).then(
                        (result: Name) => {
                            this.setState({item: result})
                            this.props.history.push("/maintenance/names/" + result.id)
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.copy_error))
                        }
                    )
                }
            )
        }
    }
    onSetState = (status: Status, user?: User) => {
        if(this.state.item) {
            const item = this.state.item
            Name.setStatus(item, status, user)

            this.setState({item: item}, () => this.onSave(false))
            return true
        }
        return false
    }

    onChangeName = (value: string) => {
        let currentItem = this.state.item
        currentItem.name = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeGender = (value: number) => {
        // Get enum value by index (value)
        let gender = Object.values(Gender).find((e, i) => i === value)
        if (gender) {
            let currentItem = this.state.item
            currentItem.gender = gender
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeNotes = (value: string) => {
        let currentItem = this.state.item
        currentItem.notes = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeGenitive = (value: string) => {
        let currentItem = this.state.item
        currentItem.genitive = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeDifficulty = (value: number) => {
        // Get enum value by index (value)
        let difficulty = Object.values(Difficulty).find((e, i) => i === value)
        if (difficulty) {
            let currentItem = this.state.item
            currentItem.difficulty = difficulty
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangePhonemic = () => {
        let currentItem = this.state.item
        currentItem.phonemic = !currentItem.phonemic
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeLanguages = (selected) => {
        this.setState({ selected , unsavedChanges: true});
    }
    onChangeSyllabification = (value: string, property: string) => {
        let currentItem = this.state.item
        currentItem[property] = value
        this.setState({item: currentItem, unsavedChanges: true})
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }

    render() {
        return <AdminDataForm
            onSave={this.onSave}
            onCancel={this.onCancel}
            onCopy={this.onCopy}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={AdminObjectType.name}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

                <div className={"admin-form-content"}>

                    <form id={"formData1"} className="admin-form-input" onSubmit={() => { return false} }>

                        <FormHeader
                            number={this.state.item.id}
                            status={this.state.item.status}
                            statusTranslationPath={translations.enum.status}
                            createdBy={this.state.item.createdBy}
                            ownerId={this.state.item.ownerId}
                        />

                        <div className="form-row">
                            <TextBox id={"txtName"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name)}
                                     required={true}
                                     readonly={this.isReadonly()}
                                     onChange={this.onChangeName}
                                     value={this.state.item.name}
                            />

                            <SelectBox id={"selGender"}
                                       width={500}
                                       label={this.context.translate(translations.fields.name_fields.gender)}
                                       data={EnumToEntityArray(Gender, translations.enum.gender, this.context.translate)}
                                       required={true}
                                       readonly={this.isReadonly()}
                                       value={EnumValueToValueNumber(Gender, this.state.item.gender)}
                                       onChange={this.onChangeGender}
                                       positioningRow={false} />
                        </div>

                        <div className="form-row">
                            <TextBox id={"txtNotes"}
                                     width={1000}
                                     label={this.context.translate(translations.fields.name_fields.notes)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     onChange={this.onChangeNotes}
                                     value={this.state.item.notes || ""}
                            />
                        </div>
                    </form>

                    <DualList
                        options={this.state.languages}
                        selected={this.state.selected}
                        width={1000}
                        marginTop={30}
                        marginLeft={0}
                        disabled={this.isReadonly()}
                        label={this.context.translate(translations.fields.name_fields.languageForName)}
                        onChange={this.onChangeLanguages}
                    />

                    <form id={"formData2"} className="admin-form-input" onSubmit={() => { return false} }>
                        <div className="form-row">
                            <TextBox id={"txtGenitive"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name_fields.genitive)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     onChange={this.onChangeGenitive}
                                     value={this.state.item.genitive || ""}
                            />
                            <SelectBox id={"selDifficulty"}
                                       width={500}
                                       label={this.context.translate(translations.fields.name_fields.difficulty)}
                                       data={EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate)}
                                       required={true}
                                       value={EnumValueToValueNumber(Difficulty, this.state.item.difficulty)}
                                       readonly={this.isReadonly()}
                                       onChange={this.onChangeDifficulty}
                                       positioningRow={false}
                            />
                        </div>

                        <div className="form-row">

                            <CheckBoxSwitch id={"ckbPhonemic"}
                                            width={250}
                                            label={this.context.translate(translations.fields.name_fields.phonemic)}
                                            checked={this.state.item.phonemic || false}
                                            readonly={this.isReadonly()}
                                            onChange={this.onChangePhonemic}
                            />

                        </div>

                        <div className={"form-row"}>
                            <TextBox id={"txtSyllableDivide"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name_fields.syllabification_divide)}
                                     required={true}
                                     readonly={this.isReadonly()}
                                     onChange={(value) => this.onChangeSyllabification(value, "syllabificationDivide")}
                                     value={this.state.item.syllabificationDivide || ""}
                            />
                            <TextBox id={"txtSyllableDivideGenitive"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name_fields.syllabification_divide_genitive)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     onChange={(value) => this.onChangeSyllabification(value, "syllabificationDivideGenitive")}
                                     value={this.state.item.syllabificationDivideGenitive || ""}
                            />
                        </div>
                        <div className={"form-row"}>
                            <TextBox id={"txtSyllableSpeak"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name_fields.syllabification_speak)}
                                     required={true}
                                     readonly={this.isReadonly()}
                                     onChange={(value) => this.onChangeSyllabification(value, "syllabificationSpeak")}
                                     value={this.state.item.syllabificationSpeak || ""}
                            />
                            <TextBox id={"txtSyllableSpeakGenitive"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name_fields.syllabification_speak_genitive)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     onChange={(value) => this.onChangeSyllabification(value, "syllabificationSpeakGenitive")}
                                     value={this.state.item.syllabificationSpeakGenitive || ""}
                            />
                        </div>
                    </form>

                </div>
        </AdminDataForm>
    }
}

