import React from "react";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import {MainContext} from "../../../../_base/MainContext";
import translations from "../../../../Framework/translations.json";
import {WDColorPicker} from "../../General/WDColorPicker";
import {TooltipPosition, TooltipText} from "../../../../Components/Tooltips";
import {Slider} from "../../../../Components/Controls/Slider";

/**
 * Submenu in Toolbar for colors
 * Effects selected element(s)
 * @param lineStyle - is needed if section to change text decoration line style should be shown
 */

interface IProps {
    id: number
    tooltip: TooltipText
    tooltipDisabled?: TooltipText
    tooltipPosition?: TooltipPosition
    open: boolean
    clickable: boolean

    currentSelectedColor?: string
    dialogHeight?: number
    customColors?: string[]
    lineStyle?: LineStyle
    lineWidth?: LineWidth
    transparency: boolean
    transparencyTitle?: string
    transparencyAmount?: number

    onChangeTransparency?: (transparency: number) => void
    onToggleButton: (id: number) => void
    onChangeColor: (color: string) => void
}
interface IState {
    showMore: boolean
}

export class LineStyle {
    color: string
    thickness?: string
    onChangeLineStyle: (lineStyle: string, thickness?: number, thicknessUnit?: string) => void

    constructor(color: string, onChangeLineStyle: (lineStyle: string, thickness?: number) => void, thickness?: string) {
        this.color = color
        this.thickness = thickness
        this.onChangeLineStyle = onChangeLineStyle
    }
}
export class LineWidth {
    lineWidth: number
    maxWidth: number
    minWidth?: number
    onChangeWidth: (lineWidth: number) => void

    constructor(lineWidth: number, onChangeWidth: (lineWidth: number) => void, maxWidth: number, minWidth?: number) {
        this.lineWidth = lineWidth
        this.maxWidth = maxWidth
        this.minWidth = minWidth
        this.onChangeWidth = onChangeWidth
    }
}

export class WDToolbarButtonColorPicker extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state);

        this.state = {
            showMore: false
        }
    }

    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    onChangeColor = (color: string) => {
        this.props.onChangeColor(color)
    }
    onChangeLineStyle = (event: React.MouseEvent, style: string, thickness?: number, thicknessUnit?: string) => {
        this.props.lineStyle && this.props.lineStyle.onChangeLineStyle(style, thickness, thicknessUnit)
    }
    onChangeWidth = (width: number) => {
        this.props.lineWidth && this.props.lineWidth.onChangeWidth(width)
    }
    onShowOtherColorThemes = (more: boolean) => {
        this.setState({showMore: more})
    }
    onChangeTransparency = (transparency: number) => {
        this.props.onChangeTransparency?.(transparency)
    }

    render() {
        let height = this.props.dialogHeight ? this.props.dialogHeight + "px" : "335px"

        return <div id={"color-picker-" + this.props.id} style={{position: "relative"}}>

            <WDToolbarButton id={this.props.id}
                             tooltip={this.props.tooltip}
                             tooltipDisabled={this.props.tooltipDisabled}
                             tooltipPosition={this.props.tooltipPosition}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={this.props.open}
                             onToggleButton={this.onToggleList}
            />

            { /* Color picker dialog */}
            {this.props.open &&
            <div className={"ws-designer-toolbar-options ws-designer-toolbar-arrow-options"}
                 style={{ width: "225px", height: height }}>

                {this.props.onChangeTransparency && this.props.transparencyAmount !== undefined &&
                <div className={"ws-designer-options-section"}>
                    <div className={"ws-designer-options-section-label"}>
                        {this.context.translate(translations.toolbar.color_picker.transparency)}
                    </div>
                    <Slider value={this.props.transparencyAmount}
                           unit={"%"}
                           minValue={0}
                           maxValue={100}
                           onChangeValue={this.onChangeTransparency}
                    />
                </div>}

                {this.props.lineWidth &&
                    <div className={"ws-designer-options-section"}>
                        <div className={"ws-designer-options-section-label"}>
                            {this.context.translate(translations.toolbar.color_picker.line_width)}
                        </div>
                        <Slider value={this.props.lineWidth.lineWidth}
                                unit={"px"}
                                minValue={this.props.lineWidth.minWidth || 1}
                                maxValue={this.props.lineWidth.maxWidth}
                                onChangeValue={this.onChangeWidth}
                        />
                    </div>
                }

                <WDColorPicker onChangeColor={this.onChangeColor}
                               currentSelectedColor={this.props.currentSelectedColor}
                               customColors={this.props.customColors}
                               transparency={this.props.transparency}
                               transparencyTitle={this.props.transparencyTitle} />

                {this.props.lineStyle &&
                <div className={"ws-designer-options-section"}>
                    <div className={"ws-designer-options-section-label"}>
                        {this.context.translate(translations.toolbar.color_picker.line_style)}
                    </div>

                    <div className={"ws-designer-color-picker-line-style"}
                         onClick={(e) => this.onChangeLineStyle(e, "solid")}>
                        <div style={{borderColor: this.props.lineStyle?.color || "black", borderBottomStyle: "solid",
                            borderWidth: "medium"}}/>
                    </div>

                    <div className={"ws-designer-color-picker-line-style"}
                         onClick={(e) => this.onChangeLineStyle(e, "solid", 5, "px")}>
                        <div style={{borderColor: this.props.lineStyle?.color || "black", borderBottomStyle: "solid",
                            borderWidth: "thick"}}/>
                    </div>

                    <div className={"ws-designer-color-picker-line-style"}
                         onClick={(e) => this.onChangeLineStyle(e, "double")}>
                        <div style={{borderColor: this.props.lineStyle?.color || "black", borderBottomStyle: "double"}}/>
                    </div>

                    <div className={"ws-designer-color-picker-line-style"}
                         onClick={(e) => this.onChangeLineStyle(e, "dotted")}>
                        <div style={{borderColor: this.props.lineStyle?.color || "black", borderBottomStyle: "dotted"}}/>
                    </div>

                    <div className={"ws-designer-color-picker-line-style"}
                         onClick={(e) => this.onChangeLineStyle(e, "dashed")}>
                        <div style={{borderColor: this.props.lineStyle?.color || "black", borderBottomStyle: "dashed"}}/>
                    </div>

                    <div className={"ws-designer-color-picker-line-style-img"}
                         onClick={(e) => this.onChangeLineStyle(e, "wavy")}>
                        <img className={"svg-color-" + this.props.lineStyle.color.toUpperCase().replace("#", "")}
                             alt={""} src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "underline_wavy.svg"}/>
                    </div>
                </div>
                }
            </div>
            }

        </div>
    }
}
