import React from "react";
import {WDToolbarAction} from "../../WDToolbarAction";
import {WDToolbarButton} from "../WDToolbarButton";
import {ImagePath} from "../../../../Framework/CategoryImage";
import translations from "../../../../Framework/translations.json";
import {MainContext} from "../../../../_base/MainContext";
import {WDColorPicker} from "../../General/WDColorPicker";
import Const from "../../../../Framework/Const";
import {TooltipText} from "../../../../Components/Tooltips";

/**
 * Button (Yes/No) in Toolbar with submenu
 * Effects selected element(s)
 * Yes: correcting margin should be shown at specific position
 * No:  correcting margin should be hidden
 */

interface IProps {
    id: number
    iconImageKey: string
    width: number
    height: number
    open: boolean
    clickable: boolean
    initialColor?: string

    onToggleButton: (id: number) => void
    onActionButton: (action: WDToolbarAction, data: any) => void
}

interface IState {
    color: string
}

export enum IconType {
    dot = "dot",
    ring = "ring",
    pen = "pen",
    star1 = "star1",
    cross = "cross",
    check = "check"
}

export class WDToolbarButtonInsertIcon extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props)

        this.state = {
            color: this.props.initialColor || Const.COLOR_RED
        }
    }

    onToggleList = (id: number) => {
        this.props.onToggleButton(id)
    }

    onChangeIcon = (id: string) => {
        let valuePressed: any = {"icon": id.toUpperCase(), "color": this.state.color}
        this.props.onActionButton(WDToolbarAction.INSERT_ICON, valuePressed)

        // close submenu
        this.props.onToggleButton(-1)
    }
    onChangeColor = (color: string) => {
        this.setState({color: color})
    }

    onToggleIcon = () => {
        let valuePressed: any = {"icon": this.props.iconImageKey, "color": this.state.color}
        this.props.onActionButton(WDToolbarAction.INSERT_ICON, valuePressed)
    }

    render() {
        return <li
            key={"ws-designer-toolbar-button"}
            className={"ws-designer-toolbar-item"}
            id={"insertIcon"}>

            <WDToolbarButton id={this.props.id}
                             icon={ImagePath.getButtonUrl() + "insert_" + this.props.iconImageKey.toLowerCase() + ".svg"}
                             iconClassSelected={"ws-designer-toolbar-button-img"}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-ml"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.insert_icon), this.context.translate(translations.tooltip.insert_icon))}
                             onToggleButton={this.onToggleIcon}
                             color={this.state.color}
                             buttonWidth={28}
                             arrow={false}
                             enabled={this.props.clickable}
                             pressed={false} />

            <WDToolbarButton id={this.props.id}
                             enabled={this.props.clickable}
                             icon={ImagePath.getButtonUrl() + "dropdown_arrow_down.svg"}
                             tooltip={new TooltipText(this.context.translate(translations.toolbar.insert_icon), this.context.translate(translations.tooltip.insert_icon))}

                             iconClassSelected={"ws-designer-toolbar-button-options"}
                             iconClassUnselected={"ws-designer-toolbar-button-options"}

                             arrow={false}
                             cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                             buttonHeight={32}
                             buttonWidth={15}
                             imageHeight={5}
                             imageWidth={5}

                             onToggleButton={this.props.onToggleButton}
                             pressed={this.props.open} />

            {this.props.open &&
                <div className={"ws-designer-toolbar-options"}>
                    <div className={"ws-designer-options-section-row"}
                         style={{
                             width: this.props.width,
                             height: this.props.height
                         }}>

                        {Object.values(IconType).map(type => {
                            return <WDToolbarButton id={this.props.id}
                                                    key={this.props.id + "-" + type}
                                                    icon={ImagePath.getButtonUrl() + "insert_" + type + ".svg"}
                                                    iconClassSelected={"ws-designer-toolbar-button-img"}
                                                    cssClass={"ws-designer-toolbar-button"}
                                                    onToggleButton={() => this.onChangeIcon(type)}
                                                    arrow={false}
                                                    enabled={this.props.clickable}
                                                    pressed={false} />
                            })
                        }

                    </div>

                    <WDColorPicker transparency={false} onChangeColor={this.onChangeColor} />
                </div>
            }
        </li>
    }
}
