import React from 'react';
import {Rule} from "../../_model/Rule";
import {
    CloneRule,
    CreateRule,
    GetRule,
    GetRuleCategory,
    GetRulePillar,
    GetRuleType,
    UpdateRule
} from "../../_endpoint/RuleEndpoint";
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import LessonSubject from "../../_model/LessonSubject";
import {CreateTag, GetLessonSubject, GetSchoolLevel, GetTags} from "../../_endpoint/MetadataEndpoint";
import SchoolLevel from "../../_model/SchoolLevel";
import RuleCategory from "../../_model/RuleCategory";
import RuleType from "../../_model/RuleType";
import RulePillar from "../../_model/RulePillar";
import SelectBox from "../../Components/Controls/SelectBox";
import TextBox from "../../Components/Controls/TextBox";
import CheckBoxList from "../../Components/Controls/CheckBoxList";
import {
    CreateWorksheet,
    CreateWorksheetPage,
    GetWorksheetItemTypes,
    GetWorksheetThumbnail
} from "../../_endpoint/WorksheetEndpoint";
import {Worksheet} from "../../_model/Worksheet";
import {AdminObjectType, Status, WSPageFormat} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import Tag from "../../_model/Tag";
import {PageBorderPosition} from "../../Components/PageBorder/WDPageBorder";
import {TreeView} from "../../Components/Controls/TreeView";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import DualList from "../../Components/Controls/DualList";
import {WSContextType} from "../../Designer/Elements/WSContext";
import {WorksheetPage} from "../../_model/WorksheetPage";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> {
}

interface IState {
    item: Rule
    unsavedChanges: boolean

    worksheetThumbnail?: string
    lessonSubjects: LessonSubject[]
    schoolLevels: SchoolLevel[]
    ruleCategories: RuleCategory[]
    ruleTypes: RuleType[]
    rulePillar: RulePillar[]
    tags?: Tag[]

    worksheetItemTypes: any[]
    selectedWorksheetItemTypes: any[]
}

export default class RuleForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            item: new Rule("", Status.draft),
            unsavedChanges: false,
            lessonSubjects: [],
            schoolLevels: [],
            ruleCategories: [],
            ruleTypes: [],
            rulePillar: [],
            worksheetItemTypes: [],
            selectedWorksheetItemTypes: []
        }
    }

    componentDidMount() {
        if (this.props.match.params.id !== Const.CreateNewDataUrl) {
            this.fetchData()
        }

        GetLessonSubject().then((result) => {
            this.setState({lessonSubjects: result})
        })
        GetRuleCategory().then((result) => {
            this.setState({ruleCategories: result})
        })
        GetRulePillar().then((result) => {
            this.setState({rulePillar: result})
        })
        GetTags().then((result) => {
            this.setState({tags: result})
        })
        GetSchoolLevel().then((result) => {
            let withoutClasses = result.filter(i => {
                let isLeaf = result.find(leaf => leaf.parent?.id === i.id)
                return isLeaf !== undefined
            })

            this.setState({schoolLevels: withoutClasses})
        })
        GetWorksheetItemTypes().then((result) => {
            this.setState({worksheetItemTypes: result.filter(wsType => wsType.usedForRules === true)})
        })
    }

    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        GetRule(+this.props.match.params.id).then(
            (itemData) => {

                if (itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                if (itemData.worksheet) {
                    GetWorksheetThumbnail(itemData.worksheet.id).then((worksheet) => {
                        this.setState({worksheetThumbnail: worksheet.thumbnail})
                    }, () => {
                        // Ignore error, when no thumbnail is available the default image is shown
                    })
                }

                if (itemData.lessonSubject && itemData.lessonSubject.id) {
                    GetRuleType(itemData.lessonSubject.id).then((result) => {
                        this.setState({ruleTypes: result})
                    })
                }

                this.setState({
                    item: itemData,
                    selectedWorksheetItemTypes: itemData.worksheetItemTypes ? itemData.worksheetItemTypes.map(entry => {
                        return entry.id
                    }) : []
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    getWorksheetName = () => {
        return ("WS-Rule: " + this.state.item.name).substr(0, 100)
    }

    isFormValid = () => {
        const form = document.getElementById("formData") as HTMLFormElement;
        return form && form.reportValidity()
    }

    onSave = async (close: boolean) => {
        if (this.isFormValid()) {
            try {
                let currentItem = this.state.item

                // Save selected worksheet item types
                currentItem.worksheetItemTypes = this.state.worksheetItemTypes.filter(
                    item => this.state.selectedWorksheetItemTypes.find(entry => entry === item.id) !== undefined
                )

                // Added new collection, needs to be created
                let tags: Tag[] = []
                if (currentItem.tags) {
                    tags = currentItem.tags.filter(i => i.id !== undefined)
                    for (const i1 of currentItem.tags.filter(i => i.id === undefined)) {
                        tags.push(await CreateTag(i1));
                    }
                }
                currentItem.tags = tags

                if (currentItem.id) {
                    await UpdateRule(this.state.item)
                    this.setState({unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                } else {
                    let result = await CreateRule(this.state.item)
                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || "/maintenance/rules")
        )
    }
    onCopy = () => {
        if (this.isFormValid()) {

            this.onSave(false).then(
                () => {
                    CloneRule(this.state.item, this.state.item.name + " - " + this.context.translate(translations.text_fragment.copy)).then(
                        (result: Rule) => {
                            this.setState({item: result})

                            this.props.history.push("/maintenance/rules/" + result.id)
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.copy_error))
                        }
                    )
                }
            )
        }
    }
    onSetState = (status: Status, user?: User) => {
        if (this.state.item) {
            const item = this.state.item
            Rule.setStatus(item, status, user)

            this.setState({item: item}, () => this.onSave(false))
            return true
        }
        return false
    }
    onClickWorksheet = async () => {
        if (this.state.item.worksheet) {
            window.open(process.env.PUBLIC_URL + "/designer/" + this.state.item.worksheet.id)
        } else {
            // Create worksheet and open
            let worksheetToCreate = new Worksheet(1, this.getWorksheetName(), WSContextType.rule, WSPageFormat.A4, true)
            try {
                let ws: Worksheet = await CreateWorksheet(worksheetToCreate)

                let p = new WorksheetPage(WorksheetPage.getNewPageKey(), false,
                    PageBorderPosition.getDefaultPageBorder(), PageBorderPosition.getNoPageBorder(), 1, true)

                let page = await CreateWorksheetPage(p, ws.id)
                ws.pages = []
                ws.pages.push(page)

                const item = this.state.item
                item.worksheet = new Worksheet(ws.id, ws.name, ws.context, ws.format, ws.editingAllowed)
                this.setState({item: item})

                // save changes
                await this.onSave(false)

                // open worksheet
                window.open(process.env.PUBLIC_URL + "/designer/" + item.worksheet.id)
            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.saving_error))
            }
        }
    }

    onChangeRuleTitle = (value: string) => {
        const item = this.state.item
        item.name = value
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeRuleDescription = (value: string) => {
        const item = this.state.item
        item.description = value
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeRuleCategory = (value: number) => {
        const item = this.state.item
        item.ruleCategory = new RuleCategory("", value)
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeLessonSubject = (value: number) => {
        const item = this.state.item
        item.lessonSubject = new LessonSubject("", "", value)

        // rule types are specifically loaded depending on lesson subject
        GetRuleType(value).then((result) => {
            this.setState({ruleTypes: result})
        })

        this.setState({item: item, unsavedChanges: true})
    }
    onChangeRulePillar = (selected: RulePillar[]) => {
        let currentItem = this.state.item
        currentItem.rulePillars = selected
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeRuleTypes = (selected: RuleType[]) => {
        selected.forEach((i) => {
            i.lessonTypeId = this.state.item.lessonSubject?.id
        })

        const item = this.state.item
        item.ruleTypes = selected
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeSchoolLevel = (selected: SchoolLevel[]) => {
        const item = this.state.item

        item.schoolLevels = selected.filter((item, i, array) => {
            return item.parent === null || array.find(it => it.id === item.parent?.id) !== undefined
        })
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeTags = (tags: Tag[]) => {
        let item = this.state.item
        item.tags = tags
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeWorksheetItemTypes = (selected) => {
        this.setState({selectedWorksheetItemTypes: selected, unsavedChanges: true});
    }

    getAuthor = () => {
        if (this.state.item.createdBy) {
            return this.state.item.createdBy?.firstname + " " + this.state.item.createdBy?.lastname
        }

        return ""
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }

    render() {
        return <AdminDataForm
            onSave={this.onSave}
            onCopy={this.onCopy}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={AdminObjectType.rule}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

            <div className={"admin-form-content"}>

                <form id={"formData"} className="admin-form-input" onSubmit={() => {
                    return false
                }}>
                    <FormHeader
                        number={this.state.item.id}
                        status={this.state.item.status}
                        statusTranslationPath={translations.enum.status}
                        createdBy={this.state.item.createdBy}
                        ownerId={this.state.item.ownerId}
                    />

                    <div className="form-row">
                        <SelectBox id={"selLessonSubject"}
                                   label={this.context.translate(translations.fields.lesson_subject)}
                                   width={484}
                                   style={{marginRight: "10px"}}
                                   data={this.state.lessonSubjects}
                                   onChange={this.onChangeLessonSubject}
                                   value={this.state.item.lessonSubject?.id}
                                   required={true}
                                   readonly={this.isReadonly()}
                                   positioningRow={false}
                        />

                        <SelectBox id={"selRuleCategory"}
                                   label={this.context.translate(translations.fields.rule.category)}
                                   width={500}
                                   data={this.state.ruleCategories}
                                   onChange={this.onChangeRuleCategory}
                                   value={this.state.item.ruleCategory?.id}
                                   required={true}
                                   readonly={this.isReadonly()}
                                   positioningRow={false}
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group" style={{width: "500px", paddingRight: "10px", paddingLeft: "0"}}>
                            <div className="form-row">
                                <TextBox id={"txtTitle"}
                                         width={500}
                                         label={this.context.translate(translations.fields.title)}
                                         required={true}
                                         readonly={this.isReadonly()}
                                         onChange={this.onChangeRuleTitle}
                                         value={this.state.item.name}
                                />
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtDescription"}
                                         width={500}
                                         label={this.context.translate(translations.fields.description)}
                                         required={false}
                                         multiline={true}
                                         readonly={this.isReadonly()}
                                         onChange={this.onChangeRuleDescription}
                                         value={this.state.item.description || ""}
                                />
                            </div>

                            <div className="form-row">
                                <TreeView
                                    id={"lstSubSchoolLevel"}
                                    label={this.context.translate(translations.fields.exercise.school_level)}
                                    data={this.state.schoolLevels}
                                    selectedData={this.state.item?.schoolLevels || []}
                                    required={false}
                                    readonly={this.isReadonly()}
                                    parent={"parent"}
                                    onChange={this.onChangeSchoolLevel}
                                />
                            </div>

                            <div className="form-row">
                                <CheckBoxList
                                    id={"lstRuleType"}
                                    label={this.context.translate(translations.fields.rule.rule_type)}
                                    data={this.state.ruleTypes}
                                    selectedData={this.state.item.ruleTypes}
                                    required={false}
                                    readonly={this.isReadonly()}
                                    onChange={this.onChangeRuleTypes}
                                />
                            </div>

                            <div className={"form-row"}>
                                <TextBox id={"txtTags"}
                                         width={800}
                                         label={this.context.translate(translations.fields.image.tags)}
                                         required={false}
                                         readonly={this.isReadonly()}
                                         data={this.state.tags}
                                         tags={this.state.item.tags || []}
                                         onChangeTags={this.onChangeTags}
                                         mode={{autoComplete: true, tags: true}}
                                         value={""}
                                />
                            </div>

                            {this.state.item.lessonSubject && this.state.item.lessonSubject.id === 1 &&
                                <div className="form-row">
                                    <CheckBoxList
                                        id={"lstRulePillar"}
                                        label={this.context.translate(translations.fields.rule.pillar)}
                                        data={this.state.rulePillar}
                                        selectedData={this.state.item.rulePillars}
                                        required={false}
                                        readonly={this.isReadonly()}
                                        onChange={this.onChangeRulePillar}
                                    />
                                </div>
                            }

                        </div>

                        {this.state.item.id && (
                            <div className={"admin-form-worksheet"}>
                                {this.state.worksheetThumbnail ?
                                    <img src={"data:image/jpeg;base64," + this.state.worksheetThumbnail} width={350}
                                         alt={this.context.translate(translations.text.worksheet)}
                                         onClick={this.onClickWorksheet}
                                         draggable={"false"}
                                         onContextMenu={(e) => e.preventDefault() }
                                    />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                        width={350} alt={this.context.translate(translations.text.worksheet)}
                                        onClick={this.onClickWorksheet}
                                        draggable={"false"}
                                        onContextMenu={(e) => e.preventDefault() }
                                    />
                                }
                            </div>
                        )}

                    </div>
                </form>

                <DualList
                    options={this.state.worksheetItemTypes.map((entry) => {
                        return {value: entry.id, label: entry.name}
                    })}
                    selected={this.state.selectedWorksheetItemTypes}
                    width={1000}
                    marginTop={0}
                    marginLeft={0}
                    disabled={this.isReadonly()}
                    label={this.context.translate(translations.fields.rule.worksheetItemType)}
                    onChange={this.onChangeWorksheetItemTypes}
                />

            </div>
        </AdminDataForm>
    }
}

