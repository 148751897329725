import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import translations from "../../Framework/translations.json";
import TextBox from "../../Components/Controls/TextBox";
import {AdminObjectType, EnumToEntityArray, EnumValueToValueNumber, Status} from "../../Framework/Enums";
import Image, {ImageAlignment, ImageColor, ImageFilter, ImageFilterAdmin, ImageProperty} from "../../_model/Image";
import {
    CloneImage,
    CreateImage, CreateImageAlternative,
    CreateImageCollection,
    CreateImageFamily, GetImageAlternative,
    GetImageCollections,
    GetImageDetails, GetImageFamilies, GetImagesByAlternative, GetImagesByFamily,
    GetImageGalleryFiltered,
    UpdateImage
} from "../../_endpoint/ImageEndpoint";
import SelectBox from "../../Components/Controls/SelectBox";
import ImageUploading, {ImageListType} from "react-images-uploading";
import {ImagePath} from "../../Framework/CategoryImage";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import Conf from "../../Framework/Conf";
import {CreateOriginator, CreateTag, GetOriginator, GetTags} from "../../_endpoint/MetadataEndpoint";
import Originator from "../../_model/Originator";
import ImageCollection from "../../_model/ImageCollection";
import Tag from "../../_model/Tag";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {Modal} from "../../Components/Modal";
import ImageFamily from "../../_model/ImageFamily";
import {ImageGroupListItem} from "./ImageGroupListItem";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import {Coords} from "../../Framework/Coords";
import {Util} from "../../Framework/Util";
import {ButtonInfo} from "../../Components/Controls/ButtonList";
import DualList from "../../Components/Controls/DualList";
import {GetThirdLayerToolboxItemsByExerciseType} from "../../_endpoint/MenuEndpoint";
import {MenuExerciseType} from "../../Components/Menu/Menu";
import _ from "lodash";
import {MenuItem} from "../../_model/MenuItem";
import {ToolboxGroup, ToolboxGroupElements} from '../../Components/Menu/ToolboxElement';
import {ImageListFilter} from "./ImageListFilter";
import {WSContextType} from "../../Designer/Elements/WSContext";
import {ImageGallery} from "../../Components/Images/ImageGallery";
import {ImageSmallListItem} from "./ImageSmallListItem";
import {ValidationError} from "../../Framework/Error/ValidationError";
import ImageAlternative from '../../_model/ImageAlternative';
import {ImageGroupModal} from "./ImageGroupModal";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

enum ImageType {
    main,
    thumb
}

interface IState {
    item: Image
    selected: any[]
    images: ImageListType
    thumbnails: ImageListType

    menuItems: MenuItem[]
    selectedMenuItems: number[]

    showImageFamilyModal: boolean
    showImageAlternativeModal: boolean
    showImageConnectionModal: boolean

    connectionImagePage: number
    connectionImages?: Image[]
    selectedConnectionImage?: Image

    originator?: Originator[]
    collection?: ImageCollection[]
    tags?: Tag[]

    unsavedChanges: boolean
}

export default class ImageForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    dualListOptions: ToolboxGroup[] = []
    pageFrameImageConnectionFilter: ImageFilter = new ImageFilter()

    constructor(props: MatchProps) {
        super(props)

        this.state = {
            item: new Image("", Status.draft),
            selected: [],
            images: [],
            thumbnails: [],
            showImageFamilyModal: false,
            showImageAlternativeModal: false,
            showImageConnectionModal: false,
            connectionImagePage: 0,
            unsavedChanges: false,
            menuItems: [],
            selectedMenuItems: []
        }

        this.pageFrameImageConnectionFilter = this.initPageConnectionFilter()
    }

    componentDidMount() {
        if(this.props.match.params.id !== Const.CreateNewDataUrl) {
            this.fetchData(+this.props.match.params.id)
        }

        this.fetchOriginator()
        this.fetchCollections()
        this.fetchTags()
        this.fetchMenuRelation()
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData(+this.props.match.params.id)
        }
    }

    initPageConnectionFilter = () : ImageFilter => {
        let filter: ImageFilter = this.pageFrameImageConnectionFilter
        filter.alignment = [ImageAlignment.portrait, ImageAlignment.landscape]
        filter.pageFrame = true
        filter.collectionId = undefined
        filter.originatorId = undefined
        filter.property = ImageListFilter.all_properties
        filter.color = ImageListFilter.all_colors
        filter.hasCounterpartImage = false
        filter.adminFilter = new ImageFilterAdmin()

        return filter
    }

    fetchData = (id: number) => {
        if (id !== null) {
            GetImageDetails(id).then(
                (itemData) => {
                    if(itemData.status === Status.published) {
                        this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                    }

                    this.setState({
                        item: itemData,
                        selectedMenuItems: itemData.toolboxItems ? itemData.toolboxItems.map(entry => {
                            return entry.id
                        }) : []
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        }
    }
    fetchOriginator = () => {
        GetOriginator().then(
            (originator) => {
                this.setState({ originator: originator })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchCollections = () => {
        GetImageCollections().then(
            (collection) => {
                this.setState({ collection: collection })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchTags = () => {
        GetTags().then(
            (tags) => {
                this.setState({ tags: tags })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchMenuRelation = () => {
        GetThirdLayerToolboxItemsByExerciseType(MenuExerciseType.image).then(
            (toolboxItems) => {
                let groupedElements = _.groupBy(toolboxItems, "parentId.id")
                let data: ToolboxGroup[] = []

                Object.keys(groupedElements).forEach(k => {
                    let element = toolboxItems.filter(e => e.parentId?.id === +k)
                    let o: ToolboxGroup =  new ToolboxGroup(element[0]?.parentId?.parentId?.name + " > " + element[0]?.parentId?.name)
                    groupedElements[k].map(i => o.options.push(new ToolboxGroupElements(i.id.toString(), i.name)))
                    data.push(o)
                })

                this.dualListOptions = data

                this.setState({menuItems: toolboxItems})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchConnectionImages = (filter: ImageFilter, append: boolean = false, callback?: (page: number, lastPage: boolean) => void) => {
        let page = append ? this.state.connectionImagePage + 1 : 0

        GetImageGalleryFiltered(filter, WSContextType.maintenance, page, 100).then(
            (result) => {
                let connectionsImages = result.content as Image[]

                if(append && this.state.connectionImages) {
                    this.state.connectionImages.forEach(img => {
                        connectionsImages.push(img)
                    })
                }

                this.setState({connectionImages: connectionsImages})
            }
        )
    }
    onFetchNextConnectionPage = (filter: ImageFilter, callback?: (page: number, lastPage: boolean) => void) => {
        this.fetchConnectionImages(filter, true, callback)
    }

    isFormValid = () => {
        const form = document.getElementById("formData")  as HTMLFormElement;
        return form && form.reportValidity()
    }

    onSave = async(close: boolean) => {

        if (this.isFormValid()) {
            let currentItem = this.state.item

            try {
                // Added new originator, needs to be created
                if (currentItem.originator) {
                    if (currentItem.originator.id === undefined) {
                        if (currentItem.originator.name !== "") {
                            currentItem.originator = await CreateOriginator(currentItem.originator)
                        } else {
                            currentItem.originator = undefined
                        }
                    }
                }

                // Added new collection, needs to be created
                if (currentItem.collection && currentItem.collection.id === undefined) {
                    currentItem.collection = await CreateImageCollection(currentItem.collection)
                }

                // Added new collection, needs to be created
                let tags: Tag[] = []
                let newTags: Tag[] = []
                if (currentItem.tags) {
                    tags = currentItem.tags.filter(i => i.id !== undefined)
                    newTags = currentItem.tags.filter(i => i.id === undefined)
                    for (const i1 of newTags) {
                        tags.push(await CreateTag(i1));
                    }
                }
                currentItem.tags = tags
                currentItem.toolboxItems = this.state.menuItems.filter(m => this.state.selectedMenuItems.includes(m.id))

                if (currentItem.id) {
                    // Update image
                    await UpdateImage(currentItem)
                    this.setState({unsavedChanges: false}, () => {
                        if (close) { this.onCancel() }
                        else if (newTags.length > 0) { this.fetchTags() }
                    })

                } else {
                    let result = await CreateImage(currentItem)
                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) { this.onCancel() }
                        else if (newTags.length > 0) { this.fetchTags() }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || "/maintenance/images")
        )
    }
    onCopy = () => {
        if (this.isFormValid()) {

            this.onSave(false).then(
                () => {
                    CloneImage(this.state.item, this.state.item.name + " - " + this.context.translate(translations.text_fragment.copy)).then(
                        (result: Image) => {
                            this.setState({item: result, images: []})
                            this.props.history.push("/maintenance/images/" + result.id)
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.copy_error))
                        }
                    )
                }
            )
        }
    }
    onSetState = (status: Status, user?: User) => {
        try {
            if(this.state.item) {
                const item = this.state.item

                if(status === Status.published && item.pageFrame && item.counterpartImage === null) {
                    throw new ValidationError(this.context.translate(translations.notification.publishing_error_image_page_frame))
                }
                Image.setStatus(item, status, user)

                this.setState({item: item}, () => this.onSave(false))
                return true
            }
            return false
        }
        catch (e) {
            this.context.handleError(e)
        }
    }

    onChangeName = (value: string) => {
        let currentItem = this.state.item
        currentItem.name = value
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeOriginator = (value: string, id?: number) => {
        let currentItem = this.state.item
        currentItem.originator = new Originator(value, id)
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeCollection = (value: string, id?: number) => {
        let currentItem = this.state.item
        currentItem.collection = new Originator(value, id)
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeTags = (tags: Tag[]) => {
        let currentItem = this.state.item
        currentItem.tags = tags
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeAlignment = (value: number) => {
        // Get enum value by index (value)
        let alignment = Object.values(ImageAlignment).find((e, i) => i === value)
        if (alignment) {
            let currentItem = this.state.item
            currentItem.alignment = alignment

            const size = Image.getSizeByAlignment(alignment)
            currentItem.width = size.x
            currentItem.height = size.y

            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeThumbAlignment = (value: number) => {
        // Get enum value by index (value)
        let thumbAlignment = Object.values(ImageAlignment).find((e, i) => i === value)
        if (thumbAlignment) {
            let currentItem = this.state.item
            currentItem.thumbAlignment = thumbAlignment

            const size = Image.getSizeByAlignment(thumbAlignment)
            currentItem.thumbWidth = size.x
            currentItem.thumbHeight = size.y

            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeDimension = (value: string, fieldname: string) => {
        let val: number | undefined = +value
        if (isNaN(val) || value === "") {
            val = undefined
        }

        let currentItem = this.state.item
        currentItem[fieldname] = val
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeImageProperty = (value: number) => {
        // Get enum value by index (value)
        let property = Object.values(ImageProperty).find((e, i) => i === value)
        if (property) {
            let currentItem = this.state.item
            currentItem.property = property
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeImageColor = (value: number) => {
        // Get enum value by index (value)
        let color = Object.values(ImageColor).find((e, i) => i === value)
        if (color) {
            let currentItem = this.state.item
            currentItem.color = color
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeImageFamily = (imageFamily: ImageFamily) => {
        let currentItem = this.state.item
        currentItem.family = imageFamily

        this.setState({
            item: currentItem,
            showImageFamilyModal: false,
            unsavedChanges: true
        })
    }
    onChangeImageAlternative = (imageAlternative: ImageAlternative) => {
        let currentItem = this.state.item
        currentItem.alternative = imageAlternative
        this.setState({
            item: currentItem,
            showImageAlternativeModal: false,
            unsavedChanges: true
        })
    }

    onChangeBoolean = (fieldName: string) => {
        let currentItem = this.state.item
        currentItem[fieldName] = !currentItem[fieldName]
        this.setState({item: currentItem, unsavedChanges: true})
    }
    onChangeMenuRelation = (selected: string[]) => {
        this.setState({selectedMenuItems: selected.map(s => +s), unsavedChanges: true});
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }

    onUploadImage = (imageType: ImageType, imageList: ImageListType) => {

        // Save image to state
        let currentItem = this.state.item
        if (imageList[0].file && imageList[0].dataURL) {
            if (imageType === ImageType.main) {
                currentItem.filename = imageList[0].file.name
                currentItem.image = imageList[0].dataURL.split(';base64,')[1]
            }
            else {
                currentItem.thumbnailFilename = imageList[0].file.name
                currentItem.thumbnail = imageList[0].dataURL.split(';base64,')[1]
            }
        }

        if (imageType === ImageType.main) {
            this.setState({item: currentItem, images: imageList})
        }
        else {
            this.setState({item: currentItem, thumbnails: imageList})
        }
    }

    onOpenPageFrameConnectionDialog = () => {
        if(this.state.item.alignment) {
            if(this.state.item.alignment === ImageAlignment.portrait) {
                this.pageFrameImageConnectionFilter.alignment = [ImageAlignment.landscape]
            }
            else if (this.state.item.alignment === ImageAlignment.landscape) {
                this.pageFrameImageConnectionFilter.alignment = [ImageAlignment.portrait]
            }
        }

        this.fetchConnectionImages(this.pageFrameImageConnectionFilter)

        this.setState({showImageConnectionModal: true})
    }
    onSubmitImageConnection = (event: React.FormEvent) => {
        event.preventDefault();

        let currentItem = this.state.item
        currentItem.counterpartImage = this.state.selectedConnectionImage

        this.setState({item: currentItem, showImageConnectionModal: false, unsavedChanges: true})
    }
    onClickConnectionImage = (image: Image) => {
        this.setState({selectedConnectionImage: image})
    }
    onCancelImageConnection = () => {
        this.setState({showImageConnectionModal: false})
    }
    onRemoveImageConnection = () => {
        let currentItem = this.state.item

        if(currentItem.counterpartImage !== undefined) {
            currentItem.counterpartImage = undefined

            this.setState({item: currentItem, showImageConnectionModal: false, unsavedChanges: true})
        }
        else {
            this.setState({showImageConnectionModal: false})
        }
    }
    onOpenImageFamilyDialog = () => {
        this.setState({showImageFamilyModal: true})
    }
    onImageFamilySubmit = (imageFamilyName: string) => {
        CreateImageFamily(new ImageFamily(imageFamilyName)).then(
            (imageFamily) => {
                let currentItem = this.state.item
                currentItem.family = imageFamily
                this.setState({
                    item: currentItem,
                    showImageFamilyModal: false,
                    unsavedChanges: true
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.saving_error))
            })
    }
    onImageFamilyCancel = () => {
        this.setState({showImageFamilyModal: false})
    }

    onOpenImageAlternativeDialog = () => {
        this.setState({showImageAlternativeModal: true})
    }
    onRemoveImageAlternative = () => {
        let image = this.state.item
        image.alternative = undefined
        this.setState({item: image})
    }
    onImageAlternativeSubmit = (imageAlternativeName: string) => {
        CreateImageAlternative(new ImageAlternative(imageAlternativeName)).then(
            (imageAlternative) => {
                let currentItem = this.state.item
                currentItem.alternative = imageAlternative
                this.setState({
                    item: currentItem,
                    showImageAlternativeModal: false,
                    unsavedChanges: true
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    onImageAlternativeCancel = () => {
        this.setState({showImageAlternativeModal: false})
    }

    onClickRedirectToImage = (imageId: number) => {
        this.fetchData(imageId)
    }

    onLoadImage = (imageUrl: string, imageSizeField: string) => {
        fetch(Conf.IMAGE_URL() + imageUrl)
            .then(resp => resp.blob())
            .then(blob => {
                let el = document.getElementById(imageSizeField)
                if (el) {
                    el.innerHTML = imageUrl + ", " + Util.formatNumber(Math.ceil(blob.size / 1024), "de-AT") + " KB"
                }
            })
    }
    renderImageUpload = (imageType: ImageType, isDragging: boolean, dragProps, onImageUpload, onImageUpdate) => {
        let url = (imageType === ImageType.main ? this.state.item.url : this.state.item.thumbnail)
        let images = (imageType === ImageType.main ? this.state.images : this.state.thumbnails)

        const imageClass = (imageType === ImageType.main ? "image-upload-item" : "image-upload-thumbnail-item")
        const imageDetail = (imageType === ImageType.main ? "image-detail-main" : "image-detail-thumb")

        if (this.isReadonly() && url) {
            return <div className={imageClass} style={{cursor: "default"}}>
                <img src={Conf.IMAGE_URL() + url} onLoad={() => this.onLoadImage(url!, imageDetail)} alt="" onClick={() => {
                    if (!this.isReadonly()) {
                        onImageUpdate(0)
                    }
                }}/>
                <div id={imageDetail} className={"image-details"} />
            </div>
        }
        else if (images.length === 0) {
            if (url) {
                return <div className={imageClass}>
                        <img src={Conf.IMAGE_URL() + url} alt="" onLoad={() => this.onLoadImage(url!, imageDetail)}
                             onClick={() => onImageUpdate(0)}/>
                        <div id={imageDetail} className={"image-details"}/>
                    </div>
            } else {
                return <div
                    className={"image-upload" + (imageType === ImageType.thumb ? " image-upload-thumbnail" : "")}
                    style={isDragging ? {backgroundColor: "lightgray"} : undefined}
                    {...dragProps}
                >
                    <img className={"image-upload-icon"}
                         src={process.env.PUBLIC_URL + ImagePath.getAdminUrl() + "load_image.svg"}
                         alt={this.context.translate(translations.fields.upload)}
                         onClick={onImageUpload}
                         draggable={"false"}
                         onContextMenu={(e) => e.preventDefault() }
                    />
                </div>
            }
        } else {
            return <div>
                {images
                    .filter(i => i.dataURL !== undefined)
                    .map((image, index) => (

                        <div key={index} className={imageClass}>
                            <img src={image.dataURL} alt=""
                                 onLoad={() => this.onLoadImage(image.dataURL!, imageDetail)}
                                 onClick={() => onImageUpdate(index)}
                                 draggable={"false"}
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                            <div id={imageDetail} className={"image-details"}/>
                        </div>
                    ))}
            </div>
        }
    }

    render() {
        let renderCoords = new Coords(0, 0)
        let imageWidth= this.state.item.width === undefined ? "" : this.state.item.width.toString()
        let imageHeight = this.state.item.height === undefined ? "" : this.state.item.height.toString()
        let imageAlignment = EnumValueToValueNumber(ImageAlignment, this.state.item.alignment)
        let thumbAlignment = EnumValueToValueNumber(ImageAlignment, this.state.item.thumbAlignment)

        if (this.state.item.width && this.state.item.height && this.state.item.alignment) {
            renderCoords = Util.calculateRenderSize(new Coords(this.state.item.width, this.state.item.height), this.state.item.alignment)
        }

        return <>
            <AdminDataForm
                onSave={this.onSave}
                onCancel={this.onCancel}
                onSetState={this.onSetState}
                allowStatusChangeToUserItself={false}
                item={this.state.item}
                itemType={AdminObjectType.image}
                onCopy={this.onCopy}
                hasUnsavedChanges={this.state.unsavedChanges}

                history={this.props.history}
                location={this.props.location}
                match={this.props.match}>

                <div className={"admin-form-content"}>

                    <form id={"formData"} className="admin-form-input" onSubmit={() => {
                        return false
                    }}>
                        <FormHeader
                            number={this.state.item.id}
                            status={this.state.item.status}
                            statusTranslationPath={translations.enum.status}
                            createdBy={this.state.item.createdBy}
                            ownerId={this.state.item.ownerId}
                        />

                        <div className="form-row">
                            <TextBox id={"txtName"}
                                     width={400}
                                     style={{marginRight: "100px"}}
                                     label={this.context.translate(translations.fields.name)}
                                     required={true}
                                     readonly={this.isReadonly()}
                                     onChange={this.onChangeName}
                                     value={this.state.item.name}
                            />

                            <TextBox id={"txtOriginator"}
                                     width={400}
                                     label={this.context.translate(translations.fields.image.originator)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     data={this.state.originator}
                                     onChange={this.onChangeOriginator}
                                     mode={{autoComplete: true}}
                                     value={this.state.item.originator?.name || ""}
                            />
                        </div>

                        <div className="form-row">

                            <div className="form-group" style={{width: "400px", marginRight: "100px"}}>

                                <div className="form-row" style={{width: "400px"}}>
                                    <div className="form-group" style={{padding: 0}}>
                                        <label className="bold-label">{this.context.translate(translations.fields.image.image)}</label>
                                        <ImageUploading
                                            multiple={false}
                                            value={this.state.images}
                                            onChange={(imageList) => this.onUploadImage(ImageType.main, imageList)}
                                            maxNumber={1}
                                        >
                                            {({
                                                  onImageUpload,
                                                  onImageUpdate,
                                                  isDragging,
                                                  dragProps
                                              }) => (this.renderImageUpload(ImageType.main, isDragging, dragProps, onImageUpload, onImageUpdate))}
                                        </ImageUploading>
                                    </div>
                                </div>
                                <div className="form-row" style={{width: "200px"}}>
                                    <div className="form-group" style={{width: "200px", padding: 0}}>
                                        <label
                                            className="bold-label">{this.context.translate(translations.fields.image.thumbnail)}</label>
                                        <ImageUploading
                                            multiple={false}
                                            value={this.state.thumbnails}
                                            onChange={(imageList) => this.onUploadImage(ImageType.thumb, imageList)}
                                            maxNumber={1}
                                        >
                                            {({
                                                  onImageUpload,
                                                  onImageUpdate,
                                                  isDragging,
                                                  dragProps
                                              }) => (this.renderImageUpload(ImageType.thumb, isDragging, dragProps, onImageUpload, onImageUpdate))}
                                        </ImageUploading>
                                    </div>
                                </div>

                                {this.state.item.thumbnail &&
                                    <div className="form-row" style={{width: "400px"}}>
                                        <div className="form-group" style={{width: "400px", padding: 0}}>
                                            <div className="form-row">
                                                <SelectBox id={"selAlignmentThumb"}
                                                           width={400}
                                                           label={this.context.translate(translations.fields.image.thumbnail_short) + " - " + this.context.translate(translations.fields.image.alignment)}
                                                           data={EnumToEntityArray(ImageAlignment, translations.enum.image_alignment, this.context.translate)}
                                                           required={true}
                                                           value={thumbAlignment !== undefined ? thumbAlignment : imageAlignment}
                                                           readonly={this.isReadonly()}
                                                           onChange={this.onChangeThumbAlignment}
                                                           positioningRow={false}
                                                />
                                            </div>

                                            <div className="form-row">
                                                <TextBox id={"txtWidthThumb"}
                                                         width={200}
                                                         style={{marginRight: "10px"}}
                                                         label={this.context.translate(translations.fields.image.thumbnail_short) + " - " + this.context.translate(translations.fields.image.width)}
                                                         value={this.state.item.thumbWidth === undefined ? imageWidth : this.state.item.thumbWidth.toString()}
                                                         required={true}
                                                         readonly={this.isReadonly()}
                                                         onChange={(val) => this.onChangeDimension(val, "thumbWidth")}
                                                />
                                                <TextBox id={"txtHeightThumb"}
                                                         width={200}
                                                         label={this.context.translate(translations.fields.image.thumbnail_short) + " - " + this.context.translate(translations.fields.image.height)}
                                                         value={this.state.item.thumbHeight === undefined ? imageHeight : this.state.item.thumbHeight.toString()}
                                                         required={true}
                                                         readonly={this.isReadonly()}
                                                         onChange={(val) => this.onChangeDimension(val, "thumbHeight")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={"form-group"} style={{padding: 0}}>

                                <div className="form-row">
                                    <SelectBox id={"selAlignment"}
                                               width={400}
                                               label={this.context.translate(translations.fields.image.alignment)}
                                               data={EnumToEntityArray(ImageAlignment, translations.enum.image_alignment, this.context.translate)}
                                               required={true}
                                               value={imageAlignment}
                                               readonly={this.isReadonly()}
                                               onChange={this.onChangeAlignment}
                                               positioningRow={false}
                                    />
                                </div>

                                <div className="form-row">
                                    <TextBox id={"txtWidth"}
                                             width={200}
                                             style={{marginRight: "10px"}}
                                             label={this.context.translate(translations.fields.image.width)}
                                             value={imageWidth}
                                             required={true}
                                             readonly={this.isReadonly()}
                                             onChange={(val) => this.onChangeDimension(val, "width")}
                                    />
                                    <TextBox id={"txtHeight"}
                                             width={200}
                                             label={this.context.translate(translations.fields.image.height)}
                                             value={imageHeight}
                                             required={true}
                                             readonly={this.isReadonly()}
                                             onChange={(val) => this.onChangeDimension(val, "height")}
                                    />
                                </div>
                                <div className="form-row" style={{paddingBottom: "15px"}}>
                                    <TextBox id={"txtRenderWidth"}
                                             width={200}
                                             style={{marginRight: "10px"}}
                                             label={this.context.translate(translations.fields.image.render_width)}
                                             value={renderCoords.x.toString()}
                                             required={true}
                                             readonly={true}
                                             onChange={(val) => this.onChangeDimension(val, "width")}
                                    />
                                    <TextBox id={"txtRenderHeight"}
                                             width={200}
                                             label={this.context.translate(translations.fields.image.render_height)}
                                             value={renderCoords.y.toString()}
                                             required={true}
                                             readonly={true}
                                             onChange={(val) => this.onChangeDimension(val, "height")}
                                    />
                                </div>

                                <div className="form-row">
                                    <SelectBox id={"selProperty"}
                                               width={400}
                                               label={this.context.translate(translations.fields.image.property)}
                                               data={EnumToEntityArray(ImageProperty, translations.enum.image_property, this.context.translate)}
                                               required={true}
                                               value={EnumValueToValueNumber(ImageProperty, this.state.item.property)}
                                               readonly={this.isReadonly()}
                                               onChange={this.onChangeImageProperty}
                                               positioningRow={false}
                                    />
                                </div>

                                <div className="form-row">
                                    <SelectBox id={"selColor"}
                                               width={400}
                                               label={this.context.translate(translations.fields.image.color)}
                                               data={EnumToEntityArray(ImageColor, translations.enum.image_color, this.context.translate)}
                                               required={true}
                                               value={EnumValueToValueNumber(ImageColor, this.state.item.color)}
                                               readonly={this.isReadonly()}
                                               onChange={this.onChangeImageColor}
                                               positioningRow={false}
                                    />
                                </div>

                                <div className="form-row" style={{marginBottom: "0"}}>
                                    <CheckBoxSwitch id={"chkBackground"}
                                                    width={200}
                                                    label={this.context.translate(translations.fields.image.background)}
                                                    checked={this.state.item.background}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBoolean("background")}
                                    />

                                    <CheckBoxSwitch id={"chkLicense"}
                                                    width={200}
                                                    label={this.context.translate(translations.fields.image.license)}
                                                    checked={this.state.item.licenseRequired}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBoolean("licenseRequired")}
                                    />
                                </div>

                                <div className={"form-row"} style={{marginBottom: "0"}}>
                                    <CheckBoxSwitch id={"chkInternal"}
                                                    width={200}
                                                    label={this.context.translate(translations.fields.image.internal)}
                                                    checked={this.state.item.internal}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBoolean("internal")}
                                    />

                                    <CheckBoxSwitch id={"chkPageFrame"}
                                                    width={200}
                                                    label={this.context.translate(translations.fields.image.pageFrame)}
                                                    checked={this.state.item.pageFrame}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBoolean("pageFrame")}
                                    />
                                </div>

                                <div className={"form-row"} style={{paddingBottom: "15px"}}>
                                    <CheckBoxSwitch id={"chkColorable"}
                                                    width={200}
                                                    label={this.context.translate(translations.fields.image.colorable)}
                                                    checked={this.state.item.colorable}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBoolean("colorable")}
                                    />

                                    <CheckBoxSwitch id={"chkAIGenerated"}
                                                    width={200}
                                                    label={this.context.translate(translations.fields.image.ai_generated)}
                                                    checked={this.state.item.aiGenerated}
                                                    readonly={this.isReadonly()}
                                                    onChange={() => this.onChangeBoolean("aiGenerated")}
                                    />
                                </div>

                                {this.state.item.pageFrame &&
                                    <div className={"form-row"}>
                                        <TextBox id={"txtPageFrameMatchingImage"}
                                                 width={400}
                                                 label={this.context.translate(translations.fields.image.page_frame_matching_image)}
                                                 required={true}
                                                 readonly={true}
                                                 value={this.state.item.counterpartImage
                                                     ? this.state.item.counterpartImage.name
                                                     : ""}
                                        />

                                        {!this.isReadonly() &&
                                            <div className={"form-button"}
                                                 onClick={() => this.onOpenPageFrameConnectionDialog()}>
                                                {this.context.translate(translations.command.connect)}
                                            </div>
                                        }
                                        {this.state.item.counterpartImage &&
                                            <div className={"form-group"} style={{position: "absolute", right: 0}}>
                                                <ImageSmallListItem
                                                    images={[this.state.item.counterpartImage]}
                                                    onClickImage={(image) => this.onClickRedirectToImage(image.id!)}
                                                />
                                            </div>
                                        }
                                    </div>
                                }

                                <div className={"form-row"}>

                                    <TextBox id={"txtCollection"}
                                             width={400}
                                             label={this.context.translate(translations.fields.image.collection)}
                                             required={true}
                                             readonly={this.isReadonly()}
                                             data={this.state.collection}
                                             onChange={this.onChangeCollection}
                                             mode={{autoComplete: true}}
                                             value={this.state.item.collection?.name || ""}
                                    />

                                </div>

                                <div className={"form-row"}>
                                    <TextBox id={"txtFamily"}
                                             width={400}
                                             label={this.context.translate(translations.fields.image.family)}
                                             required={true}
                                             readonly={true}
                                             value={this.state.item.family?.name || ""}
                                    />

                                    {!this.isReadonly() &&
                                        <div className={"form-button"} onClick={() => this.onOpenImageFamilyDialog()}>
                                            {"Bearbeiten"}
                                        </div>
                                    }
                                </div>

                                <div className={"form-row"} style={{width: "600px"}}>
                                    {this.state.item.family &&
                                        <ImageGroupListItem id={this.state.item.family.id!}
                                                            name={this.state.item.family.name}
                                                            currentImageId={this.state.item.id!}
                                                            showTitle={false}
                                                            showHover={false}
                                                            showImages={true}
                                                            retrieveImages={GetImagesByFamily}
                                                            onClickItem={(image) => this.onClickRedirectToImage(image.id!)}
                                        />
                                    }
                                </div>

                                <div className={"form-row"}>
                                    <TextBox id={"txtAlternative"}
                                             width={400}
                                             label={this.context.translate(translations.fields.image.alternative)}
                                             required={false}
                                             readonly={true}
                                             value={this.state.item.alternative?.name || ""}
                                    />

                                    {!this.isReadonly() &&
                                        <div className={"form-button"}
                                             onClick={() => this.onOpenImageAlternativeDialog()}>
                                            {this.context.translate(translations.command.edit)}
                                        </div>
                                    }
                                    {(!this.isReadonly() && this.state.item.alternative) &&
                                        <div className={"form-button margin-left"}
                                             onClick={() => this.onRemoveImageAlternative()}>
                                            {this.context.translate(translations.command.remove)}
                                        </div>
                                    }
                                </div>

                                <div className={"form-row"} style={{width: "600px"}}>
                                    {this.state.item.alternative &&
                                        <ImageGroupListItem id={this.state.item.alternative.id!}
                                                            name={this.state.item.alternative.name}
                                                            currentImageId={this.state.item.id!}
                                                            showTitle={false}
                                                            showHover={false}
                                                            showImages={true}
                                                            retrieveImages={GetImagesByAlternative}
                                                            onClickItem={(image) => this.onClickRedirectToImage(image.id!)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"form-row"}>
                            <TextBox id={"txtTags"}
                                     width={800}
                                     label={this.context.translate(translations.fields.image.tags)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     data={this.state.tags}
                                     tags={this.state.item.tags || []}
                                     onChangeTags={this.onChangeTags}
                                     mode={{autoComplete: true, tags: true}}
                                     value={""}
                            />
                        </div>
                    </form>

                    <DualList
                        options={this.dualListOptions}
                        selected={this.state.selectedMenuItems.map(s => s.toString())}
                        width={1000}
                        marginTop={0}
                        marginLeft={0}
                        disabled={this.isReadonly()}
                        label={this.context.translate(translations.fields.exercise.menuRelation)}
                        onChange={this.onChangeMenuRelation}
                    />
                </div>
            </AdminDataForm>

            {this.state.showImageFamilyModal &&
                <ImageGroupModal
                    title={this.context.translate(translations.fields.image.family)}
                    retrieveRecords={GetImageFamilies}
                    retrieveImages={GetImagesByFamily}
                    onSubmit={this.onImageFamilySubmit}
                    onCancel={this.onImageFamilyCancel}
                    onChange={this.onChangeImageFamily}
                />
            }

            {this.state.showImageAlternativeModal &&
                <ImageGroupModal
                    title={this.context.translate(translations.fields.image.alternative)}
                    retrieveRecords={GetImageAlternative}
                    retrieveImages={GetImagesByAlternative}
                    onSubmit={this.onImageAlternativeSubmit}
                    onCancel={this.onImageAlternativeCancel}
                    onChange={this.onChangeImageAlternative}
                />
            }

            {this.state.showImageConnectionModal &&
                <Modal id={"imageConnection"}
                       onFormSubmit={this.onSubmitImageConnection}
                       title={this.context.translate(translations.fields.image.connection)}
                       buttons={[
                           new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onCancelImageConnection, {marginTop: "30px", marginRight: "10px"}),
                           new ButtonInfo("btnRemove", "button button-save", "button", true, false, this.context.translate(translations.command.remove), this.onRemoveImageConnection, {marginTop: "30px", marginRight: "10px"}),
                           new ButtonInfo("btnSubmit", "button button-save", "submit", true, false, this.context.translate(translations.command.connect), undefined, {marginTop: "30px"}),
                       ]}
                       dialogStyle={{width: "850px", height: "75%"}}
                       contentAlignment={"flex-start"}
                >
                    <ImageGallery
                        itemHeight={250}
                        onFetchNextPage={() => this.onFetchNextConnectionPage(this.pageFrameImageConnectionFilter)}
                        onClickImage={this.onClickConnectionImage}
                        items={this.state.connectionImages}
                        imageFieldAsImageTitle={"url"}
                        imageTitleHoverEffect={true}
                        highlightImages={this.state.selectedConnectionImage ? [this.state.selectedConnectionImage] : undefined}/>
                </Modal>
            }
        </>
    }
}
