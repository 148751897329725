import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import translations from "../../Framework/translations.json";
import TextBox from "../../Components/Controls/TextBox";
import {AdminContentType, AdminObjectType, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import SearchResult from "../../_model/SearchResult";
import {GetSearchResult, UpdateSearchResult} from "../../_endpoint/SearchResultEndpoint";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {Util} from "../../Framework/Util";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

interface IState {
    item: SearchResult
    unsavedChanges: boolean
}

export default class SearchResultForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            item: new SearchResult("", AdminContentType.image, "", 0, Status.draft),
            unsavedChanges: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        GetSearchResult(+this.props.match.params.id).then(
            (itemData) => {
                if(itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                this.setState({
                    item: itemData
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    isFormValid = () => {
        const form = document.getElementById("formData")  as HTMLFormElement;
        return form && form.reportValidity()
    }

    onSave = async (close: boolean) => {

        if (this.isFormValid()) {

            try {
                // Update search result (status)
                await UpdateSearchResult(this.state.item)
                this.setState({unsavedChanges: false}, () => {
                    if (close) { this.onCancel() }
                })

                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || AdminUtils.getObjectTypeUrl(AdminObjectType.search_result))
        )
    }
    onSetState = (status: Status, user?: User) => {
        if(this.state.item) {
            const item = this.state.item
            SearchResult.setStatus(item, status, user)

            this.setState({item: item}, () => this.onSave(false))
            return true
        }
        return false
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }

    render() {
        return <AdminDataForm
            onSave={this.onSave}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={true}
            item={this.state.item}
            itemType={AdminObjectType.search_result}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

                <div className={"admin-form-content"}>

                    <form id={"formData"} className="admin-form-input" onSubmit={() => { return false} }>

                        <FormHeader
                            number={this.state.item.id}
                            status={this.state.item.status}
                            statusTranslationPath={translations.enum.search_result_status}
                            createdBy={this.state.item.createdBy}
                            ownerId={this.state.item.ownerId}
                        />

                        <div className="form-row">
                            <TextBox id={"txtName"}
                                     width={500}
                                     label={this.context.translate(translations.fields.name)}
                                     required={true}
                                     readonly={true}
                                     value={this.state.item.name}
                            />

                            {this.state.item.createdOn &&
                                <TextBox id={"txtDate"}
                                         width={500}
                                         label={this.context.translate(translations.fields.created_on)}
                                         required={true}
                                         readonly={true}
                                         value={Util.formatDate(this.state.item.createdOn, true)}
                                />
                            }
                        </div>

                        <div className="form-row">
                            <TextBox id={"txtNotes"}
                                     width={1000}
                                     label={this.context.translate(translations.fields.name_fields.notes)}
                                     required={false}
                                     readonly={true}
                                     value={this.state.item.details || ""}
                            />
                        </div>

                        <div className="form-row">
                            <TextBox id={"txtResults"}
                                     width={500}
                                     label={this.context.translate(translations.fields.result_amount)}
                                     required={true}
                                     readonly={true}
                                     value={this.state.item.resultAmount.toString()}
                            />
                        </div>
                    </form>
                </div>
        </AdminDataForm>
    }
}

